import { AuthMode } from '../authContext/AuthContext.types';
import { SET_AUTHORIZED, SET_UNAUTHORIZED, START_AUTHORIZING } from '../authReducer/authReducer';
import { AuthAction, User } from '../authReducer/authReducer.types';

export const setAuthorized: (authMode: AuthMode, user: User) => AuthAction = (authMode, user) => ({
  type: SET_AUTHORIZED,
  authMode,
  user,
});

export const setUnauthorized: () => AuthAction = () => ({
  type: SET_UNAUTHORIZED,
});

export const startAuthorizing: () => AuthAction = () => ({
  type: START_AUTHORIZING,
});
