import { useState } from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import parseHtml from 'html-react-parser';

import { getAppConfig } from 'utils/config';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useDefaultRoute } from 'hooks/useDefaultRoute/useDefaultRoute';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { ProfileTab } from 'app/profile/Profile.enum';

import { MenuItems, TopbarProps } from './Topbar.types';
import * as styles from './Topbar.styles';

export const Topbar = ({ drawerOpen, handleToggleDrawer }: TopbarProps) => {
  const translate = useTranslator();

  const { user } = useAuthState();
  const { hasAccessOnlyPasswordChange } = usePermissions();

  const defaultRoute = useDefaultRoute();

  const [anchorUserMenuEl, setAnchorUserMenuEl] = useState<null | HTMLElement>(null);

  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hasAccessOnlyPasswordChange) {
      event.preventDefault();
    } else {
      setAnchorUserMenuEl(event.currentTarget);
    }
  };

  const closeUserMenu = () => {
    setAnchorUserMenuEl(null);
  };

  const isUserMenuOpen = Boolean(anchorUserMenuEl);

  const menuItems: MenuItems = [
    {
      route: generatePath(AppRoute.profile, { tab: ProfileTab.data }) as AppRoute,
      title: translate('topbar.userMenuProfile'),
      target: '_blank',
    },
    {
      route: AppRoute.logout,
      title: (
        <>
          <ExitToAppIcon fontSize="small" sx={styles.userMenuItemIcon} />
          {translate('global.logout')}
        </>
      ),
    },
  ];

  const isTopbarConent = !!getAppConfig('TOPBAR_CONTENT');

  return (
    <AppBar position="static" sx={styles.appBar} data-testid="topbar">
      <Toolbar sx={styles.toolbar}>
        <Grid container alignItems="center">
          {!user && (
            <>
              <Typography variant="h6" component="h1" sx={styles.appTitle}>
                {translate('topbar.appTitle')}
              </Typography>
              {isTopbarConent && <Box sx={styles.topbarContent}>{parseHtml(getAppConfig('TOPBAR_CONTENT'))}</Box>}
            </>
          )}

          {user && (
            <>
              <Box sx={styles.openDrawer}>
                {!drawerOpen && (
                  <IconButton
                    onClick={handleToggleDrawer(true)}
                    color="inherit"
                    aria-label={translate('topbar.openDrawer')}
                    edge="start"
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Box>

              <Link component={RouterLink} to={defaultRoute} sx={styles.homeLink}>
                <Typography variant="h6" component="h1" sx={styles.appTitle}>
                  {translate('topbar.appTitle')}
                </Typography>
              </Link>

              {isTopbarConent && <Box sx={styles.topbarContent}>{parseHtml(getAppConfig('TOPBAR_CONTENT'))}</Box>}

              <Button
                id="user-menu-button"
                disabled={hasAccessOnlyPasswordChange}
                onClick={openUserMenu}
                aria-label={translate('topbar.openUserMenu')}
                aria-controls={isUserMenuOpen ? 'user-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isUserMenuOpen ? 'true' : undefined}
                color="inherit"
                sx={styles.userBox(hasAccessOnlyPasswordChange)}
              >
                <Box display="flex" alignItems="center">
                  <Typography sx={styles.userFullName} variant="body2">
                    {user.firstName} {user.lastName}
                  </Typography>
                  <Avatar sx={styles.userAvatar} />
                </Box>
              </Button>

              <Menu
                id="user-menu"
                anchorEl={anchorUserMenuEl}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={isUserMenuOpen}
                onClose={closeUserMenu}
                PaperProps={{ sx: styles.userMenu }}
                MenuListProps={{ sx: styles.userMenuList, 'aria-labelledby': 'user-menu-button' }}
              >
                {menuItems.map(({ route, title, target }, i) => (
                  <Box component="li" sx={styles.userMenuItem} key={i}>
                    <MenuItem key={i} component={RouterLink} to={route} target={target} onClick={closeUserMenu}>
                      {title}
                    </MenuItem>
                  </Box>
                ))}
              </Menu>
            </>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
