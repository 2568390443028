import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { editUser } from 'api/actions/users/usersActions';
import { EditUserPayload, UserResponse } from 'api/actions/users/usersActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useEditUser = () => {
  const queryClient = useQueryClient();

  const mutationEditUser = useMutation<UserResponse, AxiosError, EditUserPayload>(editUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(Queries.getUsers);
      queryClient.invalidateQueries(Queries.getUserMe);
    },
  });

  return mutationEditUser;
};
