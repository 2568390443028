import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { confirmEmail } from 'api/actions/email/email';
import { ConfirmEmailPayload } from 'api/actions/email/email.types';

export const useConfirmEmail = () => {
  const mutationConfirmEmail = useMutation<void, AxiosError, ConfirmEmailPayload>(confirmEmail);

  return mutationConfirmEmail;
};
