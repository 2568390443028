import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { useIsFetching, useQueryClient } from 'react-query';

import { errorMessages } from 'api/errors/messages';
import { permissions } from 'utils/permissions';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useEditUser } from 'hooks/users/useEditUser/useEditUser';
import { useGetUser } from 'hooks/users/useGetUser/useGetUser';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { CreateUserPayload } from 'api/actions/users/usersActions.types';
import { Restriction, useCheckRestrictedRoles } from 'hooks/useCheckRestrictedRoles/useCheckRestrictedRoles';
import { Queries } from 'api/queries/queries.enum';
import { UsersForm } from '../usersForm/UsersForm';

import * as styles from './UsersEdit.styles';

export const UsersEdit = () => {
  const translate = useTranslator();
  const history = useHistory();

  const queryClient = useQueryClient();
  const isFetchingUserMe = useIsFetching([Queries.getUserMe]);

  const { checkUserHasRestrictedRoles } = useCheckRestrictedRoles();

  const { id } = useParams<{ id: string }>();
  const queryGetUser = useGetUser(id);

  const confirmFormAbort = useConfirmFormAbort();

  const { user } = useAuthState();
  const isCurrentlyLoggedUser = id === user?.id;

  const { hasAccess } = usePermissions();

  const userErrorMessage: string[] = queryGetUser.error?.response?.data?.errors || [];
  const isInvalidUserId = userErrorMessage.includes(errorMessages.user.invalidId);
  const doesUserNotExit = userErrorMessage.includes(errorMessages.user.doesNotExist);
  const isUserBlacklisted =
    queryGetUser.data && checkUserHasRestrictedRoles(queryGetUser.data, { restrictionType: Restriction.blacklisted });
  const notShowUserView = isUserBlacklisted || doesUserNotExit || isInvalidUserId;

  const backToUsersList = () => {
    history.push(AppRoute.users);
  };

  useEffect(() => {
    if (notShowUserView) {
      if (isUserBlacklisted) {
        toast.error(translate('global.roleBlacklisted'));
      } else if (doesUserNotExit) {
        toast.error(translate('CONSTRAINT.VALIDATION.USER_DOES_NOT_EXIST'));
      } else if (isInvalidUserId) {
        toast.error(translate('CONSTRAINT.VALIDATION.INVALID_USER_ID'));
      }

      backToUsersList();
    }
  }, [notShowUserView]);

  const mutateEditUser = useEditUser();

  const handleSubmitFormData = useCallback(
    (data: CreateUserPayload) => {
      mutateEditUser.mutate(
        { id, values: data },
        {
          onSuccess: async () => {
            confirmFormAbort.disable();

            toast.success(translate('usersEdit.successResponse'));

            if (id === user?.id) {
              await queryClient.refetchQueries([Queries.getUserMe]);

              if (hasAccess(permissions.usersListView)) {
                return history.push(AppRoute.users);
              }

              return history.push(AppRoute.profile);
            }

            history.push(AppRoute.users);
          },
          onError: () => {
            toast.error(translate('usersEdit.errorResponse'));
          },
        },
      );
    },
    [mutateEditUser.mutate],
  );

  const renderFormBody = () => {
    if (queryGetUser.data) {
      return (
        <UsersForm
          user={queryGetUser.data}
          isEditForm
          isUpdateError={mutateEditUser.isError}
          isUpdateLoading={mutateEditUser.isLoading || !!isFetchingUserMe}
          updateError={mutateEditUser.error}
          onSubmitFormData={handleSubmitFormData}
          isCurrentlyLoggedUser={isCurrentlyLoggedUser}
        />
      );
    }

    if (queryGetUser.isError) {
      return (
        <Box mt={2}>
          <Alert severity="error">{translate('global.errorMessage')}</Alert>
        </Box>
      );
    }

    return <CircularProgress sx={styles.loader} data-testid="users-edit-loader" />;
  };

  return (
    <>
      <Typography component="h2" variant="h5" sx={styles.pageTitle}>
        {translate('usersEdit.pageTitle')} {queryGetUser.data?.firstName} {queryGetUser.data?.lastName}
      </Typography>

      {renderFormBody()}
    </>
  );
};
