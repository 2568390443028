import { format, fromUnixTime } from 'date-fns';

import { FormatDateAndTimeOptions } from './useFormatDate.types';

export const useFormatDate = () => {
  const formatDate = (date: string) => format(new Date(date), 'dd-MM-yyyy');

  const formatDateAndTime = (date: string, options?: FormatDateAndTimeOptions) =>
    format(new Date(date), options?.showSeconds ? 'dd-MM-yyyy HH:mm:ss' : 'dd-MM-yyyy HH:mm');

  const formatMicroTimestampToDateAndTime = (microTimeStamp: number, options?: FormatDateAndTimeOptions) => {
    const timestamp = microTimeStamp / Math.pow(10, 6);

    return format(fromUnixTime(timestamp), options?.showSeconds ? 'dd-MM-yyyy HH:mm:ss' : 'dd-MM-yyyy HH:mm');
  };

  return { formatDate, formatDateAndTime, formatMicroTimestampToDateAndTime };
};
