import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { StatusName } from 'api/actions/users/usersActions.enum';

export const useFormatUserStatus = () => {
  const translate = useTranslator();

  const formatUserStatus = (statusName: StatusName) => {
    switch (statusName) {
      case StatusName.active:
        return translate('global.statusActive');
        break;
      case StatusName.blocked:
        return translate('global.statusBlocked');
        break;
      case StatusName.removed:
        return translate('global.statusRemoved');
        break;
      default:
        return statusName;
        break;
    }
  };

  return formatUserStatus;
};
