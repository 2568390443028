import { AxiosError } from 'axios';

import { LoginPayload } from 'api/actions/auth/authActions.types';

export enum FormFields {
  login = 'login',
  password = 'password',
}

export type LoginForm = {
  [FormFields.login]: string;
  [FormFields.password]: string;
};

export type LoginFormProps = {
  disabled?: boolean;
  enableReCaptcha: boolean;
  error: AxiosError | null;
  formTitle: string;
  formSubTitle: string;
  onSubmit(payload: LoginPayload): Promise<boolean>;
};
