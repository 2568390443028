import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { deleteUser } from 'api/actions/users/usersActions';
import { Queries } from 'api/queries/queries.enum';

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  const mutationDeleteUser = useMutation<void, AxiosError, string>(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(Queries.getUsers);
    },
  });

  return mutationDeleteUser;
};
