import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';

import { LoginLogsParams, LoginLogsPayload, LoginLogsResponse } from './loginLogs.types';

export const getLoginLogs = async (payload: LoginLogsPayload): Promise<LoginLogsResponse> => {
  const { filters, itemsPerPage = 30, pageNumber = 0 } = payload;

  const params: LoginLogsParams = {
    items_per_page: itemsPerPage,
    page_number: pageNumber + 1,
    login: filters?.login,
    created_at_timestamp_from: filters?.createdAtTimestampFrom,
    created_at_timestamp_to: filters?.createdAtTimestampTo,
    ip_address: filters?.ipAddress,
    destination: filters?.destination,
    origin: filters?.origin,
  };

  const response = await umsApi.get(endpoints.loginLogs, {
    params,
  });

  return response.data;
};
