export const hasActiveFilters = (filters: Record<string, unknown>): boolean => {
  return Object.values(filters).some((filter) => {
    if (Array.isArray(filter)) {
      return filter.length > 0;
    }

    if (filter) {
      return true;
    }

    return false;
  });
};
