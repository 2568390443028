import { Box, Paper, Typography } from '@mui/material';
import parseHtml from 'html-react-parser';
import camelCase from 'lodash.camelcase';

import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { ServerErrorsListProps } from './ServerErrorsList.types';
import * as styles from './ServerErrosList.styles';

export const ServerErrorsList = ({ errors, totalErrorsCount }: ServerErrorsListProps) => {
  const translate = useTranslator();

  const renderErrorMessage = (type: string) => {
    if (type.includes('@')) {
      const [error, rawFieldName] = type.split('@');

      const fieldName = `global.${camelCase(rawFieldName)}`;
      const fieldNameTranslation = isTranslationAvailable(fieldName) ? translate(fieldName) : rawFieldName;

      return isTranslationAvailable(error) ? translate(error, { fieldName: fieldNameTranslation }) : type;
    }

    return isTranslationAvailable(type) ? translate(type) : type;
  };

  return (
    <Box component="section">
      <Box mb={2} component="header">
        <Typography paragraph sx={styles.header}>
          {translate('usersImport.errorValidation.serverErrorList.title')}
        </Typography>
      </Box>

      <Paper component="article" sx={styles.listWrapper}>
        <Box component="ul" sx={styles.list}>
          {errors.map((error) => {
            return (
              <Box component="li" key={error.type} sx={styles.listItem}>
                <Typography paragraph sx={styles.errorType}>
                  {renderErrorMessage(error.type)}
                </Typography>

                <Typography paragraph sx={styles.errorLines}>
                  {parseHtml(
                    translate('usersImport.errorValidation.serverErrorList.errorLines', {
                      errorLines: error.lines.join(','),
                      hasMore: error.hasMore,
                    }),
                  )}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Paper>

      <Box mt={2} component="footer">
        <Typography paragraph align="right">
          {parseHtml(translate('usersImport.errorValidation.serverErrorList.summary', { totalErrorsCount }))}
        </Typography>
      </Box>
    </Box>
  );
};
