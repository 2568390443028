import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { changePassword } from 'api/actions/password/passwordActions';
import { ChangePasswordPayload } from 'api/actions/password/passwordActions.types';

export const useChangePassword = () => {
  const mutationChangePassword = useMutation<void, AxiosError, ChangePasswordPayload>(changePassword);

  return mutationChangePassword;
};
