import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { loginAction } from 'api/actions/sso/ssoActions';
import { AuthorizeResponse, LoginPayload } from 'api/actions/sso/ssoActions.types';

export const useSsoLogin = () => {
  const mutationSsoLogin = useMutation<AuthorizeResponse | void, AxiosError, LoginPayload>(loginAction);

  return mutationSsoLogin;
};
