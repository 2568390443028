import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';

import yup from 'utils/yup';
import { useCancelForm } from 'hooks/useCancelForm/useCancelForm';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';

import { UsersDeleteFormProps } from './UsersDeleteForm.types';
import * as styles from './UsersDeleteForm.styles';

export const UsersDeleteForm = ({ error, isLoading, onSubmit }: UsersDeleteFormProps) => {
  const translate = useTranslator();

  const { cancelForm } = useCancelForm();

  const deleteSchema = yup.object().shape({
    confirm: yup.boolean().oneOf([true], translate('global.fieldRequired')),
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      confirm: false,
    },
    resolver: yupResolver(deleteSchema),
    mode: 'onChange',
  });

  useConfirmFormAbort(isDirty);

  const serverErrors: string[] | Record<string, string> = error?.response?.data?.errors;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        <>
          <Typography sx={styles.confirmTitle}>{translate('usersDelete.confirmTitle')}</Typography>

          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                sx={!!errors?.confirm ? styles.formControlError : undefined}
                control={
                  <Controller
                    control={control}
                    name="confirm"
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.field.value}
                        color="primary"
                        onChange={(e) => props.field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={translate('usersDelete.confirmLabel')}
              />
              <FormHelperText error>{errors?.confirm?.message}</FormHelperText>

              {serverErrors && <FormServerErrors serverErrors={serverErrors} />}
            </FormGroup>
          </FormControl>
        </>
      }

      <Box sx={styles.buttonBox}>
        <Button onClick={cancelForm} sx={styles.backButton} color="inherit">
          {translate('global.buttonCancel')}
        </Button>

        <Button type="submit" disabled={isLoading} variant="contained" color="primary">
          {translate('global.buttonSave')}
        </Button>
      </Box>
    </form>
  );
};
