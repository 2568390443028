import { useMemo } from 'react';
import { TableCell, TableRow } from '@mui/material';

import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useFormatProjectName } from 'hooks/format/useFormatProjectName/useFormatProjectName';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { LoginLogsTableRowProps } from './LoginLogsTableRow.types';
import * as styles from './LoginLogsTableRow.styles';

export const LoginLogsTableRow = ({ loginLog }: LoginLogsTableRowProps) => {
  const translate = useTranslator();

  const { formatDateAndTime } = useFormatDate();

  const { formatUmsNameToProjectName } = useFormatProjectName();

  const { login, destination, success, createdAt, origin, ipAddress, failureReason } = loginLog;

  const failureReasonMessage = useMemo(() => {
    if (failureReason) {
      const failureReasonArr = failureReason.split(',');

      return failureReasonArr
        .map((failureReason) => (isTranslationAvailable(failureReason) ? translate(failureReason) : failureReason))
        .join('\n');
    }

    return null;
  }, [failureReason]);

  return (
    <TableRow hover>
      <TableCell>{success ? translate('loginLogs.loginSuccessful') : translate('loginLogs.loginFailed')}</TableCell>
      <TableCell>{login}</TableCell>
      <TableCell>{formatDateAndTime(createdAt, { showSeconds: true })}</TableCell>
      <TableCell>{ipAddress}</TableCell>
      <TableCell>{formatUmsNameToProjectName(destination)}</TableCell>
      <TableCell>{origin}</TableCell>
      <TableCell sx={styles.failureReason}>{failureReasonMessage}</TableCell>
    </TableRow>
  );
};
