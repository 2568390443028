import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';

type MessageFormatPrimitiveValue = string | number | boolean | null | undefined;

export const useTranslator = () => {
  const { formatMessage } = useLocale();

  const translate = (message: keyof typeof AppMessages, values?: Record<string, MessageFormatPrimitiveValue>) => {
    return formatMessage({ id: AppMessages[message] }, values);
  };

  return translate;
};
