import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash.get';

import { TextFieldProps } from './TextField.types';

export const TextField = <T,>({ label, name, ...props }: TextFieldProps<T> & MUITextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <MUITextField
          {...field}
          {...props}
          id={name}
          label={label}
          placeholder={label}
          error={!!get(errors, name)?.message}
          helperText={get(errors, name)?.message}
          variant="outlined"
        />
      )}
    />
  );
};
