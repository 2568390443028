import { Styles } from '@typings/common';

export const container: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  m: 'auto',
  transform: { md: 'translateY(-50%)' },
};

export const title: Styles = {
  fontWeight: 500,
};

export const description: Styles = {
  textAlign: 'center',
  fontWeight: 300,
};

export const backButton: Styles = {
  mt: 2,
};
