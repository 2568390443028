import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { getImportValidationResult } from 'api/actions/imports/importsActions';
import { ImportActionPayload } from 'api/actions/imports/impportActions.types';

export const useGetImportValidationResult = <T extends ImportActionPayload, U>() => {
  const mutationGetImportValidationResult = useMutation<U, AxiosError, T>(getImportValidationResult);

  return mutationGetImportValidationResult;
};
