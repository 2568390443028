import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { LoginPayload } from 'api/actions/auth/authActions.types';
import { loginAction } from 'api/actions/auth/authActions';

export const useLogin = () => {
  const mutationLogin = useMutation<void, AxiosError, LoginPayload>(loginAction);

  return mutationLogin;
};
