import { Collapse as MUICollapse } from '@mui/material';

import { CollapseProps } from './Collapse.types';

export const Collapse = ({ children, ...props }: CollapseProps) => (
  <MUICollapse
    {...props}
    timeout="auto"
    unmountOnExit
    onEntered={(element) => {
      if (element?.parentElement?.parentElement) {
        const parentRow = element.parentElement.parentElement;
        parentRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }}
  >
    {children}
  </MUICollapse>
);
