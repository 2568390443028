import { OutlinedInputProps, TextField } from '@mui/material';

import { NonEditableProfileDataItemProps } from './nonEditableProfileDataItem.types';
import * as styles from './nonEditableProfileItem.styles';

export const NonEditableProfileDataItem = ({
  id,
  label,
  value,
  ...InputProps
}: NonEditableProfileDataItemProps & Partial<OutlinedInputProps>) => {
  return (
    <TextField
      key={id}
      id={id}
      label={label}
      value={value}
      InputProps={{
        disabled: true,
        readOnly: true,
        ...InputProps,
      }}
      variant="outlined"
      sx={styles.textField}
    />
  );
};
