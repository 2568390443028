import { Paper, Typography } from '@mui/material';
import { useState } from 'react';

import { beaconLogoutAction } from 'api/actions/auth/authActions';
import { useBeforePageLeave } from 'hooks/useBeforePageLeave/useBeforePageLeave';
import { useDefaultRoute } from 'hooks/useDefaultRoute/useDefaultRoute';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { PasswordChangeFormContainer } from 'ui/password/passwordChangeForm/PasswordChangeFormContainer';

import * as styles from './PasswordChange.styles';

export const PasswordChange = () => {
  const translate = useTranslator();

  const defaultRoute = useDefaultRoute();

  const [beaconLogoutActionActive, setBeaconLogoutActionActive] = useState(true);

  const disableBeacounLogoutAction = () => {
    setBeaconLogoutActionActive(false);
  };

  useBeforePageLeave(() => {
    if (beaconLogoutActionActive) {
      beaconLogoutAction();
    }
  });

  return (
    <Paper sx={styles.changePasswordForm}>
      <Typography variant="body1" component="h2" sx={styles.formTitle}>
        {translate('passwordChange.pageTitle')}
      </Typography>

      <PasswordChangeFormContainer
        disableBeacounLogoutAction={disableBeacounLogoutAction}
        redirectOnCancel={{ to: AppRoute.logout }}
        redirectOnSuccess={{ to: defaultRoute }}
      />
    </Paper>
  );
};
