import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { AppRoute } from 'routing/AppRoute.enum';
import { permissions } from 'utils/permissions';

export const useDefaultRoute = () => {
  const { hasAccess } = usePermissions();

  if (hasAccess(permissions.usersListView)) {
    return AppRoute.users;
  }

  if (hasAccess(permissions.rolesListView)) {
    return AppRoute.roles;
  }

  if (hasAccess(permissions.institutionsListView)) {
    return AppRoute.institutions;
  }

  if (hasAccess(permissions.eventLogsView)) {
    return AppRoute.eventLogs;
  }

  if (hasAccess(permissions.loginLogsView)) {
    return AppRoute.loginLogs;
  }

  return AppRoute.profile;
};
