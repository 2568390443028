import { FileFormState } from '../../UsersImport.types';

export type FilePreparationProps = {
  formState: FileFormState;
  onChangeFormState: (formState: FileFormState) => void;
  onClickNext: () => void;
};

export enum FormFields {
  file = 'file',
}

export type FileForm = {
  [FormFields.file]: FileList | null;
};

export type UploadImportFileResponse = {
  id: string;
};
