import { useConfigState } from 'hooks/useConfigState/useConfigState';

export const defaultUmsDomainName = 'UMS';

export const useFormatProjectName = () => {
  const config = useConfigState();

  const formatUmsNameToProjectName = (name: string | null) => {
    if (name === defaultUmsDomainName) {
      return config.shortProjectName;
    }

    return name;
  };

  const formatProjectNameToUmsName = (name: string | null) => {
    if (name?.toLowerCase() === config.shortProjectName.toLowerCase()) {
      return defaultUmsDomainName;
    }

    return name;
  };

  return { formatUmsNameToProjectName, formatProjectNameToUmsName };
};
