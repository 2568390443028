import { Box, Chip } from '@mui/material';

import { hasActiveFilters } from 'utils/function/hasActiveFilters/hasActiveFilters';
import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { FilterField } from 'api/actions/loginLogs/loginLogs.enum';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { LoginLogsTableToolbarActiveFiltersProps } from './LoginLogsTableToolbarActiveFilters.types';

export const LoginLogsTableToolbarActiveFilters = ({
  filters,
  onPageChange,
  onSubmitFilterForm,
}: LoginLogsTableToolbarActiveFiltersProps) => {
  const translate = useTranslator();

  const { formatDateAndTime } = useFormatDate();

  const filtersFields = Object.keys(filters) as FilterField[];

  const getLabelName = (key: FilterField) => {
    const value = filters[key];

    if (value === null) {
      return;
    }

    switch (key) {
      case FilterField.createdAtDateTimeFrom:
        return `${translate('global.dateTimeFrom')}: ${formatDateAndTime(value)}`;
      case FilterField.createdAtDateTimeTo:
        return `${translate('global.dateTimeTo')}: ${formatDateAndTime(value)}`;
      case FilterField.login:
        return `${translate('global.login')}: ${value}`;
      case FilterField.ipAddress:
        return `${translate('global.ipAddress')}: ${value}`;
      case FilterField.origin:
        return `${translate('loginLogs.origin')}: ${value}`;
      case FilterField.destination:
        return `${translate('loginLogs.destination')}: ${value}`;
    }
  };

  if (!hasActiveFilters(filters)) {
    return null;
  }

  return (
    <Box component="ul" sx={styles.chipList}>
      {filtersFields.map((key) => {
        if (filters[key]) {
          return (
            <li key={key}>
              <Chip
                size="small"
                label={getLabelName(key)}
                onDelete={() => {
                  onSubmitFilterForm({ ...filters, [key]: null });
                  onPageChange(null, 0);
                }}
              />
            </li>
          );
        }
      })}
    </Box>
  );
};
