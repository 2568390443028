import { MutableRefObject, ReactText } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { checkExportStatus } from 'api/actions/exports/exportsActions';
import { useDownloadExportFile } from 'hooks/export/useDownloadExportFile/useDownloadExportFile';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { CheckExportStatusPayload, CheckExportStatusResponse } from 'api/actions/exports/exportActions.types';
import { ExportStatus } from 'api/actions/exports/exportActions.enum';
import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { AppMessages } from 'i18n/messages';

export const useCheckExportStatus = (toastRef: MutableRefObject<ReactText>) => {
  const translate = useTranslator();

  const retryTimeout = 1000;

  const mutationDownloadExportFile = useDownloadExportFile(toastRef);

  const mutationCheckExportStatus = useMutation<CheckExportStatusResponse, AxiosError, CheckExportStatusPayload>(
    checkExportStatus,
    {
      onMutate: (variables) => {
        return variables;
      },
      onSuccess: ({ status, failureReason }, variables) => {
        if (status === ExportStatus.toExport) {
          setTimeout(() => {
            return mutationCheckExportStatus.mutate({ id: variables.id });
          }, retryTimeout);
        } else if (status === ExportStatus.exported) {
          toast.update(toastRef.current, { render: translate('export.statusSuccess'), type: 'default' });

          mutationDownloadExportFile.mutate({ id: variables.id });
        } else if (status === ExportStatus.failed) {
          if (failureReason && isTranslationAvailable(failureReason)) {
            toast.update(toastRef.current, {
              render: translate(AppMessages[failureReason]),
              type: 'error',
              isLoading: false,
            });
          } else {
            toast.update(toastRef.current, {
              render: translate('global.errorMessage'),
              isLoading: false,
              type: 'error',
            });
          }
        }
      },
    },
  );

  return mutationCheckExportStatus;
};
