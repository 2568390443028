import { Styles } from '@typings/common';

export const lastAddedUser: Styles = {
  alignItems: 'center',
  mb: 2,

  '& .MuiAlert-message': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: 1,
    justifyContent: 'space-between',
  },
};
