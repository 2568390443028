import { Autocomplete as MUIAutocomplete, AutocompleteProps } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const translate = useTranslator();

  return (
    <MUIAutocomplete
      {...props}
      clearText={translate('autocomplete.clear')}
      closeText={translate('autocomplete.close')}
      loadingText={translate('autocomplete.loading')}
      noOptionsText={translate('autocomplete.noOptions')}
      openText={translate('global.open')}
    />
  );
};
