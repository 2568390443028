import yup from 'utils/yup';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const useChangeStatusDataSchema = () => {
  const translate = useTranslator();

  return yup.object().shape({
    status: yup.string().required(translate('global.fieldRequired')),
  });
};
