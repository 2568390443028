import { useIsMutating } from 'react-query';

import { Mutations } from 'api/mutations/mutation.enum';

export const useIsMarkingNotificationAsRead = () => {
  const numberOfMutationsInProgress = useIsMutating([Mutations.markNotificationAsRead]);
  const isMarkingNotificationAsRead = numberOfMutationsInProgress > 0;

  return { isMarkingNotificationAsRead };
};
