import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { useDefaultRoute } from 'hooks/useDefaultRoute/useDefaultRoute';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

import * as styles from './NotFound.styles';

export const NotFound = () => {
  const translate = useTranslator();

  const defaultRoute = useDefaultRoute();

  return (
    <Box sx={styles.container}>
      <Typography component="h2" variant="h1" sx={styles.title}>
        {translate('notFound.pageTitle')}
      </Typography>
      <Typography component="h3" variant="h5" sx={styles.description}>
        {translate('notFound.description')}
      </Typography>
      <Button variant="contained" color="primary" component={RouterLink} to={defaultRoute} sx={styles.backButton}>
        {translate('global.buttonBack')}
      </Button>
    </Box>
  );
};
