import { useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Alert } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Collapse } from 'ui/table/row/collapse/Collapse';

import { InstitutionsTableRowProps } from './InstitutionsTableRow.types';
import * as styles from './InstitutionsTableRow.styles';

export const InstitutionsTableRow = ({ index, institution }: InstitutionsTableRowProps) => {
  const translate = useTranslator();
  const [rowOpen, setRowOpen] = useState(false);

  const config = useConfigState();

  const { id, name, shortName, regon, rspo, properties } = institution;

  const handleToggleRow = () => {
    setRowOpen((prev) => !prev);
  };

  const renderInstitutionPropertiesTableBody = () => {
    if (!config) {
      return (
        <TableRow>
          <TableCell>
            <Alert severity="error">{translate('global.getConfigError')}</Alert>
          </TableCell>
        </TableRow>
      );
    }

    if (!config.institutionProperties.length || !properties) {
      return (
        <TableRow>
          <TableCell>{translate('institutions.noProperties')}</TableCell>
        </TableRow>
      );
    }

    return config.institutionProperties.map((propertyName) => {
      if (properties[propertyName]) {
        return (
          <TableRow>
            <TableCell component="th" scope="row" sx={styles.propertyNameCell}>
              {propertyName}
            </TableCell>
            <TableCell>{properties[propertyName]}</TableCell>
          </TableRow>
        );
      }
    });
  };

  return (
    <>
      <TableRow sx={styles.row} hover>
        <TableCell sx={styles.slimCell}>
          <IconButton aria-label={translate('global.expandRow')} size="small" onClick={handleToggleRow}>
            {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={styles.slimCell}>{index + 1}</TableCell>
        <TableCell sx={styles.slimCell}>{id}</TableCell>
        <TableCell sx={styles.slimCell}>{shortName}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{regon}</TableCell>
        <TableCell>{rspo}</TableCell>
      </TableRow>
      <TableRow sx={styles.collapsedRow}>
        <TableCell colSpan={2} />
        <TableCell colSpan={3}>
          <Collapse in={rowOpen}>
            <Table sx={styles.nestedTable} size="small">
              <TableBody>{renderInstitutionPropertiesTableBody()}</TableBody>
            </Table>
          </Collapse>
        </TableCell>
        <TableCell colSpan={2} />
      </TableRow>
    </>
  );
};
