import { useState } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { ConfirmDialog } from 'ui/confirmDialog/ConfirmDialog';
import { ConfirmFormAbortContext } from '../confirmFormAbortContext/ConfirmFormAbortContext';
import { ConfirmFormAbortContent } from '../confirmFormAbortContext/ConfirmFormAbortContext.types';

import { ConfirmFormAbortContextControllerProps } from './ConfirmFormAbortContextController.types';

export const ConfirmFormAbortContextController = ({ children }: ConfirmFormAbortContextControllerProps) => {
  const translate = useTranslator();

  const [isVisible, setIsVisible] = useState(false);

  const [content, setContent] = useState<ConfirmFormAbortContent>({
    description: translate('global.confirmFormAbortDialogDescription'),
    title: translate('global.confirmFormAbortDialogTitle'),
  });

  const enable = (content?: ConfirmFormAbortContent) => {
    if (content) {
      setContent(content);
    }

    setIsVisible(true);
  };

  const disable = () => {
    setIsVisible(false);
  };

  return (
    <ConfirmFormAbortContext.Provider value={{ disable, enable }}>
      <NavigationPrompt when={isVisible}>
        {({ onConfirm, onCancel }) => (
          <ConfirmDialog
            id="form-leave-dialog"
            description={content.description}
            onCancel={onCancel}
            onConfirm={() => {
              setIsVisible(false);
              onConfirm();
            }}
            show={isVisible}
            title={content.title}
          />
        )}
      </NavigationPrompt>
      {children}
    </ConfirmFormAbortContext.Provider>
  );
};
