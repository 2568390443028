import { AxiosRequestConfig } from 'axios';

import { umsApi } from 'api/axios/axiosInstance';

import { UploadFilePayload } from './upload.types';

const fileFormDataKey = 'file';

export const uploadFile = async <T>(payload: UploadFilePayload): Promise<T> => {
  const { data: rawData, setCompleted, url } = payload;

  const config: AxiosRequestConfig = {
    onUploadProgress: ({ loaded, total }: ProgressEvent) => {
      const percentCompleted = Math.floor((loaded * 100) / total);

      setCompleted(percentCompleted);
    },
  };

  const data = new FormData();

  data.append(fileFormDataKey, rawData.file[0]);

  const response = await umsApi.post(url, data, config);

  return response.data;
};
