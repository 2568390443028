import { toast, TypeOptions } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useFormatEvent } from 'hooks/format/useFormatEvent/useFormatEvent';
import { useMarkNotificationAsRead } from '../useMarkNotificationAsRead/useMarkNotificationAsRead';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppMessages } from 'i18n/messages';
import { Notification } from 'api/actions/notifications/notificationsActions.types';
import { Queries } from 'api/queries/queries.enum';

const isOfToastTypeOptions = (notificationType: string): notificationType is TypeOptions => {
  return ['warning', 'info', 'success', 'error', 'default', 'dark'].includes(notificationType);
};

export const useShowNotifications = () => {
  const translate = useTranslator();

  const queryClient = useQueryClient();

  const { user } = useAuthState();

  const [currentNotifications, setCurentNotifications] = useState<Notification[]>([]);

  const { formatEventContextValues } = useFormatEvent();

  const mutationMarkNotificationAsRead = useMarkNotificationAsRead();

  const getNotificationType = (notificationType: string): TypeOptions => {
    if (isTranslationAvailable(notificationType)) {
      return isOfToastTypeOptions(translate(AppMessages[notificationType]))
        ? (translate(AppMessages[notificationType]) as TypeOptions)
        : 'default';
    }

    return 'default';
  };

  const handleCloseToast = (id: string | null) => {
    if (id) {
      mutationMarkNotificationAsRead.mutate({ id });
    }
  };

  const showNotifications = (notifications: Notification[]) => {
    notifications.forEach(({ content, context, id }) => {
      setCurentNotifications(notifications);

      const isBulkAction = content.includes('NOTIFICATION.BULK_ACTION');

      const notificationMessage = (() => {
        if (isBulkAction) {
          return `${content}.${context?.bulkActionType}`;
        }

        return content;
      })();

      const notificationType = `${content}.type`;

      const toastContentValues = context && formatEventContextValues(context);

      if (isTranslationAvailable(notificationMessage)) {
        toast(translate(AppMessages[notificationMessage], toastContentValues || undefined), {
          toastId: id || content,
          type: getNotificationType(notificationType),
          autoClose: false,
          onClose: () => handleCloseToast(id),
        });
      } else {
        toast(notificationMessage, {
          toastId: id || content,
          type: getNotificationType(notificationType),
        });
      }

      const shouldInvalidateUsersList = [
        'NOTIFICATION.BULK_ACTION.FINISHED',
        'NOTIFICATION.BULK_ACTION.FAILED',
      ].includes(content);

      if (shouldInvalidateUsersList) {
        queryClient.invalidateQueries(Queries.getUsers);
        queryClient.resetQueries(Queries.getUser);
      }
    });
  };

  useEffect(() => {
    if (!user) {
      currentNotifications.forEach(({ id, content }) => {
        toast.update(id || content, {
          onClose: null,
        });
      });
    }
  }, [user]);

  return showNotifications;
};
