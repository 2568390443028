import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClientProvider } from 'react-query';
import plLocale from 'date-fns/locale/pl';

import { getAppConfig } from 'utils/config';
import { theme } from 'utils/theme';
import { queryClient } from 'utils/queryClient';
import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { ErrorBoundary } from 'app/errorBoundary/ErrorBoundary';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { AxiosController } from 'context/axios/AxiosController';
import { UserController } from 'context/auth/userController/UserController';
import { ConfirmFormAbortContextController } from 'context/confirmFormAbort/confirmFormAbortContextController/ConfirmFormAbortContextController';

import { AppProvidersProps } from './AppProviders.types';

export const AppProviders = ({ children }: AppProvidersProps) => (
  <LocaleContextController>
    <Router basename={getAppConfig('PUBLIC_URL')}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ConfirmFormAbortContextController>
              <AuthContextController>
                <AxiosController>
                  <UserController>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                      <CssBaseline />
                      {children}
                    </LocalizationProvider>
                  </UserController>
                </AxiosController>
              </AuthContextController>
            </ConfirmFormAbortContextController>
          </ErrorBoundary>
        </ThemeProvider>
      </QueryClientProvider>
    </Router>
  </LocaleContextController>
);
