import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { startManageRole } from 'api/actions/bulk/bulk';
import { StartManageRolePayload, StartBulkActionResponse } from 'api/actions/bulk/bulk.types';

export const useStartManageRole = () => {
  const mutationStartManageRole = useMutation<StartBulkActionResponse, AxiosError, StartManageRolePayload>(
    startManageRole,
  );

  return mutationStartManageRole;
};
