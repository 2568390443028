import { Box, Typography } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { InstitutionsTable } from './institutionsTable/InstitutionsTable';

export const Institutions = () => {
  const translate = useTranslator();

  return (
    <>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          {translate('institutions.pageTitle')}
        </Typography>
      </Box>

      <InstitutionsTable />
    </>
  );
};
