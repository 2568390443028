import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getInstitutionsShortNames } from 'api/actions/institutions/institutionsActions';
import {
  InstitutionsShortNamesPayload,
  InstitutionsShortNamesResponse,
} from 'api/actions/institutions/institutionsActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetInstitutionsShortNames = (params: InstitutionsShortNamesPayload) => {
  const queryGetInstitutionsShortNames = useQuery<InstitutionsShortNamesResponse, AxiosError>(
    [Queries.getInstitutionsShortNames, params],
    () => getInstitutionsShortNames(params),
    { keepPreviousData: true },
  );

  return queryGetInstitutionsShortNames;
};
