import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';

import { useConfirmEmail } from 'hooks/email/useConfirmEmail/useConfirmEmail';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Loader } from 'ui/loader/Loader';

import * as styles from './EmailConfirm.styles';

export const EmailConfirm = () => {
  const translate = useTranslator();

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const token = queryParams.token as string;

  const mutationConfirmEmail = useConfirmEmail();
  const { isError, isLoading, isSuccess, mutate } = mutationConfirmEmail;

  useEffect(() => {
    mutate({
      token: token,
    });
  }, []);

  if (isLoading) {
    return <Loader data-testid="email-confirm-loader" />;
  }

  if (isError) {
    return (
      <Paper sx={styles.wrapper}>
        <InfoIcon color="disabled" sx={styles.icon} />
        <Typography sx={styles.description}>{translate('emailConfirm.linkExpired')}</Typography>
      </Paper>
    );
  }

  if (isSuccess) {
    return (
      <Paper sx={styles.wrapper}>
        <CheckCircleIcon color="primary" sx={styles.icon} />
        <Typography sx={styles.description}>{translate('emailConfirm.success')}</Typography>
      </Paper>
    );
  }

  return null;
};
