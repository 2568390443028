import { Styles } from '@typings/common';

export const stepper: Styles = {
  my: 3,
  background: 'inherit',

  '.MuiStep-root:first-child': {
    pl: 0,
  },
};

export const buttonBox: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',

  '& >:last-child': {
    ml: 1,
  },
};
