import { HTMLAttributes, useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, Typography, TextField } from '@mui/material';

import { formatUsersFilterValues } from 'utils/function/formatUsersFilterValues/formatUsersFilterValues';
import { isInstitutionRequired } from 'utils/function/isInstitutionRequired/isInstitutionRequired';
import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useCheckRestrictedRoles } from 'hooks/useCheckRestrictedRoles/useCheckRestrictedRoles';
import { useGetInstitutionsShortNames } from 'hooks/institutions/useGetInstitutionsShortNames/useGetInstitutionsShortNames';
import { useGetRoles } from 'hooks/useGetRoles/useGetRoles';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useStartManageRole } from 'hooks/bulk/useStartManageRole/useStartManageRole';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { Autocomplete } from 'ui/autocomplete/Autocomplete';
import { ConfirmDialog } from 'ui/confirmDialog/ConfirmDialog';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';
import { ManageRole as ManageRoleVariant } from 'api/actions/bulk/bulk.enum';
import { Role } from 'api/actions/roles/rolesActions.types';
import { RoleOption } from 'ui/autocomplete/roleOption/RoleOption';
import * as styles from '../UsersBulkActions.styles';

import { useManageRoleDataSchema } from './ManageRoleDataSchema';
import { FormFields } from './ManageRole.enum';
import { ManageRoleFormData, ManageRoleProps, Translations } from './ManageRole.types';

export const ManageRole = ({ onCloseBulkAction, variant }: ManageRoleProps) => {
  const translate = useTranslator();

  const { checkIsRestrictedRole } = useCheckRestrictedRoles();

  const { selectedRows, numOfSelectedRows, areSelectedAllOnAllPages, filters, searchPhrase } = useUsersState();
  const { handleResetSelectedRows, handleToggleAreSelectedAllOnAllPages } = useUsersMethods();

  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

  const assignRoleDataSchema = useManageRoleDataSchema();

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<ManageRoleFormData>({
    resolver: yupResolver(assignRoleDataSchema),
    mode: 'onChange',
  });

  const mutationStartManageRole = useStartManageRole();

  const serverErrors: string[] | Record<string, string> = mutationStartManageRole.error?.response?.data?.errors;

  useEffect(() => {
    if (mutationStartManageRole.isError) {
      setIsConfirmDialogVisible(false);
    }
  }, [mutationStartManageRole.isError]);

  const onStartChangeStatus = async () => {
    const params = removeEmptyFields({
      ...formatUsersFilterValues(filters),
      searchPhrase,
      allUsers: true,
    });

    await mutationStartManageRole.mutateAsync({
      params: areSelectedAllOnAllPages ? params : undefined,
      userIds: !areSelectedAllOnAllPages ? selectedRows : undefined,
      roleId: getValues(FormFields.role)?.id || '',
      institutionId: getValues(FormFields.institution)?.id || null,
      variant,
    });

    onCloseBulkAction();
    handleResetSelectedRows();
    handleToggleAreSelectedAllOnAllPages(false);
  };

  const onSubmit = () => {
    setIsConfirmDialogVisible(true);
  };

  const queryGetRoles = useGetRoles({
    itemsPerPage: 50,
  });

  const queryGetInstitutionsShortNames = useGetInstitutionsShortNames({
    itemsPerPage: 100,
  });

  const roleSelected = watch(FormFields.role);
  const isAdminRoleSelected = !!roleSelected && isInstitutionRequired(roleSelected);
  const isUserRoleRestricted = !!roleSelected && checkIsRestrictedRole(roleSelected);

  const variantTranslations: Translations = {
    [ManageRoleVariant.assign]: {
      name: translate('users.bulkActionsAssignRole'),
      submitButton: translate('users.bulkActionsAssignRoleSubmit'),
      confirmDialog: {
        id: 'confirmAssignRole',
        title: translate('users.bulkActionsAssignRoleConfirmDialogTitle'),
        description: translate('users.bulkActionsAssignRoleConfirmDialogDescription', {
          numOfUsers: areSelectedAllOnAllPages
            ? translate('users.buttonBulkActionsAllUsers').toLocaleLowerCase()
            : numOfSelectedRows,
          institution: getValues(FormFields.institution)?.shortName,
          role: getValues(FormFields.role)?.name,
        }),
        submitButton: translate('users.bulkActionsAssignRoleConfirmDialogSubmit'),
      },
    },
    [ManageRoleVariant.detach]: {
      name: translate('users.bulkActionsDetachRole'),
      submitButton: translate('users.bulkActionsDetachRoleSubmit'),
      confirmDialog: {
        id: 'confirmDetachRole',
        title: translate('users.bulkActionsDetachRoleConfirmDialogTitle'),
        description: translate('users.bulkActionsDetachRoleConfirmDialogDescription', {
          numOfUsers: areSelectedAllOnAllPages
            ? translate('users.buttonBulkActionsAllUsers').toLocaleLowerCase()
            : numOfSelectedRows,
          institution: getValues(FormFields.institution)?.shortName,
          role: getValues(FormFields.role)?.name,
        }),
        submitButton: translate('users.bulkActionsDetachRoleConfirmDialogSubmit'),
      },
    },
  };

  return (
    <>
      <Dialog open>
        <Box sx={styles.dialogContainer}>
          <Typography component="h2" variant="h5" sx={styles.title}>
            {variantTranslations[variant].name}
          </Typography>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={styles.form}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="role"
                  size="small"
                  disabled={isUserRoleRestricted}
                  loading={queryGetRoles.isFetching}
                  options={queryGetRoles.data ? queryGetRoles.data?.items : []}
                  renderOption={(props, option: Role) => (
                    <li {...props}>
                      <RoleOption option={option} />
                    </li>
                  )}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionDisabled={(option) => checkIsRestrictedRole(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate('usersForm.userRole')}
                      placeholder={translate('usersForm.userRole')}
                      variant="outlined"
                      error={!!errors?.role}
                      helperText={(errors?.role as FieldError)?.message}
                    />
                  )}
                  onChange={(_, data) => {
                    if (!!data && isInstitutionRequired(data)) {
                      setValue(FormFields.institution, null);
                      clearErrors(FormFields.institution);
                    }
                    field.onChange(data);
                    trigger(FormFields.role);
                  }}
                  sx={styles.formField}
                  data-testid="role-autocomplete"
                  ListboxProps={{ 'data-testid': 'role-list' } as HTMLAttributes<HTMLUListElement>}
                />
              )}
              name={FormFields.role}
              control={control}
            />

            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="institution"
                  disabled={isAdminRoleSelected || isUserRoleRestricted}
                  loading={queryGetInstitutionsShortNames.isFetching}
                  size="small"
                  options={queryGetInstitutionsShortNames.data ? queryGetInstitutionsShortNames.data?.items : []}
                  getOptionLabel={(option) => option.shortName}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate('usersForm.userInstitution')}
                      placeholder={translate('usersForm.userInstitution')}
                      variant="outlined"
                      error={!!errors?.institution}
                      helperText={(errors?.institution as FieldError)?.message}
                    />
                  )}
                  onChange={(_, data) => {
                    field.onChange(data);
                    trigger(FormFields.role);
                  }}
                  sx={styles.formField}
                  data-testid="institution-autocomplete"
                  ListboxProps={{ 'data-testid': 'institution-list' } as HTMLAttributes<HTMLUListElement>}
                />
              )}
              name={FormFields.institution}
              control={control}
            />

            {serverErrors && <FormServerErrors serverErrors={serverErrors} sx={styles.serverErrorsList} />}

            <Box sx={styles.buttonBox}>
              <Button type="submit" onClick={onCloseBulkAction} color="inherit">
                {translate('global.buttonCancel')}
              </Button>

              <Button type="submit" variant="contained" color="primary">
                {variantTranslations[variant].submitButton}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <ConfirmDialog
        show={isConfirmDialogVisible}
        id={variantTranslations[variant].confirmDialog.id}
        title={variantTranslations[variant].confirmDialog.title}
        description={variantTranslations[variant].confirmDialog.description}
        onCancel={onCloseBulkAction}
        onConfirm={onStartChangeStatus}
        onConfirmButtonText={variantTranslations[variant].confirmDialog.submitButton}
        isProcessing={mutationStartManageRole.isLoading}
      />
    </>
  );
};
