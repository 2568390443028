import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { RestrictedSectionTooltip } from 'hoc/restrictedSectionTooltip/RestrictedSectionTooltip';

import { EditUserButtonProps } from './EditUserButton.types';

export const EditUserButton = ({ id, isUserBlacklisted }: EditUserButtonProps) => {
  const translate = useTranslator();

  return (
    <RestrictedSectionTooltip
      isRestricted={isUserBlacklisted}
      title={translate('users.userWithBlacklistedRoleTooltip')}
    >
      <Button
        component={RouterLink}
        to={`/users/edit/${id}`}
        disabled={isUserBlacklisted}
        color="primary"
        variant="contained"
      >
        {translate('global.buttonEdit')}
      </Button>
    </RestrictedSectionTooltip>
  );
};
