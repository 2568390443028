export enum Queries {
  getEventLogs = 'getEventLogs',
  getInstitutions = 'getInstitutions',
  getInstitutionsShortNames = 'getInstitutionsShortNames',
  getLoginLogs = 'getLoginLogs',
  getNotifications = 'getNotifications',
  getRoles = 'getRoles',
  getUser = 'getUser',
  getUserMe = 'getUserMe',
  getUsers = 'getUsers',
  getConfig = 'getConfig',
  getPasswordSyntax = 'getPasswordSyntax',
  getStartupPassword = 'getStartupPassword',
  logoutFromServiceProviders = 'logoutFromServiceProviders',
  checkLoggedInRealms = 'chceckLoggedInRealms',
}

export enum QueriesStatus {
  idle = 'idle',
  error = 'error',
  loading = 'loading',
  success = 'success',
}
