// Forked from https://github.com/jacobbuck/react-beforeunload
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';

import { EventType, UseBeforePageLeaveHandler } from './useBeforePageLeave.types';

export const useBeforePageLeave = (handler?: UseBeforePageLeaveHandler) => {
  const eventType = isMobile ? 'pagehide' : 'beforeunload';

  const eventListenerRef = useRef<UseBeforePageLeaveHandler>();

  useEffect(() => {
    eventListenerRef.current = (event: EventType) => {
      const returnValue = handler?.(event);
      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === 'string') {
        return (event.returnValue = returnValue);
      }
      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        return (event.returnValue = '');
      }
    };
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: EventType) => eventListenerRef.current?.(event);
    window.addEventListener(eventType, eventListener);
    return () => {
      window.removeEventListener(eventType, eventListener);
    };
  }, []);
};
