import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, MenuItem, Typography } from '@mui/material';

import { formatUsersFilterValues } from 'utils/function/formatUsersFilterValues/formatUsersFilterValues';
import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useFormatUserStatus } from 'hooks/format/useFormatUserStatus/useFormatUserStatus';
import { useStartChangeStatus } from 'hooks/bulk/useStartChangeStatus/useStartChangeStatus';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { BulkActionComponentProps } from '../UsersBulkActions.types';
import { ConfirmDialog } from 'ui/confirmDialog/ConfirmDialog';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';
import { StatusName } from 'api/actions/users/usersActions.enum';
import { Select } from 'ui/select/Select';
import * as styles from '../UsersBulkActions.styles';

import { useChangeStatusDataSchema } from './ChangeStatusDataSchema';
import { ChangeStatusFormData } from './ChangeStatus.types';
import { FormFields } from './ChangeStatus.enum';

export const ChangeStatus = ({ onCloseBulkAction }: BulkActionComponentProps) => {
  const translate = useTranslator();

  const { selectedRows, numOfSelectedRows, areSelectedAllOnAllPages, filters, searchPhrase } = useUsersState();
  const { handleResetSelectedRows, handleToggleAreSelectedAllOnAllPages } = useUsersMethods();

  const formatUserStatus = useFormatUserStatus();

  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

  const changeStatusDataSchema = useChangeStatusDataSchema();

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<ChangeStatusFormData>({
    resolver: yupResolver(changeStatusDataSchema),
    mode: 'onChange',
  });

  const mutationStartChangeStatus = useStartChangeStatus();

  const serverErrors: string[] | Record<string, string> = mutationStartChangeStatus.error?.response?.data?.errors;

  useEffect(() => {
    if (mutationStartChangeStatus.isError) {
      setIsConfirmDialogVisible(false);
    }
  }, [mutationStartChangeStatus.isError]);

  const onStartChangeStatus = async () => {
    const params = removeEmptyFields({
      ...formatUsersFilterValues(filters),
      searchPhrase,
      allUsers: true,
    });

    await mutationStartChangeStatus.mutateAsync({
      params: areSelectedAllOnAllPages ? params : undefined,
      userIds: !areSelectedAllOnAllPages ? selectedRows : undefined,
      status: getValues(FormFields.status),
    });

    onCloseBulkAction();
    handleResetSelectedRows();
    handleToggleAreSelectedAllOnAllPages(false);
  };

  const onSubmit = () => {
    setIsConfirmDialogVisible(true);
  };

  return (
    <>
      <Dialog open>
        <Box sx={styles.dialogContainer}>
          <Typography component="h2" variant="h5" sx={styles.title}>
            {translate('users.bulkActionsChangeStatusConfirmDialogTitle')}
          </Typography>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={styles.form}>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  id="status"
                  label={translate('global.status')}
                  variant="outlined"
                  inputProps={{
                    'data-testid': 'status-select',
                    'aria-label': translate('global.status'),
                    'aria-labelledby': 'status',
                  }}
                  select
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  sx={styles.formField}
                >
                  <MenuItem value={StatusName.active}>{formatUserStatus(StatusName.active)}</MenuItem>
                  <MenuItem value={StatusName.blocked}>{formatUserStatus(StatusName.blocked)}</MenuItem>
                </Select>
              )}
              name={FormFields.status}
              control={control}
            />

            {serverErrors && <FormServerErrors serverErrors={serverErrors} sx={styles.serverErrorsList} />}

            <Box sx={styles.buttonBox}>
              <Button type="submit" onClick={onCloseBulkAction} color="inherit">
                {translate('global.buttonCancel')}
              </Button>

              <Button type="submit" variant="contained" color="primary">
                {translate('users.bulkActionsChangeStatusSubmit')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <ConfirmDialog
        show={isConfirmDialogVisible}
        id="confirmChangeStatus"
        title={translate('users.bulkActionsChangeStatusConfirmDialogTitle')}
        description={translate('users.bulkActionsChangeStatusConfirmDialogDescription', {
          numOfUsers: areSelectedAllOnAllPages
            ? translate('users.buttonBulkActionsAllUsers').toLocaleLowerCase()
            : numOfSelectedRows,
          status: formatUserStatus(getValues('status')),
        })}
        onCancel={onCloseBulkAction}
        onConfirm={onStartChangeStatus}
        onConfirmButtonText={translate('users.bulkActionsChangeStatusConfirmDialogSubmit')}
        isProcessing={mutationStartChangeStatus.isLoading}
      />
    </>
  );
};
