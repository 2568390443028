import yup from 'utils/yup';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const useFileDataSchema = () => {
  const translate = useTranslator();

  const fileSize = 25 * 1024 * 1024;
  const supportedFormats = [
    'text/comma-separated-values',
    'text/csv',
    'application/csv',
    'application/excel',
    'application/vnd.ms-excel',
    'application/vnd.msexcel',
  ];

  return yup.object().shape({
    file: yup
      .mixed()
      .test('required', translate('global.fieldRequired'), (value) => value?.length > 0)
      .test('fileSize', translate('global.fileTooLarge'), (value) => value?.length > 0 && value[0].size <= fileSize)
      .test(
        'fileFormat',
        translate('global.fileWrongFormat'),
        (value) => value?.length > 0 && supportedFormats.includes(value[0].type),
      ),
  });
};
