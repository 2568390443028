import { TableCell, TableCellProps, TableHead, TableRow } from '@mui/material';
import { ReactNode } from 'react';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { EventLogWithTimestamp } from 'api/actions/eventLogs/eventLogs.types';
import { SortableTableCell as GenericSortableCell } from 'ui/table/cell/SortableTableCell';

import { EventLogsTableHeaderProps } from './EventLogsTableHeader.props';

export const EventLogsTableHeader = ({ orderBy, orderDirection, onRequestSort }: EventLogsTableHeaderProps) => {
  const translate = useTranslator();

  const SortableTableCell = ({
    id,
    children,
    ...props
  }: { id: keyof EventLogWithTimestamp; children: ReactNode } & TableCellProps) => (
    <GenericSortableCell<EventLogWithTimestamp>
      id={id}
      orderBy={orderBy}
      orderDirection={orderDirection}
      onRequestSort={onRequestSort}
      {...props}
    >
      {children}
    </GenericSortableCell>
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell>{translate('eventLogs.eventId')}</TableCell>
        <SortableTableCell id="login">{translate('global.login')}</SortableTableCell>
        <TableCell>{translate('eventLogs.application')}</TableCell>
        <TableCell>{translate('eventLogs.userId')}</TableCell>
        <SortableTableCell id={'eventTimestamp'}>{translate('eventLogs.eventDateTime')}</SortableTableCell>
        <SortableTableCell id="eventType">{translate('eventLogs.eventType')}</SortableTableCell>
        <TableCell>{translate('eventLogs.processType')}</TableCell>
        <TableCell>{translate('eventLogs.processId')}</TableCell>
        <TableCell>{translate('eventLogs.eventObject')}</TableCell>
        <SortableTableCell id="eventSource">{translate('eventLogs.eventSource')}</SortableTableCell>
        <TableCell>{translate('eventLogs.ipAddress')}</TableCell>
      </TableRow>
    </TableHead>
  );
};
