import { TableCell, TableRow } from '@mui/material';
import { Alert } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import * as styles from './NoData.styles';

export const NoData = () => {
  const translate = useTranslator();

  return (
    <TableRow>
      <TableCell sx={styles.alertCell} colSpan={100}>
        <Alert severity="warning">{translate('global.noResults')}</Alert>
      </TableCell>
    </TableRow>
  );
};
