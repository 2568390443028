import { useContext } from 'react';

import { ConfigContext } from 'context/config/configContext/ConfigContext';
import { ConfigResponse } from 'api/actions/config/configActions.types';

export const useConfigState: () => ConfigResponse = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfigState must be used within an ConfigContextController');
  }

  if (context === null) {
    throw new Error('Config is required');
  }

  return context;
};
