import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { startRemove } from 'api/actions/bulk/bulk';
import { StartRemovePayload, StartBulkActionResponse } from 'api/actions/bulk/bulk.types';

export const useStartRemove = () => {
  const mutationStartRemove = useMutation<StartBulkActionResponse, AxiosError, StartRemovePayload>(startRemove);

  return mutationStartRemove;
};
