import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';

import { RolesParams, RolesPayload, RolesResponse } from './rolesActions.types';

export const getRoles = async (payload: RolesPayload): Promise<RolesResponse> => {
  const { pageNumber = 0, itemsPerPage = 30, searchPhrase } = payload;

  const params: RolesParams = {
    items_per_page: itemsPerPage,
    page_number: pageNumber + 1,
    search_phrase: searchPhrase,
  };

  const response = await umsApi.get(endpoints.roles, {
    params,
  });

  return response.data;
};
