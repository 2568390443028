import { Theme } from '@mui/material';

import { Styles } from '@typings/common';

export const appBar: Styles = {
  zIndex: 'drawer',
  color: 'text.primary',
  backgroundColor: 'background.paper',
  boxShadow: 'none',
  borderBottom: (theme: Theme) => `2px solid ${theme.palette.border?.main}`,
};

export const toolbar: Styles = {
  px: { xs: 2 },
  display: { lg: 'flex' },
  width: { lg: '100%' },
  ml: { lg: 'auto' },
  textTransform: { lg: 'none' },
};

export const homeLink: Styles = {
  textDecoration: 'none',
  color: 'text.primary',
};

export const appTitle: Styles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: { xs: '0.95rem', sm: '1rem', md: '1.25rem' },
  fontWeight: 500,
};

export const topbarContent: Styles = {
  ml: 1,
};

export const openDrawer: Styles = {
  display: { lg: 'none' },
};

export const disabledUserBox: Styles = {
  '&.Mui-disabled': {
    color: 'initial ',
  },
};

export const userBox = (hasAccessOnlyPasswordChange: boolean): Styles => ({
  display: { xs: 'none', lg: 'flex' },
  ml: { lg: 'auto' },
  textTransform: { lg: 'none' },
  borderRadius: 0,
  ...(hasAccessOnlyPasswordChange ? disabledUserBox : {}),
});

export const userFullName: Styles = {
  mr: 1,
};

export const userAvatar: Styles = {
  width: '2rem',
  height: '2rem',
  fontSize: '1rem',
};

export const userMenu: Styles = {
  width: '10rem',
  mt: 1.5,
  borderRadius: 0,
  boxShadow: [3],
};

export const userMenuList: Styles = {
  pb: 0,
};

export const userMenuItem: Styles = {
  '& a': {
    p: 1.5,
    fontSize: (theme: Theme) => theme.typography.body2,
  },

  '&:last-child': {
    borderTop: (theme: Theme) => `1px solid ${theme.palette.border?.main}`,
  },
};

export const userMenuItemIcon: Styles = {
  mr: 1,
};
