import yup from 'utils/yup';
import { isInstitutionRequired } from 'utils/function/isInstitutionRequired/isInstitutionRequired';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Role } from 'api/actions/roles/rolesActions.types';

export const useManageRoleDataSchema = () => {
  const translate = useTranslator();

  return yup.object().shape({
    role: yup.mixed().nullable().required(translate('global.fieldRequired')),
    institution: yup.mixed().when('role', {
      is: (role: Role) => {
        return !role || !isInstitutionRequired(role);
      },
      then: yup.object().nullable().required(translate('global.fieldRequired')),
    }),
  });
};
