import { Styles } from '@typings/common';

export const dialogContainer: Styles = {
  p: 2,
};

export const title: Styles = {
  mb: 2,
};

export const form: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const formField: Styles = {
  mb: 2,
  width: '40rem',
  maxWidth: '100%',
};

export const buttonBox: Styles = {
  alignSelf: 'flex-end',
  display: 'flex',
  gap: 1,
};

export const serverErrorsList: Styles = {
  mb: 2,
};
