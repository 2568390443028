import { createTheme } from '@mui/material';

import { plPL as plPLPickers } from 'utils/pickersLocale/plPl';

declare module '@mui/material/styles' {
  interface Palette {
    border?: Palette['primary'];
  }
  interface PaletteOptions {
    border?: PaletteOptions['primary'];
  }
}

const defaultFont = ['Roboto', 'sans-serif'].join(',');

const paletteTheme = createTheme({
  palette: {
    background: {
      default: '#f6f6f6',
    },
    primary: {
      main: '#007ac3',
    },
    secondary: {
      main: '#cd2026',
    },
    text: {
      primary: '#474747',
    },
    border: {
      main: '#dadada',
    },
    error: {
      main: '#cd2026',
    },
    success: {
      main: '#2e8540',
    },
    warning: {
      main: '#f9c642',
    },
  },
});

const { breakpoints, palette, spacing } = paletteTheme;

export const theme = createTheme(
  paletteTheme,
  {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontFamily: defaultFont,
          },
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
          ':root': {
            '--toastify-color-success': palette.success.main,
            '--toastify-color-warning': palette.warning.main,
            '--toastify-color-error': palette.error.main,
            '--toastify-text-color-warning': palette.text.primary,
          },
          '.Toastify__toast-container--top-right': {
            [breakpoints.up('md')]: {
              top: '4.5rem',
              right: '0.75rem',
            },
          },
          '.Toastify__toast--warning .Toastify__close-button > svg': {
            color: palette.text.primary,
          },
          '.grecaptcha-badge': {
            zIndex: 1300,

            [breakpoints.up('md')]: {
              bottom: '4.5rem !important',
            },
          },
          'input[type=password]::-ms-reveal, input[type=password]::-ms-clear': {
            display: 'none',
          },
          '.MuiTableCell-root': {
            whiteSpace: 'nowrap',
          },
          '.visuallyHidden': {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            height: '100%',
          },
          label: {
            paddingTop: spacing(0.25),
            paddingBottom: spacing(0.25),
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
            textOverflow: 'clip',
          },
        },
      },
    },
    typography: {
      fontFamily: defaultFont,
    },
  },
  plPLPickers,
);

export const drawerOpenWidth = 240;
export const drawerCloseWidth = parseInt(theme.spacing(7)) + 1;
