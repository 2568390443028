import { useContext } from 'react';

import { UsersStateContext } from 'context/users/usersContext/UsersContext';
import { UsersStateContextValueType } from 'context/users/usersContext/UsersContext.types';

export const useUsersState: () => UsersStateContextValueType = () => {
  const context = useContext(UsersStateContext);

  if (context === undefined) {
    throw new Error('useUsersState must be used within an UsersContextController');
  }

  return context;
};
