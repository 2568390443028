import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { EventType, EventSource, BulkActionType, ProcessType } from 'api/actions/eventLogs/eventLogs.enum';
import { EventContext } from 'api/actions/eventLogs/eventLogs.types';

export const useFormatEvent = () => {
  const translate = useTranslator();

  const eventSourceLabels = {
    [EventSource.ums]: translate('eventSource.ums'),
    [EventSource.application]: translate('eventSource.application'),
  };

  const formatEventSource = (eventSource: EventSource) => {
    return eventSourceLabels[eventSource] || eventSource;
  };

  const eventTypeLabels = {
    [EventType.bulkActionStarted]: translate('eventType.bulkActionStarted'),
    [EventType.bulkActionFinished]: translate('eventType.bulkActionFinished'),
    [EventType.bulkActionFailed]: translate('eventType.bulkActionFailed'),
    [EventType.userActivated]: translate('eventType.userActivated'),
    [EventType.userCreated]: translate('eventType.userCreated'),
    [EventType.userEdited]: translate('eventType.userEdited'),
    [EventType.userRemoved]: translate('eventType.userRemoved'),
    [EventType.userBlocked]: translate('eventType.userBlocked'),
    [EventType.roleAssignedToUser]: translate('eventType.roleAssignedToUser'),
    [EventType.roleDetachedFromUser]: translate('eventType.roleDetachedFromUser'),
    [EventType.userStartupPasswordChanged]: translate('eventType.userStartupPasswordChanged'),
    [EventType.userPasswordChanged]: translate('eventType.userPasswordChanged'),
    [EventType.userPasswordReset]: translate('eventType.userPasswordReset'),
  };

  const formatEventContextValues = (context: EventContext) => {
    const bulkActionType = context.bulkActionType ? formatBulkActionType(context.bulkActionType) : undefined;

    const errors = (() => {
      if (!context.errors) {
        return undefined;
      }

      return context.errors
        .map((error) => {
          if (error.includes('@')) {
            const [errorKey, fieldName] = error.split('@');

            return isTranslationAvailable(errorKey) ? translate(errorKey, { fieldName: fieldName }) : error;
          }

          return isTranslationAvailable(error) ? translate(error) : error;
        })
        .join(', ');
    })();

    const nonAffectedItems =
      typeof context.itemsCount === 'number' && typeof context.affectedItems === 'number'
        ? context.itemsCount - context.affectedItems
        : undefined;

    const lineWhereImportWasStopped =
      context.bulkActionType === BulkActionType.usersImport && typeof context.failedOnItemId === 'string'
        ? Number(context.failedOnItemId) + 1
        : undefined;

    return {
      affectedItems: context.affectedItems,
      bulkActionId: context.bulkActionId,
      bulkActionType,
      errors,
      failedOnItemId: context.failedOnItemId,
      lineWhereImportWasStopped,
      institutionId: context.institutionId,
      institutionShortName: context.institutionShortName,
      itemsCount: context.itemsCount,
      nonAffectedItems,
      roleId: context.roleId,
      roleName: context.roleName,
      userId: context.userId,
      userLogin: context.userLogin,
    };
  };

  const formatEventType = (eventType: EventType) => {
    return eventTypeLabels[eventType] || eventType;
  };

  const bulkActionTypeLabel = {
    [BulkActionType.usersStatusChange]: translate('bulkActionType.usersStatusChange'),
    [BulkActionType.usersRemove]: translate('bulkActionType.usersRemove'),
    [BulkActionType.roleAssign]: translate('bulkActionType.roleAssign'),
    [BulkActionType.roleDetach]: translate('bulkActionType.roleDetach'),
    [BulkActionType.usersImport]: translate('bulkActionType.usersImport'),
  };

  const formatBulkActionType = (bulkActionType: BulkActionType) => {
    return bulkActionTypeLabel[bulkActionType] || bulkActionType;
  };

  const processTypeLabels = {
    [ProcessType.userAdding]: translate('processType.userAdding'),
    [ProcessType.userEditing]: translate('processType.userEditing'),
    [ProcessType.userRemoving]: translate('processType.userRemoving'),
    [ProcessType.userPasswordChanging]: translate('processType.userPasswordChanging'),
    [ProcessType.userPasswordReseting]: translate('processType.userPasswordReseting'),
    [ProcessType.userEmailChanging]: translate('processType.userEmailChanging'),
    [ProcessType.userNotConfirmedEmailChanging]: translate('processType.userNotConfirmedEmailChanging'),
  };

  const formatProcessType = (processType: ProcessType) => {
    return processTypeLabels[processType] || processType;
  };

  return { formatBulkActionType, formatEventContextValues, formatEventSource, formatEventType, formatProcessType };
};
