import { Styles } from '@typings/common';

export const container: Styles = {
  mb: 2,
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  rowGap: 1,
};

export const addButton: Styles = {
  width: '6rem',
};

export const moreButton: Styles = {
  ml: 1,
};
