import { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { endpoints } from 'api/endpoints/endpoints';
import { useCheckImportStatus } from 'hooks/import/useCheckImportStatus/useCheckImportStatus';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useInitImport } from 'hooks/import/useInitImport/useInitImport';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useGetImportValidationResult } from 'hooks/import/useGetImportValidationResult/useGetImportValidationResult';
import { ImportActionPayload } from 'api/actions/imports/impportActions.types';
import { Loader } from 'ui/loader/Loader';
import { AppRoute } from 'routing/AppRoute.enum';
import * as styles from '../../UsersImport.styles';

import { ErrorValidationProps, ImportUsersValidationResultResponse } from './ErrorValidation.types';
import { ImportDataPreview } from './importDataPreview/ImportDataPreview';
import { ServerErrorsList } from './serverErrorsLIst/ServerErrorsList';

export const ErrorValidation = (props: ErrorValidationProps) => {
  const translate = useTranslator();

  const history = useHistory();

  const { mutationCheckImportStatus, isValidated } = useCheckImportStatus();

  const confirmFormAbort = useConfirmFormAbort();

  const {
    formState: { id },
    onClickBack,
  } = props;

  useEffect(() => {
    confirmFormAbort.enable();
  }, []);

  useEffect(() => {
    if (id) {
      mutationCheckImportStatus.mutate({ id, url: endpoints.imports.users.checkStatus(id) });
    }
  }, []);

  const mutationGetImportValidationResult = useGetImportValidationResult<
    ImportActionPayload,
    ImportUsersValidationResultResponse
  >();

  useEffect(() => {
    if (id && isValidated) {
      mutationGetImportValidationResult.mutate({ id, url: endpoints.imports.users.validationResult(id) });
    }
  }, [isValidated]);

  const mutationInitImport = useInitImport<ImportActionPayload>();

  const handleInitImport = async () => {
    if (id && isValidated) {
      mutationInitImport.mutate(
        { id, url: endpoints.imports.users.init(id) },
        {
          onSuccess: () => {
            confirmFormAbort.disable();
            history.push(AppRoute.users);
          },
        },
      );
    }
  };

  if (mutationGetImportValidationResult.data) {
    const { errors, items, totalErrorsCount, totalItemsCount } = mutationGetImportValidationResult.data;

    const hasItems = items.length > 0;
    const hasErrors = errors.length > 0;

    return (
      <>
        {hasItems && (
          <ImportDataPreview data={items} totalItemsCount={totalItemsCount} totalErrorsCount={totalErrorsCount} />
        )}

        {hasErrors && <ServerErrorsList errors={errors} totalErrorsCount={totalErrorsCount} />}

        <Box sx={styles.buttonBox}>
          <Button onClick={onClickBack} color="inherit">
            {translate('global.buttonBack')}
          </Button>

          <Button
            onClick={handleInitImport}
            disabled={hasErrors || mutationInitImport.isLoading}
            variant="contained"
            color="primary"
          >
            {translate('usersImport.submitImport')}
          </Button>
        </Box>
      </>
    );
  }

  return <Loader data-testid="error-validation-loader" />;
};
