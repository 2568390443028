import { Checkbox, TableCell, TableCellProps, TableHead, TableRow } from '@mui/material';

import { permissions } from 'utils/permissions';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { AuthorizedSection } from 'hoc/authorizedSection/AuthorizedSection';
import { HeaderSortableCell } from 'app/users/usersTable/usersTableHeader/UsersTableHeader.types';
import { Permission } from 'api/actions/users/usersActions.enum';
import { SortableTableCell as GenericSortableCell } from 'ui/table/cell/SortableTableCell';
import { User } from 'api/actions/users/usersActions.types';

import * as styles from './UserTableHeader.styles';

export const UsersTableHeader = () => {
  const translate = useTranslator();

  const config = useConfigState();
  const isPasswordColumnVisible = config.passwordPolicy.isPasswordColumnVisible;

  const {
    numOfSelectedRows,
    orderBy,
    orderDirection,
    isSelectAllChecked,
    isIndeterminateChecked,
    areSelectedAllOnAllPages,
  } = useUsersState();

  const isCheckboxChecked = (numOfSelectedRows > 0 && isSelectAllChecked) || areSelectedAllOnAllPages;
  const isChecbkoxIndeterminate = numOfSelectedRows > 0 && isIndeterminateChecked;

  const { handleSelectAllRows, handleRequestSort } = useUsersMethods();

  const { areMultipleBindingKeys, bindingKeyValueLabel } = useBindingKey();

  const headCells: HeaderSortableCell[] = [
    { id: 'lastName', label: translate('global.lastName') },
    { id: 'firstName', label: translate('global.firstName') },
    { id: 'login', label: translate('global.login') },
    { id: 'bindingKey', label: bindingKeyValueLabel },
    { id: 'status', label: translate('global.status') },
    { id: 'modificationDate', label: translate('global.modificationDate') },
    { id: 'lastLoginDate', label: translate('global.lastLoginDate') },
  ];

  const SortableTableCell = ({ id, label, ...props }: { id: keyof User; label: string } & TableCellProps) => (
    <GenericSortableCell<User>
      id={id}
      orderBy={orderBy}
      orderDirection={orderDirection}
      onRequestSort={handleRequestSort}
      {...props}
    >
      {label}
    </GenericSortableCell>
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell component="td" sx={styles.slimCell} />
        <TableCell sx={styles.slimCell}>{translate('global.no')}</TableCell>
        <TableCell component="td" sx={styles.slimCell}>
          <Checkbox
            size="small"
            checked={isCheckboxChecked}
            disabled={areSelectedAllOnAllPages}
            indeterminate={isChecbkoxIndeterminate}
            inputProps={{ 'aria-label': translate('users.selectAll') }}
            onChange={handleSelectAllRows}
          />
        </TableCell>

        {headCells.slice(0, 3).map(({ id, label }) => (
          <SortableTableCell id={id} label={label} key={id} />
        ))}

        {areMultipleBindingKeys && <TableCell component="td">{translate('global.bindingKeyType')}</TableCell>}

        {headCells.splice(3, headCells.length - 1).map(({ id, label }) => (
          <SortableTableCell id={id} label={label} key={id} />
        ))}

        <AuthorizedSection allowedPermissions={permissions.passwordsManagement}>
          {isPasswordColumnVisible && <TableCell>{translate('global.startupPassword')}</TableCell>}
          <TableCell component="td" />
        </AuthorizedSection>
        <AuthorizedSection allowedPermissions={permissions.usersManage}>
          <TableCell component="td" />
          <AuthorizedSection allowedPermissions={[Permission.superAdministrator, Permission.userManagement]}>
            <TableCell component="td" />
          </AuthorizedSection>
        </AuthorizedSection>
      </TableRow>
    </TableHead>
  );
};
