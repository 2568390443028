import { useState } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';

import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useGetRoles } from 'hooks/useGetRoles/useGetRoles';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Role } from 'api/actions/roles/rolesActions.types';
import { DataLoading } from 'ui/table/row/dataLoading/DataLoading';
import { DataLoadingError } from 'ui/table/row/dataLoadingError/DataLoadingError';
import { NoData } from 'ui/table/row/noData/NoData';
import { Pagination } from 'ui/table/pagination/Pagination';
import * as styles from 'ui/table/Table.styles';

import { RolesTableRow } from './rolesTableRow/RolesTableRow';
import { RolesTableHeader } from './rolesTableHeader/RolesTableHeader';
import { RolesTableToolbar } from './rolesTableToolbar/RolesTableToolbar';

export const RolesTable = () => {
  const translate = useTranslator();

  const [page, setPage] = useState(0);
  const paginationOptions = [30, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchPhrase, setSearchPhrase] = useState('');

  const queryParams = removeEmptyFields({
    pageNumber: page,
    itemsPerPage: rowsPerPage,
    searchPhrase,
  });
  const { data, isError, isFetching, isLoading, isPreviousData } = useGetRoles(queryParams);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmitSearchPhrase = (searchPhrase: string) => {
    setSearchPhrase(searchPhrase);
    setPage(0);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <DataLoading data-testid="loader" />;
    }

    if (isError) {
      return <DataLoadingError />;
    }

    if (data) {
      return data.items.length > 0 ? (
        <>
          {data?.items.map((role: Role, index: number) => (
            <RolesTableRow key={role.id} role={role} index={(page + 1) * rowsPerPage - rowsPerPage + index} />
          ))}
        </>
      ) : (
        <NoData />
      );
    }
  };

  return (
    <Paper sx={styles.tableWrapper} data-testid="roles-table">
      {isFetching && isPreviousData && <CircularProgress sx={styles.loader} data-testid="loader" />}

      <RolesTableToolbar onSubmitSearchPhrase={handleSubmitSearchPhrase} />

      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table} stickyHeader aria-label={translate('roles.pageTitle')} size="small">
          <RolesTableHeader />
          <TableBody sx={styles.tableBody}>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>

      {data && (
        <Pagination
          count={data.totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={paginationOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
