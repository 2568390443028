import { Styles } from '@typings/common';

export const title: Styles = {
  mb: 2,
};

export const serviceProvidersList: Styles = {
  width: '20rem',
  maxWidth: '100%',
};
