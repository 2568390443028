import { useEffect, useMemo, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { toast } from 'react-toastify';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { permissions } from 'utils/permissions';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useFormatUserStatus } from 'hooks/format/useFormatUserStatus/useFormatUserStatus';
import { useGetStartupPassword } from 'hooks/password/useGetStartupPassword/useGetStartupPassword';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useResetPassword } from 'hooks/password/useResetPassword/useResetPassword';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { AuthorizedSection } from 'hoc/authorizedSection/AuthorizedSection';
import { Collapse } from 'ui/table/row/collapse/Collapse';
import { ConfirmDialog } from 'ui/confirmDialog/ConfirmDialog';
import { Permission, StatusName } from 'api/actions/users/usersActions.enum';
import { UserRolesTable } from 'ui/userRolesTable/UserRolesTable';
import { PasswordProvider } from 'api/actions/config/configActions.enum';

import { ResetPasswordButton } from './resetPasswordButton/ResetPasswordButton';
import { StartupPassword } from './startupPassword/StartupPassword';
import { UsersTableRowProps } from './UsersTableRow.types';
import { DeleteUserButton } from './deleteUserButton/DeleteUserButton';
import { EditUserButton } from './editUserButton/EditUserButton';
import * as styles from './UserTableRow.styles';

export const UsersTableRow = ({ index, user }: UsersTableRowProps) => {
  const translate = useTranslator();

  const formatUserStatus = useFormatUserStatus();
  const { formatDate, formatDateAndTime } = useFormatDate();

  const config = useConfigState();
  const isPasswordColumnVisible = config.passwordPolicy.isPasswordColumnVisible;
  const passwordProviderType = config.passwordProviderType;

  const { hasAccess } = usePermissions();
  const { user: authUser } = useAuthState();

  const { selectedRows, areSelectedAllOnAllPages, queryParams } = useUsersState();
  const { handleSelectRow } = useUsersMethods();

  const { areMultipleBindingKeys, getBindingKeyLabel } = useBindingKey();

  const [rowOpen, setRowOpen] = useState(false);
  const [showStartupPassword, setShowStartupPassword] = useState(false);
  const [showConfirmPasswordReset, setShowConfirmPasswordReset] = useState(false);

  const {
    id,
    lastName,
    firstName,
    login,
    bindingKey,
    email,
    lastLoginDate,
    modificationDate,
    status,
    withStartupPassword,
    roles,
    blacklisted,
  } = user;

  const isRowSelected = selectedRows.includes(id);
  const isCurrentlyLoggedUser = authUser?.id === id;
  const isUserBlacklisted = blacklisted && !hasAccess(permissions.ignoreRolesBlacklist);
  const isUserBlocked = status === StatusName.blocked;
  const hasRoles = roles.length > 0;
  const canResetPassword = user.canResetPassword;
  const isCheckboxVisible = !isUserBlacklisted && !isCurrentlyLoggedUser;
  const userName = sanitizeHtml(`${firstName} ${lastName}`, {
    allowedTags: [],
  });

  const {
    data: getStartupPasswordData,
    isError: isGetStartupPasswordError,
    isLoading: isGetStartupPasswordLoading,
    isRefetchError: isGetStartupPasswordRefetchError,
  } = useGetStartupPassword({ userId: id, queryEnabled: showStartupPassword });

  useEffect(() => {
    if (isGetStartupPasswordError && !isGetStartupPasswordRefetchError) {
      toast.error(translate('global.errorMessage'));
    }
  }, [isGetStartupPasswordError, isGetStartupPasswordRefetchError]);

  const mutationResetPassword = useResetPassword({
    userId: id,
    usersQueryParams: queryParams,
  });
  const { isLoading: isResetStartupPasswordLoading, mutate: mutateResetPassword } = mutationResetPassword;

  const handleToggleRow = () => {
    setRowOpen((prev) => !prev);
  };

  const handleToggleShowStartupPassword = async () => {
    setShowStartupPassword((prev) => !prev);
  };

  const handleClickResetPassword = () => {
    setShowConfirmPasswordReset(true);
  };

  const handleCancelPasswordReset = () => {
    setShowConfirmPasswordReset(false);
  };

  const showSuccessToast = () => {
    if (passwordProviderType === PasswordProvider.generator) {
      return toast.success(translate('users.resetPasswordSuccess.passwordProviderGenerator'));
    }

    if (passwordProviderType === PasswordProvider.email) {
      return toast.success(translate('users.resetPasswordSuccess.passwordProviderEmail', { email }));
    }
  };

  const handleConfirmPasswordReset = () => {
    mutateResetPassword(id, {
      onSuccess: () => {
        setShowConfirmPasswordReset(false);

        showSuccessToast();
      },
    });
  };

  const tableRowSelectedProp = useMemo(() => {
    if (isCurrentlyLoggedUser) {
      return false;
    }

    if (isRowSelected || areSelectedAllOnAllPages) {
      return true;
    }

    return false;
  }, [isCurrentlyLoggedUser, isRowSelected, areSelectedAllOnAllPages]);

  return (
    <>
      <TableRow sx={styles.row} hover selected={tableRowSelectedProp}>
        <TableCell sx={styles.collapseCell}>
          <IconButton aria-label={translate('global.expandRow')} size="small" onClick={handleToggleRow}>
            {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={styles.slimCell}>{index + 1}</TableCell>
        <TableCell sx={styles.slimCell}>
          {isCheckboxVisible && (
            <Checkbox
              size="small"
              onChange={() => handleSelectRow(id)}
              checked={isRowSelected || areSelectedAllOnAllPages}
              disabled={areSelectedAllOnAllPages}
              inputProps={{ 'aria-label': translate('users.select') }}
            />
          )}
        </TableCell>

        <TableCell>{lastName}</TableCell>
        <TableCell>{firstName}</TableCell>
        <TableCell>{login}</TableCell>
        {areMultipleBindingKeys && <TableCell>{getBindingKeyLabel(bindingKey?.key)}</TableCell>}
        <TableCell>{bindingKey?.value}</TableCell>
        <TableCell>{formatUserStatus(status)}</TableCell>
        <TableCell>{formatDate(modificationDate)}</TableCell>
        <TableCell>{!!lastLoginDate && formatDateAndTime(lastLoginDate, { showSeconds: true })}</TableCell>
        <AuthorizedSection allowedPermissions={permissions.passwordsManagement}>
          {isPasswordColumnVisible && (
            <TableCell>
              <StartupPassword
                getStartupPasswordData={getStartupPasswordData}
                isGetStartupPasswordLoading={isGetStartupPasswordLoading}
                onToggleShowStartupPassword={handleToggleShowStartupPassword}
                showStartupPassword={showStartupPassword}
                userHasStartupPassword={withStartupPassword}
              />
            </TableCell>
          )}
          <TableCell sx={styles.slimCell}>
            <ResetPasswordButton
              isCurrentlyLoggedUser={isCurrentlyLoggedUser}
              isUserBlacklisted={isUserBlacklisted}
              isUserBlocked={isUserBlocked}
              canResetPassword={canResetPassword}
              onClickResetPassword={handleClickResetPassword}
            />
          </TableCell>
        </AuthorizedSection>
        <AuthorizedSection allowedPermissions={permissions.usersManage}>
          <TableCell sx={styles.slimCell}>
            <EditUserButton id={id} isUserBlacklisted={isUserBlacklisted} />
          </TableCell>
          <AuthorizedSection allowedPermissions={[Permission.superAdministrator, Permission.userManagement]}>
            <TableCell sx={styles.slimCell}>
              <DeleteUserButton id={id} isCurrentlyLoggedUser={isCurrentlyLoggedUser} hasRoles={hasRoles} />
            </TableCell>
          </AuthorizedSection>
        </AuthorizedSection>
      </TableRow>
      <TableRow selected={isRowSelected}>
        <TableCell sx={styles.nestedCell} colSpan={3} />
        <TableCell sx={styles.nestedCell} colSpan={12}>
          <Collapse in={rowOpen}>
            <UserRolesTable
              roles={roles}
              withInstitutionName={true}
              sx={styles.nestedTable}
              size="small"
              aria-label={translate('users.tableInner')}
            />
          </Collapse>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        id="resetPassword"
        title={translate('users.confirmResetPasswordTitle')}
        description={translate('users.confirmResetPasswordDescription', { userName })}
        show={showConfirmPasswordReset}
        onCancel={handleCancelPasswordReset}
        onConfirm={handleConfirmPasswordReset}
        isProcessing={isResetStartupPasswordLoading}
      />
    </>
  );
};
