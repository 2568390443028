import { Styles } from '@typings/common';

export const description: Styles = {
  '& p': {
    my: 0,
  },
  '& li': {
    mt: 0,
    mb: 0.5,
    whiteSpace: 'pre-wrap',
  },
  '& code': {
    bgcolor: '#e1e1e1',
    py: 0.25,
    px: 0.5,
    fontWeight: 700,
  },
};

export const importFileTemplateLink: Styles = {
  display: 'inline-block',
  mb: 3,
};

export const inputFileWrapper: Styles = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
};

export const inputFileLabel: Styles = {
  mt: 1,
};

export const customInputFile: Styles = {
  mb: 2,
  mx: 1,

  '& input': {
    cursor: 'pointer',
  },
};

export const loaderInputFileContainer: Styles = {
  ml: 2,
  width: '4rem',
  height: '4rem',
};

export const serverErrors: Styles = {
  mb: 2,
  overflow: 'hidden',
};

export const errorBox: Styles = {
  mb: 2,
};

export { buttonBox } from '../../UsersImport.styles';
