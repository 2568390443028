export enum SearchFormFields {
  searchPhrase = 'searchPhrase',
}

export type SearchFormData = {
  [SearchFormFields.searchPhrase]: string;
};

export type SearchBarProps = {
  onSubmit(data: SearchFormData): void;
  textFieldPlaceholder: string;
};
