import { Params } from 'api/actions/actions.types';
import { FiltersPayload } from 'api/actions/users/usersActions.types';

export type UsersEndpointFilterSearchParams = FiltersPayload & {
  searchPhrase?: string;
  allUsers?: boolean;
};

export const toUsersEndpointFilterSearchParams = (
  params?: UsersEndpointFilterSearchParams,
): Params<UsersEndpointFilterSearchParams> => ({
  search_phrase: params?.searchPhrase,
  institution: params?.institution?.length ? params?.institution.join(',') : undefined,
  role: params?.role?.length ? params?.role.join(',') : undefined,
  status: params?.status?.length ? params?.status.join(',') : undefined,
  last_login_timestamp_from: params?.lastLoginTimestampFrom,
  last_login_timestamp_to: params?.lastLoginTimestampTo,
  never_logged: params?.neverLogged,
  all_users: params?.allUsers ? true : undefined,
});
