import { useState } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { getUnixTime } from 'date-fns';

import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useFormatProjectName } from 'hooks/format/useFormatProjectName/useFormatProjectName';
import { useGetLoginLogs } from 'hooks/useGetLoginLogs/useGetLoginLogs';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { DataLoading } from 'ui/table/row/dataLoading/DataLoading';
import { DataLoadingError } from 'ui/table/row/dataLoadingError/DataLoadingError';
import { Filters, LoginLog } from 'api/actions/loginLogs/loginLogs.types';
import { FilterField, FiltersPayload } from 'api/actions/loginLogs/loginLogs.enum';
import { NoData } from 'ui/table/row/noData/NoData';
import { Pagination } from 'ui/table/pagination/Pagination';
import * as styles from 'ui/table/Table.styles';

import { LoginLogsTableHeader } from './loginLogsTableHeader/LoginLogsTableHeader';
import { LoginLogsTableRow } from './loginLogsTableRow/LoginLogsTableRow';
import { LoginLogsTableToolbar } from './loginLogsTableToolbar/LoginLogsTableToolbar';

export const LoginLogsTable = () => {
  const translate = useTranslator();

  const { formatProjectNameToUmsName } = useFormatProjectName();

  const [page, setPage] = useState(0);
  const paginationOptions = [30, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const defaultFilters: Filters = {
    [FilterField.createdAtDateTimeFrom]: null,
    [FilterField.createdAtDateTimeTo]: null,
    [FilterField.login]: null,
    [FilterField.ipAddress]: null,
    [FilterField.origin]: null,
    [FilterField.destination]: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const formatFilters = (filters: Filters): FiltersPayload => ({
    login: filters.login,
    createdAtTimestampFrom: !!filters.createdAtDateTimeFrom
      ? getUnixTime(new Date(filters.createdAtDateTimeFrom))
      : null,
    createdAtTimestampTo: !!filters.createdAtDateTimeTo ? getUnixTime(new Date(filters.createdAtDateTimeTo)) : null,
    ipAddress: filters.ipAddress,
    origin: filters.origin,
    destination: formatProjectNameToUmsName(filters.destination),
  });

  const queryParams = removeEmptyFields({
    pageNumber: page,
    itemsPerPage: rowsPerPage,
    filters: formatFilters(filters),
  });
  const { data, isError, isFetching, isLoading, isPreviousData } = useGetLoginLogs(queryParams);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmitFilters = (filters: Filters) => {
    setFilters(filters);
    setPage(0);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <DataLoading data-testid="loader" />;
    }

    if (isError) {
      return <DataLoadingError />;
    }

    if (data) {
      return data.items.length > 0 ? (
        <>
          {data?.items.map((loginLog: LoginLog, i) => (
            <LoginLogsTableRow key={i} loginLog={loginLog} />
          ))}
        </>
      ) : (
        <NoData />
      );
    }
  };

  return (
    <Paper sx={styles.tableWrapper} data-testid="login-logs-table">
      {isFetching && isPreviousData && <CircularProgress sx={styles.loader} data-testid="loader" />}

      <LoginLogsTableToolbar filters={filters} onPageChange={handleChangePage} onSubmitFilters={handleSubmitFilters} />

      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table} stickyHeader aria-label={translate('loginLogs.pageTitle')} size="small">
          <LoginLogsTableHeader />
          <TableBody sx={styles.tableBody}>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>

      {data && (
        <Pagination
          count={data.totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={paginationOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
