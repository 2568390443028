import { useEffect, useMemo, useState } from 'react';
import { Step, StepLabel, Stepper, StepContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { errorMessages } from 'api/errors/messages';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useCheckCanEditUser } from 'hooks/users/useCheckCanEditUser/useCheckCanEditUser';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { StatusName } from 'api/actions/users/usersActions.enum';
import { CreateUserPayload, UserRolePayload } from 'api/actions/users/usersActions.types';
import { UsersFormPersonalData } from 'app/users/usersForm/usersFormPersonalData/UsersFormPersonalData';
import { UsersFormRoles } from 'app/users/usersForm/usersFormRoles/UsersFormRoles';
import { UsersFormSummary } from 'app/users/usersForm/usersFormSummary/UsersFormSummary';

import { UsersFormData, UsersFormProps } from './UsersForm.types';
import { useUsersFormDataSchema } from './UsersFormDataSchema';
import * as styles from './UsersForm.styles';

export const UsersForm = ({
  user,
  isEditForm,
  isUpdateError,
  isUpdateLoading,
  updateError,
  onSubmitFormData,
  isCurrentlyLoggedUser,
}: UsersFormProps) => {
  const translate = useTranslator();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [activeStep, setActiveStep] = useState(0);
  const steps: string[] = [
    translate('usersForm.step1Title'),
    translate('usersForm.step2Title'),
    translate('usersForm.step3Title'),
  ];

  const usersFormDataSchema = useUsersFormDataSchema();

  const { bindingKeys, areMultipleBindingKeys } = useBindingKey();

  const currentValidationSchema = usersFormDataSchema[activeStep];

  const defaultValues = useMemo(() => {
    if (isEditForm && user) {
      return {
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        bindingKey: {
          key: user.bindingKey.key,
          value: user.bindingKey.value,
        },
        login: user.login,
        status: user.status,
        roles: user.roles,
      };
    }

    return {
      lastName: '',
      firstName: '',
      email: '',
      bindingKey: {
        key: areMultipleBindingKeys ? '' : bindingKeys?.[0].key,
        value: '',
      },
      login: '',
      status: StatusName.active,
      roles: [],
    };
  }, [areMultipleBindingKeys, bindingKeys, isEditForm, user]);

  const methods = useForm<UsersFormData>({
    defaultValues: defaultValues,
    resolver: yupResolver(currentValidationSchema),
    mode: 'onChange',
  });

  const {
    formState: { isDirty },
    reset,
    trigger,
    getValues,
  } = methods;

  useConfirmFormAbort(isDirty);

  const getFormattedFormData = (): CreateUserPayload => {
    const { roles, email, ...otherPayload } = getValues();

    const payloadRoles = roles.map(
      ({ role, institution }): UserRolePayload => ({
        roleId: role.id,
        institutionId: institution?.id || null,
      }),
    );

    const data = {
      ...otherPayload,
      email: email === '' ? undefined : email,
      login: undefined,
      roles: payloadRoles,
    };

    delete data.login;

    return data;
  };

  const mutationCheckCanEditUser = useCheckCanEditUser();

  const changeActiveStepToNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const checkCanEditUser = () => {
    if (user?.id) {
      mutationCheckCanEditUser.mutate(
        { id: user.id, values: getFormattedFormData() },
        {
          onSettled: () => {
            changeActiveStepToNext();
          },
        },
      );
    }
  };

  const handleClickNext = async () => {
    const isStepValid = await trigger();

    if (isStepValid) {
      if (isEditForm && activeStep === 1) {
        return checkCanEditUser();
      }

      return changeActiveStepToNext();
    }
  };

  const handleClickBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmitFormData = () => {
    const data = getFormattedFormData();

    onSubmitFormData(data);
  };

  const checkErrorsPresent = (serverErrors: string[], listenedErrors: string[]): boolean =>
    listenedErrors.some((listenedError) => serverErrors.some((serverError) => serverError.includes(listenedError)));

  useEffect(() => {
    if (isUpdateError) {
      const serverErrors: string[] | Record<string, string[]> = updateError?.response?.data?.errors || [];
      const listenedErrors = [errorMessages.user.bindingKeyNotUnique, errorMessages.user.bindingKeyInavlidSyntax];

      reset(getValues(), {
        keepValues: true,
        keepTouched: false,
        keepDirty: true,
      });

      if (Array.isArray(serverErrors) && checkErrorsPresent(serverErrors, listenedErrors)) {
        setActiveStep(0);
      }
    }
  }, [isUpdateError]);

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <UsersFormPersonalData
            isEditForm={isEditForm}
            isUpdateError={isUpdateError}
            updateError={updateError}
            onClickNext={handleClickNext}
            isCurrentlyLoggedUser={isCurrentlyLoggedUser}
          />
        );
      case 1:
        return (
          <UsersFormRoles
            isEditForm={isEditForm}
            isNextStepLoading={mutationCheckCanEditUser.isLoading}
            onClickBack={handleClickBack}
            onClickNext={handleClickNext}
          />
        );
      case 2:
        return (
          <UsersFormSummary
            isEditForm={isEditForm}
            isUpdateLoading={isUpdateLoading}
            updateError={updateError}
            onClickBack={handleClickBack}
            onSubmitFormData={handleSubmitFormData}
            checkCanEditUserError={mutationCheckCanEditUser.error}
          />
        );
    }
  };

  return (
    <FormProvider {...methods}>
      <Stepper activeStep={activeStep} sx={styles.stepper} orientation={matches ? 'horizontal' : 'vertical'}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>

            {!matches && <StepContent>{getStepContent(activeStep)}</StepContent>}
          </Step>
        ))}
      </Stepper>
      {matches && getStepContent(activeStep)}
    </FormProvider>
  );
};
