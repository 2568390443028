import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { logoutAction } from 'api/actions/auth/authActions';

export const useLogout = () => {
  const mutationLogout = useMutation<void, AxiosError, void>(logoutAction);

  return mutationLogout;
};
