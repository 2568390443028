import { Box, Tooltip } from '@mui/material';

import { DisabledOptionWithTooltipProps } from './DisabledOptionWithTooltip.types';
import * as styles from './DisabledOptionWithTooltip.styles';

export const DisabledOptionWithTooltip = ({ children, tooltipTitle }: DisabledOptionWithTooltipProps) => {
  return (
    <Tooltip title={tooltipTitle} disableInteractive placement="bottom-start" arrow>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        sx={styles.tooltipContainer}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
