import get from 'lodash.get';

import { endpoints } from 'api/endpoints/endpoints';
import { getAppConfig } from 'utils/config';

export const getDownloadLink = (endpointKey: DotNestedKeys<typeof endpoints>): string => {
  const endpoint = get(endpoints, endpointKey);

  return `${getAppConfig('API_URL')}${endpoint}`;
};
