import { TableCell, TableRow } from '@mui/material';

import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useFormatEvent } from 'hooks/format/useFormatEvent/useFormatEvent';

import { EventLogsTableRowProps } from './EventLogsTableRow.types';
import { EventObjectCell } from './eventObjectCell/EventObjectCell';

export const EventLogsTableRow = ({ eventLog }: EventLogsTableRowProps) => {
  const { formatMicroTimestampToDateAndTime } = useFormatDate();
  const { formatEventSource, formatEventType, formatProcessType } = useFormatEvent();

  const {
    id,
    login,
    applicationName,
    userId,
    eventMicroTimestamp,
    eventType,
    processId,
    processType,
    eventSource,
    context,
    ipAddress,
  } = eventLog;

  return (
    <TableRow hover>
      <TableCell>{id}</TableCell>
      <TableCell>{login}</TableCell>
      <TableCell>{applicationName}</TableCell>
      <TableCell>{userId}</TableCell>
      <TableCell>{formatMicroTimestampToDateAndTime(eventMicroTimestamp, { showSeconds: true })}</TableCell>
      <TableCell>{formatEventType(eventType)}</TableCell>
      <TableCell>{formatProcessType(processType)}</TableCell>
      <TableCell>{processId}</TableCell>
      <EventObjectCell context={context} eventType={eventType} />
      <TableCell>{formatEventSource(eventSource)}</TableCell>
      <TableCell>{ipAddress}</TableCell>
    </TableRow>
  );
};
