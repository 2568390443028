import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { initImport } from 'api/actions/imports/importsActions';
import { ImportActionPayload } from 'api/actions/imports/impportActions.types';

export const useInitImport = <T extends ImportActionPayload>() => {
  const mutationInitImport = useMutation<void, AxiosError, T>(initImport);

  return mutationInitImport;
};
