import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs, BreadcrumbsProps, Link, Typography } from '@mui/material';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { routes } from 'routing/AppRoutes';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const translate = useTranslator();

  const breadcrumbs = useBreadcrumbs(routes);
  const hasNestedBreadcrumbs = breadcrumbs.length > 1;

  if (hasNestedBreadcrumbs) {
    return (
      <MUIBreadcrumbs aria-label={translate('global.breadcrumbs')} {...props}>
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          const last = index === breadcrumbs.length - 1;

          return last ? (
            <Typography variant="body2" color="textPrimary" key={match.url}>
              {breadcrumb}
            </Typography>
          ) : (
            <Link
              variant="body2"
              color="inherit"
              underline="hover"
              to={match.url}
              key={match.url}
              component={RouterLink}
            >
              {breadcrumb}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    );
  }

  return null;
};
