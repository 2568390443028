import { Box, Button, Paper, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { PasswordActionMessageProps } from './PasswordActionMessage.types';
import * as styles from './PasswordActionMessage.styles';

export const PasswordActionMessage = ({
  title,
  description,
  icon: Icon,
  iconColor,
  buttonText,
  buttonLink,
}: PasswordActionMessageProps) => (
  <Paper sx={styles.wrapper}>
    <Box>
      <Icon color={iconColor} sx={styles.icon} />
    </Box>
    <Box sx={styles.textBox}>
      {title && (
        <Typography variant="h5" component="h2" color="primary" sx={styles.title}>
          {title}
        </Typography>
      )}

      <Typography sx={styles.description}>{description}</Typography>

      {buttonText && buttonLink && (
        <Button component={RouterLink} to={buttonLink} color="primary" variant="contained" sx={styles.button}>
          {buttonText}
        </Button>
      )}
    </Box>
  </Paper>
);
