import { Styles } from '@typings/common';

export const textField: Styles = {
  mb: 2,

  '& .MuiOutlinedInput-input.Mui-disabled': {
    color: 'text.primary',
    WebkitTextFillColor: 'inherit',
  },
};
