import { Styles } from '@typings/common';

export const wrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: { md: '100vh' },
};

export const container: Styles = {
  flexGrow: 1,
  display: 'flex',
  overflow: 'hidden',
};

export const content: Styles = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
};

export const main: Styles = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  p: 2,
};

export const breadcrumbs: Styles = {
  mb: 1,
};
