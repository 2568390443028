import { Alert, Box } from '@mui/material';
import camelCase from 'lodash.camelcase';

import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { errorMessages } from 'api/errors/messages';
import { lowerCaseFirstLetter } from 'utils/function/lowerCaseFirstLetter/lowerCaseFirstLetter';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { FormServerErrorsProps } from './FormServerErrors.types';

export const WithPermissions = ({ serverErrors, ...props }: FormServerErrorsProps) => {
  const translate = useTranslator();

  const { getBindingKeyLabel } = useBindingKey();

  const handleServerErrorWithField = (serverError: string) => {
    const [error, rawFieldName] = serverError.split('@');

    const fieldName = `global.${camelCase(rawFieldName)}`;
    const fieldNameTranslation = (() => {
      if (error.includes(errorMessages.user.bindingKeyInavlidSyntax)) {
        return getBindingKeyLabel(rawFieldName);
      }

      if (isTranslationAvailable(fieldName)) {
        return translate(fieldName);
      }

      return rawFieldName;
    })();

    return isTranslationAvailable(error) ? translate(error, { fieldName: fieldNameTranslation }) : serverError;
  };

  const getErrorMessage = (error: string) => {
    return isTranslationAvailable(error) ? translate(error) : error;
  };

  const mapErrorArray = (serverErrors: string[]) => (
    <Alert severity="error" {...props}>
      {serverErrors.map((serverError: string) => {
        if (serverError.includes('@')) {
          return handleServerErrorWithField(serverError);
        }

        return <Box>{getErrorMessage(serverError)}</Box>;
      })}
    </Alert>
  );

  const mapErrorObject = (serverErrors: Record<string, string>) => {
    const serverErrorsKeys = Object.keys(serverErrors);

    return (
      <Alert severity="error" {...props}>
        {serverErrorsKeys.map((serverErrorKey) => {
          const serverError = serverErrors[serverErrorKey];

          const fieldName = `global.${camelCase(serverErrorKey)}`;
          const fieldNameTranslation = isTranslationAvailable(fieldName) ? translate(fieldName) : serverErrorKey;

          return (
            <Box>
              {fieldNameTranslation}: {lowerCaseFirstLetter(getErrorMessage(serverError))}
            </Box>
          );
        })}
      </Alert>
    );
  };

  if (Array.isArray(serverErrors)) {
    return mapErrorArray(serverErrors);
  } else if (typeof serverErrors === 'object') {
    return mapErrorObject(serverErrors);
  }

  return null;
};
