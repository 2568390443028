import { AppBar, Grid, Toolbar } from '@mui/material';
import parseHtml from 'html-react-parser';

import { getAppConfig } from 'utils/config';

import * as styles from './Footer.styles';

export const Footer = () => {
  const isFooterContent = !!getAppConfig('FOOTER_CONTENT');

  if (isFooterContent) {
    return (
      <AppBar sx={styles.appBar} component="footer" position="static">
        <Toolbar sx={styles.toolbar}>
          <Grid container>{parseHtml(getAppConfig('FOOTER_CONTENT'))}</Grid>
        </Toolbar>
      </AppBar>
    );
  }

  return null;
};
