import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { remindPassword } from 'api/actions/password/passwordActions';
import { RemindPasswordPayload } from 'api/actions/password/passwordActions.types';

export const useRemindPassword = () => {
  const mutationRemindPassword = useMutation<void, AxiosError, RemindPasswordPayload>(remindPassword);

  return mutationRemindPassword;
};
