import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getRoles } from 'api/actions/roles/rolesActions';
import { RolesPayload, RolesResponse } from 'api/actions/roles/rolesActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetRoles = (params: RolesPayload) => {
  const queryGetRoles = useQuery<RolesResponse, AxiosError>([Queries.getRoles, params], () => getRoles(params), {
    keepPreviousData: true,
  });

  return queryGetRoles;
};
