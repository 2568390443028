import { Alert, Box } from '@mui/material';
import camelcase from 'lodash.camelcase';

import { errorMessages } from 'api/errors/messages';
import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppMessages } from 'i18n/messages';

import { ServerErrorsListProps } from './ServerErrorsList.types';

export const ServerErrorsList = ({ errors, ...props }: ServerErrorsListProps) => {
  const translate = useTranslator();

  const uploadImportErrors = errorMessages.import as Record<string, keyof typeof AppMessages>;

  // e.g. CONSTRAINT.VALIDATION.NO_OF_COLUMNS_DOES_NOT_MATCH:1,2
  // e.g. CONSTRAINT.VALIDATION.IMPORT_FILE_HAS_UNSUPPORTED_DELIMITER:1,2
  // e.g. CONSTRAINT.VALIDATION.IMPORT_FILE_EMPTY_LINE:1,2
  const linesErrors: Array<keyof typeof AppMessages> = [
    uploadImportErrors.rowLengthDoesNotMatchRequiredLength,
    uploadImportErrors.rowUnsupportedDelimiter,
    uploadImportErrors.emptyLine,
  ];

  const renderErrorMessage = (error: string) => {
    const lineError = linesErrors.filter((lineError) => error.includes(lineError))[0];

    if (lineError) {
      const lines = error.replace(`${lineError}:`, '');

      return translate(lineError, { lines });
    }

    if (error.includes(uploadImportErrors.unknownHeader)) {
      // e.g. CONSTRAINT.VALIDATION.UNKNOWN_HEADER:SOME_HEADER
      const fieldName = error.replace(`${uploadImportErrors.unknownHeader}:`, '');

      return translate(uploadImportErrors.unknownHeader, { fieldName });
    }

    return isTranslationAvailable(error) ? translate(error) : error;
  };

  if (Array.isArray(errors)) {
    return (
      <Alert severity="error" {...props}>
        {errors.map((error: string) => {
          return <Box key={error}>{renderErrorMessage(error)}</Box>;
        })}
      </Alert>
    );
  }

  return null;
};
