import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';

import {
  ChangePasswordPayload,
  PasswordSyntaxResponse,
  RemindPasswordPayload,
  SetNewPasswordPayload,
  StartupPasswordResponse,
  VerifyPasswordLinkPayload,
} from './passwordActions.types';

export const getUserStartupPassword = async (id: string): Promise<StartupPasswordResponse> => {
  const response = await umsApi.get(endpoints.passwords.startupPassword(id));

  return response.data;
};

export const resetStartupPassword = async (id: string): Promise<void> => {
  await umsApi.put(endpoints.passwords.startupPassword(id));
};

export const resetPassword = async (id: string): Promise<void> => {
  await umsApi.post(endpoints.passwords.reset(id));
};

export const changePassword = async (values: ChangePasswordPayload): Promise<void> => {
  await umsApi.put(endpoints.passwords.change, values);
};

export const remindPassword = async (values: RemindPasswordPayload): Promise<void> => {
  await umsApi.post(endpoints.passwords.remind, values);
};

export const verifyPasswordLink = async (values: VerifyPasswordLinkPayload): Promise<void> => {
  await umsApi.post(endpoints.passwords.verifyLink, values);
};

export const setNewPassword = async (values: SetNewPasswordPayload): Promise<void> => {
  await umsApi.put(endpoints.passwords.setNew, values);
};

export const getPasswordSyntax = async (): Promise<PasswordSyntaxResponse> => {
  const response = await umsApi.get(endpoints.passwords.getSyntax);

  return response.data;
};
