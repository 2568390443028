import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useChangePassword } from 'hooks/password/useChangePassword/useChangePassword';
import { useSsoLogin } from 'hooks/auth/useSsoLogin/useSsoLogin';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AuthModeName } from 'context/auth/authContext/AuthContext.enum';
import { AuthorizeResponse } from 'api/actions/sso/ssoActions.types';
import { ChangePasswordPayload } from 'api/actions/password/passwordActions.types';
import { Queries } from 'api/queries/queries.enum';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useGetPasswordSyntax } from 'hooks/password/useGetPasswordSyntax/useGetPasswordSyntax';
import { Loader } from 'ui/loader/Loader';
import { useGetConfig } from 'hooks/useGetConfig/useGetConfig';

import { PasswordChangeForm } from './PasswordChangeForm';
import { PasswordChangeFormContainerProps, PasswordChangeFormRef } from './PasswordChangeFormContainer.types';
import * as styles from './PasswordChangeForm.styles';

export const PasswordChangeFormContainer = ({
  disableBeacounLogoutAction,
  redirectOnCancel,
  redirectOnSuccess,
}: PasswordChangeFormContainerProps) => {
  const translate = useTranslator();

  const queryClient = useQueryClient();

  const { user, authMode } = useAuthState();

  const queryGetConfig = useGetConfig();

  const queryGetPasswordSyntax = useGetPasswordSyntax();

  const mutationChangePassword = useChangePassword();

  const passwordChangeFormRef = useRef<PasswordChangeFormRef>();

  const mutationSsoLogin = useSsoLogin();

  const [loginResponse, setLoginResponse] = useState<AuthorizeResponse>();

  const confirmFormAbort = useConfirmFormAbort();

  useEffect(() => {
    if (loginResponse) {
      document.forms[1].submit();
    }
  }, [loginResponse]);

  const onSubmit = useCallback(
    async (body: ChangePasswordPayload): Promise<boolean> => {
      try {
        await mutationChangePassword.mutateAsync(body);

        confirmFormAbort.disable();

        toast.success(translate('passwordChangeForm.success'));

        if (authMode?.name === AuthModeName.default) {
          await queryClient.refetchQueries(Queries.getUserMe);
          passwordChangeFormRef.current?.handleResetForm();
        }

        if (user && authMode?.name === AuthModeName.sso && authMode.params) {
          const payload = {
            login: user.login,
            password: body.newPasswordRepeated,
            wtrealm: authMode.params.wtrealm,
            wctx: authMode.params.wctx,
            wreply: authMode.params.wreply,
          };

          const mutationSsoLoginResponse = await mutationSsoLogin.mutateAsync(payload);

          if (mutationSsoLoginResponse) {
            disableBeacounLogoutAction?.();

            setLoginResponse(mutationSsoLoginResponse);
          }
        }
      } catch {
        return false;
      }

      return true;
    },
    [mutationChangePassword.mutateAsync, mutationSsoLogin.mutateAsync],
  );

  const passwordSyntax = useMemo(() => {
    if (queryGetPasswordSyntax.data) {
      return queryGetPasswordSyntax.data;
    }

    if (queryGetConfig.data) {
      return queryGetConfig.data.passwordPolicy.passwordSyntax;
    }
  }, [queryGetPasswordSyntax.data, queryGetConfig.data]);

  const numberOfStoredPasswords = queryGetConfig.data?.passwordPolicy.numberOfStoredPasswords ?? 0;

  if (mutationChangePassword.isSuccess && redirectOnSuccess && authMode?.name === AuthModeName.default) {
    <Redirect to={redirectOnSuccess.to} />;
  }

  if (queryGetPasswordSyntax.isLoading) {
    return <Loader sx={styles.loader} />;
  }

  if (passwordSyntax) {
    return (
      <>
        <PasswordChangeForm
          disabled={!!loginResponse || mutationSsoLogin.isLoading}
          error={mutationChangePassword.error}
          isError={mutationChangePassword.isError}
          onSubmit={onSubmit}
          ref={passwordChangeFormRef}
          redirectOnCancel={redirectOnCancel && { to: redirectOnCancel.to }}
          passwordSyntax={passwordSyntax}
          numberOfStoredPasswords={numberOfStoredPasswords}
        />
        {loginResponse && <div className="visuallyHidden" dangerouslySetInnerHTML={{ __html: loginResponse }}></div>}
      </>
    );
  }

  return null;
};
