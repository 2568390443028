import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getPasswordSyntax } from 'api/actions/password/passwordActions';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { AppRoute } from 'routing/AppRoute.enum';
import { PasswordSyntaxResponse } from 'api/actions/password/passwordActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetPasswordSyntax = () => {
  const { isAuthorized } = useAuthState();

  const { hasPermissions } = usePermissions();

  const location = useLocation();
  const isLocationPasswordNew = location.pathname === AppRoute.passwordNew;

  const enabled = (isAuthorized && !hasPermissions) || isLocationPasswordNew;

  const queryGetPasswordSyntax = useQuery<PasswordSyntaxResponse, AxiosError>(
    Queries.getPasswordSyntax,
    getPasswordSyntax,
    { keepPreviousData: true, enabled },
  );

  return queryGetPasswordSyntax;
};
