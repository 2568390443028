import { Role } from 'api/actions/roles/rolesActions.types';
import { RoleUserResponse, User } from 'api/actions/users/usersActions.types';
import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { permissions } from 'utils/permissions';

export enum Restriction {
  nonAssignable = 'nonAssignable',
  blacklisted = 'blacklisted',
}

export const useCheckRestrictedRoles = () => {
  const config = useConfigState();
  const { hasAccess } = usePermissions();

  const nonAssignableRoles = config.nonAssignableRoles;
  const nonAssignableRolesKeys = nonAssignableRoles.map((role) => role.key);

  const blacklistedRoles = config.blacklistedRoles;
  const blacklistedRolesKeys = blacklistedRoles.map((role) => role.key);

  const checkIsRestrictedRole = (
    role: Role | RoleUserResponse,
    options?: { restrictionType: Restriction },
  ): boolean => {
    const areNonAssignableRoles = nonAssignableRoles?.length;
    const areBlackListedRoles = blacklistedRoles?.length;

    if (!areNonAssignableRoles && !areBlackListedRoles) return false;

    if (options?.restrictionType === Restriction.nonAssignable) {
      return !!areNonAssignableRoles && nonAssignableRolesKeys.includes(role.key);
    }

    if (options?.restrictionType === Restriction.blacklisted) {
      return (
        !!areBlackListedRoles && !hasAccess(permissions.ignoreRolesBlacklist) && blacklistedRolesKeys.includes(role.key)
      );
    }

    return (
      nonAssignableRolesKeys.includes(role.key) ||
      (!hasAccess(permissions.ignoreRolesBlacklist) && blacklistedRolesKeys.includes(role.key))
    );
  };

  const checkUserHasRestrictedRoles = (user: User, options?: { restrictionType: Restriction }): boolean => {
    const userRoles = user?.roles;

    if (!userRoles?.length) return false;

    if (options) {
      return userRoles.some((userRole) =>
        checkIsRestrictedRole(userRole.role, { restrictionType: options.restrictionType }),
      );
    }

    return userRoles.some((userRole) => checkIsRestrictedRole(userRole.role));
  };

  return { checkIsRestrictedRole, checkUserHasRestrictedRoles };
};
