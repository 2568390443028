import { useEffect, useState } from 'react';

import { formatUsersFilterValues } from 'utils/function/formatUsersFilterValues/formatUsersFilterValues';
import { permissions } from 'utils/permissions';
import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useGetUsers } from 'hooks/users/useGetUsers/useGetUsers';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { FilterField, StatusName } from 'api/actions/users/usersActions.enum';
import { Filters, Status, User } from 'api/actions/users/usersActions.types';
import { OrderDirection } from '@typings/common';
import { UsersMethodsContext, UsersStateContext } from '../usersContext/UsersContext';
import { UsersMethodsContextType, UsersStateContextValueType } from '../usersContext/UsersContext.types';

import { UsersContextControllerProps } from './UsersContextController.types';

export const paginationOptions = [30, 50, 100];

export const statusOptions: Status[] = [
  { id: StatusName.active, name: StatusName.active },
  { id: StatusName.blocked, name: StatusName.blocked },
];

export const UsersContextController = ({ children }: UsersContextControllerProps) => {
  const { user: authUser } = useAuthState();

  const { hasAccess } = usePermissions();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationOptions[0]);

  const [orderBy, setOrderBy] = useState<keyof User>();
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.asc);

  const [searchPhrase, setSearchPhrase] = useState('');

  const getRoleFromStorage = () => {
    const role = localStorage.getItem('role');

    try {
      const parsedRole = role && JSON.parse(role);
      return parsedRole ? [parsedRole] : [];
    } catch (error) {
      return [];
    }
  };

  const defaultRoles = getRoleFromStorage();

  useEffect(() => {
    return () => {
      localStorage.removeItem('role');
    };
  }, []);

  const defaultFilters: Filters = {
    [FilterField.role]: defaultRoles,
    [FilterField.institution]: [],
    [FilterField.status]: [],
    [FilterField.lastLoginDateFrom]: null,
    [FilterField.lastLoginDateTo]: null,
    [FilterField.neverLogged]: false,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const queryParams = removeEmptyFields({
    filters: formatUsersFilterValues(filters),
    orderBy,
    ...(orderBy && { orderDirection }),
    pageNumber: page,
    itemsPerPage: rowsPerPage,
    searchPhrase,
  });

  const { data } = useGetUsers(queryParams);
  const dataItemsAllowedToSelect = data?.items.filter((user) => {
    if (user.id === authUser?.id) {
      return false;
    }

    if (user.blacklisted && !hasAccess(permissions.ignoreRolesBlacklist)) {
      return false;
    }

    return true;
  });

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const numOfSelectedRows = selectedRows.length;
  const isSelectAllChecked =
    numOfSelectedRows > 0 && !!dataItemsAllowedToSelect?.every(({ id }) => selectedRows.includes(id));
  const isIndeterminateChecked =
    !!dataItemsAllowedToSelect?.some(({ id }) => selectedRows.includes(id)) && !isSelectAllChecked;

  const [areSelectedAllOnAllPages, setAreSelectedAllOnAllPages] = useState(false);
  const [isSelectAllOnAllPagesNotificationVisible, setIsSelectAllOnAllPagesNotificationVisible] = useState(false);

  const shouldShowSelectAllOnAllPagesNotification = (
    numOfSelectedRows: number,
    isSelectAllChecked: boolean,
    areSelectedAllOnAllPages: boolean,
  ) => {
    if (numOfSelectedRows === 1) {
      return false;
    }

    if (isSelectAllChecked) {
      return true;
    }

    if (areSelectedAllOnAllPages) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (shouldShowSelectAllOnAllPagesNotification(numOfSelectedRows, isSelectAllChecked, areSelectedAllOnAllPages)) {
      setIsSelectAllOnAllPagesNotificationVisible(true);
    } else {
      setIsSelectAllOnAllPagesNotificationVisible(false);
    }
  }, [numOfSelectedRows, isSelectAllChecked, areSelectedAllOnAllPages]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof User) => {
    const isAsc = orderBy === property && orderDirection === OrderDirection.asc;
    setOrderDirection(isAsc ? OrderDirection.desc : OrderDirection.asc);
    setOrderBy(property);
    setPage(0);
    setSelectedRows([]);
  };

  const handleSelectRow = (id: string) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedRows.slice(0, selectedIndex), selectedRows.slice(selectedIndex + 1));
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (numOfSelectedRows > 1) {
        setIsSelectAllOnAllPagesNotificationVisible(true);
      }

      if (!!data) {
        const newSelecteds = dataItemsAllowedToSelect?.map((user) => user.id) || [];
        setSelectedRows((prev) => Array.from(new Set([...prev, ...newSelecteds])));
        return;
      }
    }

    if (!!data) {
      const newSelecteds = dataItemsAllowedToSelect?.map((user) => user.id) || [];
      setSelectedRows((prev) => prev.filter((id) => !newSelecteds.includes(id)));
    }
  };

  const handleToggleAreSelectedAllOnAllPages = (value: boolean) => {
    setAreSelectedAllOnAllPages(value);

    if (!value && areSelectedAllOnAllPages) {
      setSelectedRows([]);
    }

    if (!value) {
      setIsSelectAllOnAllPagesNotificationVisible(false);
    }
  };

  const handleResetSelectedRows = () => {
    setSelectedRows([]);
  };

  const handleSubmitFilters = (filters: Filters) => {
    setFilters(filters);
    setPage(0);
    setSelectedRows([]);
  };

  const handleSubmitSearchPhrase = (searchPhrase: string) => {
    setSearchPhrase(searchPhrase);
    setPage(0);
    setSelectedRows([]);
  };

  const state: UsersStateContextValueType = {
    page,
    paginationOptions,
    rowsPerPage,
    orderBy,
    orderDirection,
    searchPhrase,
    statusOptions,
    filters,
    queryParams,
    selectedRows,
    numOfSelectedRows,
    isSelectAllChecked,
    isIndeterminateChecked,
    areSelectedAllOnAllPages,
    isSelectAllOnAllPagesNotificationVisible,
  };

  const methods: UsersMethodsContextType = {
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSelectRow,
    handleSelectAllRows,
    handleToggleAreSelectedAllOnAllPages,
    handleResetSelectedRows,
    handleSubmitFilters,
    handleSubmitSearchPhrase,
  };

  return (
    <UsersStateContext.Provider value={state}>
      <UsersMethodsContext.Provider value={methods}>{children}</UsersMethodsContext.Provider>
    </UsersStateContext.Provider>
  );
};
