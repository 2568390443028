import { useContext } from 'react';

import { UsersMethodsContextType } from 'context/users/usersContext/UsersContext.types';
import { UsersMethodsContext } from 'context/users/usersContext/UsersContext';

export const useUsersMethods: () => UsersMethodsContextType = () => {
  const context = useContext(UsersMethodsContext);

  if (context === undefined) {
    throw new Error('useUsersMethods must be used within an UsersContextController');
  }

  return context;
};
