import { useEffect, useState } from 'react';
import { useIsFetching } from 'react-query';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { Queries } from 'api/queries/queries.enum';

export const useHasLogoutStarted = () => {
  const { isAuthorized } = useAuthState();

  const [hasLogoutStarted, setHasLogoutStarted] = useState(false);

  const numberOfQueriesInProgress = useIsFetching([Queries.checkLoggedInRealms]);
  const isCheckingLoggedInRealms = numberOfQueriesInProgress > 0;

  useEffect(() => {
    if (isCheckingLoggedInRealms) {
      setHasLogoutStarted(true);
    }
  }, [isCheckingLoggedInRealms, setHasLogoutStarted]);

  useEffect(() => {
    if (isAuthorized) {
      setHasLogoutStarted(false);
    }
  }, [isAuthorized]);

  return { hasLogoutStarted };
};
