import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { UserRolesTableProps } from './UserRolesTable.types';
import { RestrictedRoleRow } from './restrictedRoleRow/RestrictedRoleRow';

export const UserRolesTable = ({ roles, withInstitutionName, ...props }: UserRolesTableProps) => {
  const translate = useTranslator();

  const renderTableBody = () => {
    if (!roles?.length) {
      return (
        <TableRow>
          <TableCell colSpan={withInstitutionName ? 3 : 2}>{translate('userRolesTable.noRoles')}</TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {roles.map(({ role, institution }, i) => (
          <RestrictedRoleRow role={role} institution={institution} withInstitutionName={withInstitutionName} key={i} />
        ))}
      </>
    );
  };

  return (
    <Table {...props}>
      <TableHead>
        <TableRow>
          <TableCell>{translate('userRolesTable.userRole')}</TableCell>
          <TableCell>{translate('userRolesTable.userInstitutionShortName')}</TableCell>
          {withInstitutionName && <TableCell>{translate('userRolesTable.userInstitution')}</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>{renderTableBody()}</TableBody>
    </Table>
  );
};
