import {
  Badge,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useFormatUserStatus } from 'hooks/format/useFormatUserStatus/useFormatUserStatus';
import { StatusName } from 'api/actions/users/usersActions.enum';
import { UserRolesTable } from 'ui/userRolesTable/UserRolesTable';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';

import { UserSummaryTableProps } from './UserSummaryTable.types';
import * as styles from './UserSummaryTable.styles';

export const UserSummaryTable = ({ data, options }: UserSummaryTableProps) => {
  const translate = useTranslator();

  const formatUserStatus = useFormatUserStatus();

  const { areMultipleBindingKeys, bindingKeyValueLabel, getBindingKeyLabel } = useBindingKey();

  const { lastName, firstName, bindingKey, email, login, roles, status } = data;

  const isStatusBlocked = status === StatusName.blocked;

  return (
    <Paper sx={styles.wrapper}>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          {translate('usersForm.summaryTitle')}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>{translate('usersForm.personalData')}</Typography>
      </Box>
      <TableContainer sx={styles.summaryTableContainerDesktop}>
        <Table sx={styles.summaryTable} size="small" aria-label={translate('usersForm.personalData')}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('global.lastName')}</TableCell>
              <TableCell>{translate('global.firstName')}</TableCell>
              {areMultipleBindingKeys && <TableCell>{translate('global.bindingKeyType')}</TableCell>}
              <TableCell>{bindingKeyValueLabel}</TableCell>
              <TableCell>{translate('global.email')}</TableCell>
              {options?.showLogin && <TableCell>{translate('global.login')}</TableCell>}
              <TableCell>{translate('global.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{lastName}</TableCell>
              <TableCell>{firstName}</TableCell>
              {areMultipleBindingKeys && <TableCell>{getBindingKeyLabel(bindingKey.key)}</TableCell>}
              <TableCell>{bindingKey.value}</TableCell>
              <TableCell>{email}</TableCell>
              {options?.showLogin && <TableCell>{login}</TableCell>}
              <TableCell sx={isStatusBlocked ? styles.statusBlocked : undefined}>{formatUserStatus(status)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer sx={styles.summaryTableContainerMobile}>
        <Table sx={styles.summaryTable} size="small" aria-label={translate('usersForm.personalData')}>
          <TableBody>
            <TableRow>
              <TableCell>{translate('global.lastName')}</TableCell>
              <TableCell>{lastName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{translate('global.firstName')}</TableCell>
              <TableCell>{firstName}</TableCell>
            </TableRow>
            {areMultipleBindingKeys && (
              <TableRow>
                <TableCell>{translate('global.bindingKeyType')}</TableCell>
                <TableCell>{getBindingKeyLabel(bindingKey.key)}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{bindingKeyValueLabel}</TableCell>
              <TableCell>{bindingKey.value}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{translate('global.email')}</TableCell>
              <TableCell>{email}</TableCell>
            </TableRow>
            {options?.showLogin && (
              <TableRow>
                <TableCell>{translate('global.login')}</TableCell>
                <TableCell>{login}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{translate('global.status')}</TableCell>
              <TableCell sx={isStatusBlocked ? styles.statusBlocked : undefined}>{formatUserStatus(status)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box mb={2} display="flex" alignItems="center">
        <Typography>{translate('usersForm.userRoles')}</Typography>
        <Badge sx={styles.rolesBadge} badgeContent={roles?.length} showZero={true} color="primary" />
      </Box>

      <TableContainer>
        <UserRolesTable
          roles={roles}
          sx={styles.rolesTable}
          size="small"
          aria-label={translate('usersForm.userRoles')}
        />
      </TableContainer>
    </Paper>
  );
};
