import { TableCell, TableHead, TableRow } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const LoginLogsTableHeader = () => {
  const translate = useTranslator();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{translate('global.event')}</TableCell>
        <TableCell>{translate('global.login')}</TableCell>
        <TableCell>{translate('global.dateTime')}</TableCell>
        <TableCell>{translate('global.ipAddress')}</TableCell>
        <TableCell>{translate('loginLogs.destination')}</TableCell>
        <TableCell>{translate('loginLogs.origin')}</TableCell>
        <TableCell>{translate('loginLogs.failureReason')}</TableCell>
      </TableRow>
    </TableHead>
  );
};
