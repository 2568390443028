import yup from 'utils/yup';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const usePasswordRemindSchema = () => {
  const translate = useTranslator();

  return yup.object().shape({
    login: yup.string().trim().required(translate('global.fieldRequired')),
  });
};
