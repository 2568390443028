let appConfigStore: AppConfigStore | undefined;

export interface AppConfigStore {
  API_URL: string;
  DEFAULT_LOCALE: string;
  SENTRY_DSN: string;
  PUBLIC_URL: string;
  RECAPTCHA_SITE_KEY: string;
  TOPBAR_CONTENT: string;
  FOOTER_CONTENT: string;
}

const throwFetchError = () => {
  throw Error('Can\'t fetch or parse application configuration from "/config.json"');
};

const setAppConfig = (config: AppConfigStore) => {
  appConfigStore = { ...appConfigStore, ...config };
};

export const loadAppConfiguration = (filename: string, force = false): Promise<AppConfigStore | void> => {
  if (appConfigStore && !force) {
    return Promise.resolve(appConfigStore);
  }

  return fetch(filename)
    .then((response) => (response.ok ? response.json() : throwFetchError()))
    .then(
      (config) => setAppConfig(config),
      () => throwFetchError(),
    );
};

export const getAppConfig = (variable: keyof AppConfigStore): string => {
  if (!appConfigStore) {
    throw Error(`Application configuration not set!`);
  }

  if (appConfigStore[variable] === undefined) {
    throw Error(`Application configuration variable "${variable}" not provided!`);
  }

  return appConfigStore[variable];
};
