import { Tooltip } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { RestrictedSectionTooltipProps } from './RestrictedSectionTooltip.types';

export const RestrictedSectionTooltip = ({
  isRestricted,
  children,
  title,
  ...tooltipProps
}: RestrictedSectionTooltipProps) => {
  const translate = useTranslator();

  if (isRestricted) {
    return (
      <Tooltip title={title ? title : translate('global.sectionRestricted')} arrow {...tooltipProps}>
        <span>{children}</span>
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};
