import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { EditUserPayload } from 'api/actions/users/usersActions.types';
import { checkCanEditUser } from 'api/actions/users/usersActions';

export const useCheckCanEditUser = () => {
  const mutationCheckCanEditUser = useMutation<void, AxiosError, EditUserPayload>(checkCanEditUser);

  return mutationCheckCanEditUser;
};
