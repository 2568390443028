import { usePermissions } from 'hooks/usePermissions/usePermissions';

import { AuthorizedSectionProps } from './AuthorizedSection.types';

export const AuthorizedSection = ({ allowedPermissions, children }: AuthorizedSectionProps) => {
  const { hasAccess } = usePermissions();

  const access = hasAccess(allowedPermissions);

  if (!access) {
    return <></>;
  }

  return <>{children}</>;
};
