import { Styles } from '@typings/common';

export const drawerOpenTransitionMixin: Styles = {
  transition: ({ transitions }) =>
    transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
};

export const drawerCloseTransitionMixin: Styles = {
  transition: ({ transitions }) =>
    transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
};
