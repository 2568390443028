import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueries } from 'react-query';
import queryString from 'query-string';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

import { executeOneTime } from 'api/queries/options';
import { logoutFromServiceProviderAction } from 'api/actions/sso/ssoActions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { LoggedInRealm } from 'api/actions/sso/ssoActions.types';
import { Queries, QueriesStatus } from 'api/queries/queries.enum';

import * as styles from './SsoLogout.styles';
import { SsoLogoutProps } from './SsoLogout.types';

export const mutateLogoutExecutionTimeout = 4 * 1000;
const queriesServiceProvidersLogoutTimeLimit = 20 * 1000;

export const SsoLogout = ({ loggedInRealms, onLogout }: SsoLogoutProps) => {
  const translate = useTranslator();

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer((prev) => prev + 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const wreply = useMemo<string | null>(() => {
    if (Array.isArray(queryParams.wreply)) {
      return queryParams.wreply[0];
    }

    return queryParams.wreply;
  }, [queryParams.wreply]);

  const queriesServiceProvidersLogout = useQueries(
    loggedInRealms.map((loggedInRealm) => ({
      queryKey: [Queries.logoutFromServiceProviders, loggedInRealm.wtrealm],
      queryFn: () =>
        loggedInRealm.cleanupDestinationUrl
          ? logoutFromServiceProviderAction(loggedInRealm.cleanupDestinationUrl)
          : null,
      ...executeOneTime,
    })),
  );

  const areQueriesServiceProvidersResolved = queriesServiceProvidersLogout.every(
    ({ status }) => status !== QueriesStatus.idle,
  );
  const isTimeLimitExceeded = timer > queriesServiceProvidersLogoutTimeLimit;

  useEffect(() => {
    if (areQueriesServiceProvidersResolved || isTimeLimitExceeded) {
      setTimeout(() => {
        onLogout(wreply);
      }, mutateLogoutExecutionTimeout);
    }
  }, [areQueriesServiceProvidersResolved, isTimeLimitExceeded, wreply]);

  const getStatusIcon = (serviceProvider: LoggedInRealm, i: number) => {
    if (serviceProvider.cleanupDestinationUrl) {
      switch (queriesServiceProvidersLogout[i].status) {
        case QueriesStatus.error:
          return <ErrorIcon color="error" />;
          break;
        case QueriesStatus.success:
          return <CheckCircleIcon color="success" />;
          break;
        default:
          return <CircularProgress size="1rem" />;
          break;
      }
    }

    return <WarningIcon color="warning" />;
  };

  return (
    <>
      <Typography component="h2" variant="h5" sx={styles.title}>
        {translate('logout.ssoTitle')}
      </Typography>

      <List
        component={Paper}
        subheader={<ListSubheader>{translate('logout.ssoServiceProvidersListTitle')}</ListSubheader>}
        sx={styles.serviceProvidersList}
      >
        {loggedInRealms.map((serviceProvider, i) => (
          <ListItem key={serviceProvider.wtrealm}>
            <ListItemText primary={serviceProvider.wtrealm} />
            <ListItemSecondaryAction>{getStatusIcon(serviceProvider, i)}</ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};
