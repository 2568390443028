import { Box, Chip } from '@mui/material';

import { hasActiveFilters } from 'utils/function/hasActiveFilters/hasActiveFilters';
import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useFormatEvent } from 'hooks/format/useFormatEvent/useFormatEvent';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { EventSource, EventType, FilterField } from 'api/actions/eventLogs/eventLogs.enum';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { EventLogsTableToolbarActiveFiltersProps } from './EventLogsTableToolbarActiveFilters.types';

export const EventLogsTableToolbarActiveFilters = ({
  filters,
  onPageChange,
  onSubmitFilterForm,
}: EventLogsTableToolbarActiveFiltersProps) => {
  const translate = useTranslator();

  const { formatDateAndTime } = useFormatDate();
  const { formatEventSource, formatEventType } = useFormatEvent();

  const filtersFields = Object.keys(filters) as FilterField[];

  const getLabelName = (key: FilterField) => {
    switch (key) {
      case FilterField.context:
        return `${translate('eventLogs.eventObject')}: ${filters?.[key]}`;
        break;
      case FilterField.eventDateTimeFrom:
        return `${translate('global.dateTimeFrom')}: ${formatDateAndTime(filters?.[key] as string)}`;
        break;
      case FilterField.eventDateTimeTo:
        return `${translate('global.dateTimeTo')}: ${formatDateAndTime(filters?.[key] as string)}`;
        break;
      case FilterField.processId:
        return `${translate('eventLogs.processId')}: ${filters?.[key]}`;
        break;
      case FilterField.eventSource:
        return `${translate('eventLogs.eventSource')}: ${formatEventSource(filters?.[key] as EventSource)}`;
        break;
    }
  };

  if (!hasActiveFilters(filters)) {
    return null;
  }

  return (
    <Box component="ul" sx={styles.chipList}>
      {filtersFields.map((key) => {
        if (filters?.[key]) {
          if (key === FilterField.eventType) {
            return (filters?.[key] as EventType[]).map((eventType) => (
              <li key={key}>
                <Chip
                  size="small"
                  label={formatEventType(eventType)}
                  onDelete={() => {
                    onSubmitFilterForm({
                      ...filters,
                      [key]: filters[key]?.filter((value) => value !== eventType),
                    });
                    onPageChange(null, 0);
                  }}
                />
              </li>
            ));
          }

          return (
            <li key={key}>
              <Chip
                size="small"
                label={getLabelName(key)}
                onDelete={() => {
                  onSubmitFilterForm({ ...filters, [key]: null });
                  onPageChange(null, 0);
                }}
              />
            </li>
          );
        }
      })}
    </Box>
  );
};
