import { MutableRefObject, ReactText } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { downloadExportFile } from 'api/actions/exports/exportsActions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { DownloadExportFilePayload } from 'api/actions/exports/exportActions.types';

export const useDownloadExportFile = (toastRef: MutableRefObject<ReactText>) => {
  const translate = useTranslator();

  const mutationDownloadExportFile = useMutation<void, AxiosError, DownloadExportFilePayload>(downloadExportFile, {
    onSuccess: () => {
      toast.update(toastRef.current, {
        render: translate('export.fileDownloaded'),
        type: 'success',
        autoClose: 5000,
        isLoading: false,
      });
    },
  });

  return mutationDownloadExportFile;
};
