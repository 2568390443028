import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { startChangeStatus } from 'api/actions/bulk/bulk';
import { StartChangeStatusPayload, StartBulkActionResponse } from 'api/actions/bulk/bulk.types';

export const useStartChangeStatus = () => {
  const mutationStartChangeStatus = useMutation<StartBulkActionResponse, AxiosError, StartChangeStatusPayload>(
    startChangeStatus,
  );

  return mutationStartChangeStatus;
};
