import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';

import { ConfigResponse } from './configActions.types';

export const getConfig = async (): Promise<ConfigResponse> => {
  const response = await umsApi.get(endpoints.configuration);

  return response.data;
};
