import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { ComponentType, useMemo, useState } from 'react';

import { permissions } from 'utils/permissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { AuthorizedSection } from 'hoc/authorizedSection/AuthorizedSection';
import { ManageRole as ManageRoleVariant } from 'api/actions/bulk/bulk.enum';
import { Permission } from 'api/actions/users/usersActions.enum';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { BulkActionName } from './UsersBulkActions.enum';
import { ChangeStatus } from './changeStatus/ChangeStatus';
import { Export } from './export/Export';
import { Remove } from './remove/Remove';
import { ManageRole } from './manageRole/ManageRole';
import { BulkAction, BulkActionComponentProps } from './UsersBulkActions.types';

export const UsersBulkActions = () => {
  const translate = useTranslator();

  const { numOfSelectedRows, areSelectedAllOnAllPages } = useUsersState();

  const [anchorBulkMenuEl, setAnchorBulkMenuEl] = useState<HTMLElement | null>(null);
  const isBulkMenuOpen = Boolean(anchorBulkMenuEl);

  const [activeBulkAction, setActiveBulkAction] = useState<BulkActionName | null>(null);

  const openBulkActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorBulkMenuEl(event.currentTarget);
  };

  const closeBulkActionMenu = () => {
    setAnchorBulkMenuEl(null);
  };

  const onCloseBulkAction = () => {
    setAnchorBulkMenuEl(null);
    setActiveBulkAction(null);
  };

  const bulkActionComponentWrapper = <T,>(Component: ComponentType<T & BulkActionComponentProps>) => {
    return (props: T) => <Component {...props} onCloseBulkAction={onCloseBulkAction} />;
  };

  const bulkActions: BulkAction[] = [
    {
      name: BulkActionName.export,
      label: translate('users.bulkActionsPrintStartupPasswords'),
      component: bulkActionComponentWrapper(Export),
      allowedPermissions: permissions.passwordsManagement,
    },
    {
      name: BulkActionName.assignRole,
      label: translate('users.bulkActionsAssignRole'),
      component: bulkActionComponentWrapper((props) => <ManageRole variant={ManageRoleVariant.assign} {...props} />),
      allowedPermissions: permissions.usersManage,
    },
    {
      name: BulkActionName.detachRole,
      label: translate('users.bulkActionsDetachRole'),
      component: bulkActionComponentWrapper((props) => <ManageRole variant={ManageRoleVariant.detach} {...props} />),
      allowedPermissions: permissions.usersManage,
    },
    {
      name: BulkActionName.changeStatus,
      label: translate('users.bulkActionsChangeStatus'),
      component: bulkActionComponentWrapper(ChangeStatus),
      allowedPermissions: permissions.usersManage,
    },
    {
      name: BulkActionName.remove,
      label: translate('users.bulkActionsRemove'),
      component: bulkActionComponentWrapper(Remove),
      allowedPermissions: [Permission.superAdministrator, Permission.userManagement],
    },
  ];

  const bulkActionsPermissions = [
    ...Array.from(
      new Set(
        bulkActions
          .filter(({ allowedPermissions }) => allowedPermissions?.length)
          .flatMap(({ allowedPermissions }) => allowedPermissions) as Permission[],
      ),
    ),
  ];

  const bulkActionComponent = useMemo(() => {
    if (activeBulkAction) {
      const BulkActionComponent = bulkActions.filter(({ name }) => activeBulkAction === name)[0].component;

      return <BulkActionComponent />;
    }

    return null;
  }, [activeBulkAction]);

  return (
    <Box sx={styles.selectedBox}>
      <Typography sx={styles.selectedBoxTitle} color="inherit" variant="subtitle1" component="div">
        {translate('users.toolbarSelected')}{' '}
        {areSelectedAllOnAllPages ? translate('users.buttonBulkActionsAllUsers') : numOfSelectedRows}
      </Typography>

      <AuthorizedSection allowedPermissions={bulkActionsPermissions}>
        <Button
          id="bulk-actions-menu-button"
          aria-controls={isBulkMenuOpen ? 'bulk-actions-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isBulkMenuOpen ? 'true' : undefined}
          onClick={openBulkActionMenu}
          variant="outlined"
        >
          {translate('users.buttonBulkActions')}
        </Button>

        <Menu
          id="bulk-actions-menu"
          anchorEl={anchorBulkMenuEl}
          keepMounted
          open={isBulkMenuOpen}
          onClose={closeBulkActionMenu}
          MenuListProps={{
            'aria-labelledby': 'bulk-actions-menu-button',
          }}
        >
          {bulkActions.map(({ name, label, allowedPermissions }) => (
            <AuthorizedSection allowedPermissions={allowedPermissions} key={name}>
              <MenuItem onClick={() => setActiveBulkAction(name)}>{label}</MenuItem>
            </AuthorizedSection>
          ))}
        </Menu>

        {bulkActionComponent}
      </AuthorizedSection>
    </Box>
  );
};
