import { useEffect, useRef } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { AxiosError } from 'axios';

import { errorMessages } from 'api/errors/messages';
import { useGetUsers } from 'hooks/users/useGetUsers/useGetUsers';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { DataLoading } from 'ui/table/row/dataLoading/DataLoading';
import { DataLoadingError } from 'ui/table/row/dataLoadingError/DataLoadingError';
import { NoData } from 'ui/table/row/noData/NoData';
import { Pagination } from 'ui/table/pagination/Pagination';
import { User } from 'api/actions/users/usersActions.types';
import { UsersTableHeader } from 'app/users/usersTable/usersTableHeader/UsersTableHeader';
import * as styles from 'ui/table/Table.styles';

import { UsersTableRow } from './usersTableRow/UsersTableRow';
import { UsersTableToolbar } from './usersTableToolbar/UsersTableToolbar';

export const UsersTable = () => {
  const translate = useTranslator();

  const { page, rowsPerPage, paginationOptions, searchPhrase, queryParams } = useUsersState();
  const { handleChangePage, handleChangeRowsPerPage } = useUsersMethods();

  const { data, error, isError, isFetching, isLoading, isPreviousData } = useGetUsers(queryParams);

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    tableContainerRef.current?.scrollTo({ left: 0 });
  }, [searchPhrase]);

  const errorHandler = (error: AxiosError) => {
    const errors: string | Record<string, string> = error?.response?.data?.errors;

    if (!Array.isArray(errors)) {
      return;
    }

    if (errors.includes(errorMessages.pagination.pageDoesNotExist)) {
      handleChangePage(undefined, 0);
    }
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <DataLoading data-testid="loader" />;
    }

    if (isError) {
      errorHandler(error);

      return <DataLoadingError />;
    }

    if (data) {
      return data.items.length > 0 ? (
        <>
          {data?.items.map((user: User, index: number) => (
            <UsersTableRow key={user.id} user={user} index={(page + 1) * rowsPerPage - rowsPerPage + index} />
          ))}
        </>
      ) : (
        <NoData />
      );
    }
  };

  return (
    <Paper sx={styles.tableWrapper} data-testid="users-table">
      {isFetching && isPreviousData && <CircularProgress sx={styles.loader} data-testid="loader" />}

      <UsersTableToolbar />

      <TableContainer ref={tableContainerRef} sx={styles.tableContainer}>
        <Table sx={styles.table} stickyHeader aria-label={translate('users.pageTitle')} size="small">
          <UsersTableHeader />
          <TableBody sx={styles.tableBody}>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>

      {data && (
        <Pagination
          count={data?.totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={paginationOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
