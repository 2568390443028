import { endpoints } from 'api/endpoints/endpoints';
import { umsApi } from 'api/axios/axiosInstance';

import { ChangeEmailPayload, ConfirmEmailPayload } from './email.types';

export const changeEmail = async (values: ChangeEmailPayload): Promise<void> => {
  await umsApi.post(endpoints.email.change, values);
};

export const confirmEmail = async (values: ConfirmEmailPayload): Promise<void> => {
  await umsApi.post(endpoints.email.confirm, values);
};
