import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { verifyPasswordLink } from 'api/actions/password/passwordActions';
import { VerifyPasswordLinkPayload } from 'api/actions/password/passwordActions.types';

export const useVerifyPasswordLink = () => {
  const mutationVerifyPasswordLink = useMutation<void, AxiosError, VerifyPasswordLinkPayload>(verifyPasswordLink);

  return mutationVerifyPasswordLink;
};
