import { Styles } from '@typings/common';

export const wrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  width: '36rem',
  maxWidth: '100%',
  my: { md: 3 },
  mx: { md: 'auto' },
  p: 2,
};

export const icon: Styles = {
  fontSize: { xs: '2rem', md: '3rem' },
};

export const description: Styles = {
  ml: { xs: 1, md: 2 },
};
