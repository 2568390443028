import yup from 'utils/yup';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { PasswordSyntax } from 'api/actions/config/configActions.types';

export const usePasswordChangeFormData = ({
  passwordSyntax,
  numberOfStoredPasswords,
}: {
  passwordSyntax: PasswordSyntax;
  numberOfStoredPasswords: number;
}) => {
  const translate = useTranslator();

  if (!passwordSyntax) {
    return yup.object().shape({
      oldPassword: yup.string().required(translate('global.fieldRequired')),
      newPassword: yup.string().required(translate('global.fieldRequired')),
      newPasswordRepeated: yup
        .string()
        .oneOf(
          [yup.ref('newPassword'), null],
          translate('CONSTRAINT.VALIDATION.USER.PASSWORD.NEW_PASSWORDS_ARE_NOT_EQUAL'),
        )
        .required(translate('global.fieldRequired')),
    });
  }

  return yup.object().shape({
    oldPassword: yup.string().required(translate('global.fieldRequired')),
    newPassword: yup
      .string()
      .min(passwordSyntax.minLength, translate('global.fieldMinLength', { value: passwordSyntax.minLength }))
      .max(passwordSyntax.maxLength, translate('global.fieldMaxLength', { value: passwordSyntax.maxLength }))
      .minLowercase(
        passwordSyntax.minLowerCaseLetters || 0,
        translate('global.fieldMinLowercaseLetters', { value: passwordSyntax.minLowerCaseLetters }),
      )
      .minUppercase(
        passwordSyntax.minUpperCaseLetters || 0,
        translate('global.fieldMinUppercaseLetters', { value: passwordSyntax.minUpperCaseLetters }),
      )
      .minNumbers(
        passwordSyntax.minDigits || 0,
        translate('global.fieldMinDigits', { value: passwordSyntax.minDigits }),
      )
      .minSymbols(
        passwordSyntax.minSpecialCharacters || 0,
        translate('global.fieldMinSpecialCharacters', { value: passwordSyntax.minSpecialCharacters }),
      )
      .notOneOf(
        [numberOfStoredPasswords ? yup.ref('oldPassword') : null, null],
        translate('CONSTRAINT.VALIDATION.USER.PASSWORD.NEW_PASSWORD_SAME_AS_OLD_PASSWORD'),
      )
      .required(translate('global.fieldRequired')),
    newPasswordRepeated: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        translate('CONSTRAINT.VALIDATION.USER.PASSWORD.NEW_PASSWORDS_ARE_NOT_EQUAL'),
      )
      .required(translate('global.fieldRequired')),
  });
};
