import isEqual from 'lodash.isequal';

import yup from 'utils/yup';
import { isInstitutionRequired } from 'utils/function/isInstitutionRequired/isInstitutionRequired';
import { regExp } from 'utils/regExp';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { PasswordProvider } from 'api/actions/config/configActions.enum';
import { Permission } from 'api/actions/users/usersActions.enum';
import { Role } from 'api/actions/roles/rolesActions.types';
import { UserRoleResponse } from 'api/actions/users/usersActions.types';

import { FormFields } from './UsersForm.enum';

export const useUsersFormDataSchema = () => {
  const translate = useTranslator();

  const config = useConfigState();
  const isPasswordProviderEmail = config.passwordProviderType === PasswordProvider.email;

  const { areMultipleBindingKeys } = useBindingKey();

  const { hasAccess } = usePermissions();

  return [
    yup.object().shape(
      {
        lastName: yup
          .string()
          .max(
            150,
            translate('global.fieldMaxLength', {
              fieldName: translate('global.lastName'),
              value: 150,
            }),
          )
          .trim()
          .required(translate('global.fieldRequired')),
        firstName: yup
          .string()
          .max(
            70,
            translate('global.fieldMaxLength', {
              fieldName: translate('global.firstName'),
              value: 70,
            }),
          )
          .trim()
          .required(translate('global.fieldRequired')),
        bindingKey: yup.object().shape({
          key: yup.string().when(FormFields.bindingKeyKey, {
            is: () => areMultipleBindingKeys,
            then: yup.string().required(translate('global.fieldRequired')),
          }),
          value: yup.string().trim().required(translate('global.fieldRequired')),
        }),
        email: yup
          .string()
          .nullable()
          .when(FormFields.email, {
            is: (value: string) => isPasswordProviderEmail || value,
            then: yup
              .string()
              .transform((value) => (value === null ? '' : value))
              .required(translate('global.fieldRequired'))
              .matches(regExp.email, translate('global.fieldWrongFormat')),
          })
          .max(250, translate('global.fieldMaxLength', { fieldName: translate('global.email'), value: 250 })),
        status: yup.string().required('global.fieldRequired'),
      },
      [[FormFields.email, FormFields.email]],
    ),
    yup.object().shape({
      roles: yup.array().of(
        yup
          .object()
          .shape({
            role: yup.mixed().nullable().required(translate('global.fieldRequired')),
            institution: yup.mixed().when('role', {
              is: (role: Role) => {
                return !role || !isInstitutionRequired(role);
              },
              then: yup.object().nullable().required(translate('global.fieldRequired')),
            }),
          })
          .test('unique', function (currentUserRole) {
            const { createError, parent, path } = this;

            const areDuplicatedRoles =
              parent.filter((userRole: UserRoleResponse) =>
                isEqual(
                  { institution: userRole.institution?.id, role: userRole.role?.id },
                  { institution: currentUserRole.institution?.id, role: currentUserRole.role?.id },
                ),
              ).length > 1;

            if (areDuplicatedRoles) {
              throw createError({
                path: `${path}.role`,
                message: translate('usersForm.userRoleUnique'),
              });
            }

            return true;
          }),
      ),
    }),
  ];
};
