import { Theme } from '@mui/material';

import { Styles } from '@typings/common';

export const appBar = {
  mt: 'auto',
  py: { xs: 2, md: 0 },
  px: 0,
  textAlign: { xs: 'center', md: 'left' },
  color: 'text.primary',
  backgroundColor: 'background.default',
  boxShadow: 'none',
  borderTop: (theme: Theme) => `2px solid ${theme.palette.border?.main}`,
};

export const toolbar: Styles = {
  display: 'flex',
  alignItems: 'center',
  minHeight: { sm: '3.5rem' },
  overflow: 'hidden',
};
