import { Styles } from '@typings/common';

export const row: Styles = {
  '& .MuiTableCell-root': {
    borderBottom: 'unset',
  },
};

export const nestedTable: Styles = {
  width: 'auto',
  mt: { md: 2 },
  mb: { xs: 2, md: 4 },
  mr: { xs: 2, md: 8 },

  '& tr:only-child': {
    '& td': {
      border: 0,
    },
  },
};

export const collapsedRow: Styles = {
  '&>td': {
    p: 0,
    m: 0,

    '&.MuiTableCell-sizeSmall': {
      p: 0,
    },
  },
};

export const slimCell: Styles = {
  p: '0.25rem',
  width: '1px',
};

export const propertyNameCell: Styles = {
  ...slimCell,
  minWidth: '12rem',
  fontWeight: 700,
};

export const propertyValueCell: Styles = {
  minWidth: '16rem',
};
