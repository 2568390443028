export enum FilterField {
  login = 'login',
  createdAtDateTimeFrom = 'createdAtDateTimeFrom',
  createdAtDateTimeTo = 'createdAtDateTimeTo',
  ipAddress = 'ipAddress',
  origin = 'origin',
  destination = 'destination',
}

export type FiltersPayload = {
  [FilterField.login]?: string | null;
  createdAtTimestampFrom?: number | null;
  createdAtTimestampTo?: number | null;
  [FilterField.ipAddress]?: string | null;
  [FilterField.origin]?: string | null;
  [FilterField.destination]?: string | null;
};
