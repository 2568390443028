import { Box, Button, Typography } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';

import * as styles from './UsersSelectAllOnAllPages.styles';

export const UsersSelectAllOnAllPages = () => {
  const translate = useTranslator();

  const { areSelectedAllOnAllPages } = useUsersState();

  const { handleToggleAreSelectedAllOnAllPages } = useUsersMethods();

  return (
    <Box sx={styles.container}>
      <Typography>{translate('users.selectAllOnAllPagesNotification')}</Typography>
      <Button
        size="small"
        variant={areSelectedAllOnAllPages ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => handleToggleAreSelectedAllOnAllPages(true)}
        sx={styles.confirmButton}
      >
        {translate('users.selectAllOnAllPagesNotificationConfirm')}
      </Button>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={() => handleToggleAreSelectedAllOnAllPages(false)}
      >
        {translate('users.selectAllOnAllPagesNotificationAbort')}
      </Button>
    </Box>
  );
};
