import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';

import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useCreateUser } from 'hooks/users/useCreateUser/useCreateUser';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { CreateUserPayload } from 'api/actions/users/usersActions.types';
import { UsersForm } from '../usersForm/UsersForm';
import { useGetUser } from 'hooks/users/useGetUser/useGetUser';
import { ExecutionType } from '@typings/common';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { Permission } from 'api/actions/users/usersActions.enum';

export const UsersAdd = () => {
  const translate = useTranslator();
  const history = useHistory();

  const { hasAccess } = usePermissions();

  const mutateCreateUser = useCreateUser();

  const mutateGetUser = useGetUser(undefined, ExecutionType.mutation);

  const confirmFormAbort = useConfirmFormAbort();

  const handleSubmitFormData = useCallback(
    (data: CreateUserPayload) => {
      mutateCreateUser.mutate(data, {
        onSuccess: async ({ id }) => {
          confirmFormAbort.disable();

          const hasRoles = data.roles.length > 0;
          const canSeeAddedUser = !hasRoles && !hasAccess([Permission.superAdministrator, Permission.userListFull]);

          if (canSeeAddedUser) {
            return history.push(AppRoute.users);
          }

          const user = await mutateGetUser.mutateAsync(id);

          history.push({ pathname: AppRoute.users, state: { lastAddedUser: user } });

          window.scrollTo(0, 0);
        },
        onError: () => {
          toast.error(translate('usersAdd.errorResponse'));
        },
      });
    },
    [mutateCreateUser],
  );

  return (
    <>
      <Typography component="h2" variant="h5">
        {translate('usersAdd.pageTitle')}
      </Typography>

      <UsersForm
        isUpdateError={mutateCreateUser.isError}
        isUpdateLoading={mutateCreateUser.isLoading}
        updateError={mutateCreateUser.error}
        onSubmitFormData={handleSubmitFormData}
      />
    </>
  );
};
