import { useContext, useEffect } from 'react';

import { ConfirmFormAbortContext } from 'context/confirmFormAbort/confirmFormAbortContext/ConfirmFormAbortContext';
import { ConfirmFormAbortContextType } from 'context/confirmFormAbort/confirmFormAbortContext/ConfirmFormAbortContext.types';

type UseConfirmFormAbort = (isFormDirty?: boolean) => ConfirmFormAbortContextType;

export const useConfirmFormAbort: UseConfirmFormAbort = (isFormDirty) => {
  const confirmFormAbortContext = useContext(ConfirmFormAbortContext);

  if (confirmFormAbortContext === undefined) {
    throw new Error('useConfirmFormAbort must be used within an ConfirmFormAbortContextController');
  }

  useEffect(() => {
    if (isFormDirty) {
      return confirmFormAbortContext.enable();
    }

    if (isFormDirty === false) {
      return confirmFormAbortContext.disable();
    }
  }, [isFormDirty]);

  return confirmFormAbortContext;
};
