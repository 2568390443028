import { AxiosError } from 'axios';

import { AppRoute } from 'routing/AppRoute.enum';
import { ChangePasswordPayload } from 'api/actions/password/passwordActions.types';
import { PasswordSyntax } from 'api/actions/config/configActions.types';

export type PasswordChangeFormProps = {
  disabled?: boolean;
  error: AxiosError | null;
  isError: boolean;
  onSubmit: (body: ChangePasswordPayload) => Promise<boolean>;
  redirectOnCancel?: {
    to: AppRoute;
  };
  passwordSyntax: PasswordSyntax;
  numberOfStoredPasswords: number;
};

export enum PasswordChangeFormFields {
  oldPassword = 'oldPassword',
  newPassword = 'newPassword',
  newPasswordRepeated = 'newPasswordRepeated',
}

export type PasswordChangeFormData = {
  [PasswordChangeFormFields.oldPassword]: string;
  [PasswordChangeFormFields.newPassword]: string;
  [PasswordChangeFormFields.newPasswordRepeated]: string;
};
