import { useCallback, useEffect } from 'react';
import { Alert } from '@mui/material';
import axios from 'axios';

import { useSetNewPassword } from 'hooks/password/useSetNewPassword/useSetNewPassword';
import { useGetPasswordSyntax } from 'hooks/password/useGetPasswordSyntax/useGetPasswordSyntax';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Loader } from 'ui/loader/Loader';
import { SetNewPasswordPayload } from 'api/actions/password/passwordActions.types';
import { errorMessages } from 'api/errors/messages';

import { PasswordNewForm } from './PasswordNewForm';
import { PasswordNewFormData } from './PasswordNewForm.types';
import { PasswordNewFormContainerProps } from './PasswordNewFormContainer.types';

export const PasswordNewFormContainer = ({ onShowLinkExpiredMessage, token }: PasswordNewFormContainerProps) => {
  const translate = useTranslator();

  const queryGetPasswordSyntax = useGetPasswordSyntax();
  const mutationSetNewPassword = useSetNewPassword();

  const onSubmit = useCallback(
    async (body: PasswordNewFormData): Promise<boolean> => {
      try {
        const bodyWithToken: SetNewPasswordPayload = {
          ...body,
          token,
        };

        await mutationSetNewPassword.mutateAsync(bodyWithToken);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const errors = error?.response?.data?.errors;

          if (errors?.includes(errorMessages.password.invalidResetLinkToken)) {
            onShowLinkExpiredMessage();
          }
        }

        return false;
      }

      return true;
    },
    [mutationSetNewPassword.mutateAsync],
  );

  if (queryGetPasswordSyntax.isLoading) {
    return <Loader data-testid="password-new-form-container-loader" />;
  }

  if (queryGetPasswordSyntax.isError) {
    return <Alert severity="error">{translate('global.errorMessage')}</Alert>;
  }

  if (queryGetPasswordSyntax.data) {
    return (
      <PasswordNewForm
        error={mutationSetNewPassword.error}
        isError={mutationSetNewPassword.isError}
        isSuccess={mutationSetNewPassword.isSuccess}
        onSubmit={onSubmit}
        passwordSyntax={queryGetPasswordSyntax.data}
      />
    );
  }

  return null;
};
