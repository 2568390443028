import { Styles } from '@typings/common';

export const wrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  py: { xs: 2, md: 3 },
  px: { xs: 0, md: 2 },
  backgroundColor: { xs: 'inherit', md: 'background.paper' },
  boxShadow: { xs: 'none', md: 1 },
};

export const summaryTableContainer: Styles = {
  mb: 4,

  '& .MuiTableCell-root': {
    whiteSpace: 'break-spaces',
    wordBreak: { md: 'break-word' },
  },
};

export const summaryTableContainerDesktop: Styles = {
  ...summaryTableContainer,
  display: { xs: 'none', md: 'block' },
};

export const summaryTableContainerMobile: Styles = {
  ...summaryTableContainer,
  display: { md: 'none' },

  '& .MuiTableCell-body:first-child': {
    fontWeight: 700,
  },
};

export const summaryTable: Styles = {
  tableLayout: 'fixed',
};

export const togglePasswordButton: Styles = {
  ml: 1,
};

export const statusBlocked: Styles = {
  color: 'error.main',
  fontWeight: 700,
};

export const rolesBadge: Styles = {
  ml: 2,

  '& .MuiBadge-badge': {
    backgroundColor: 'text.disabled',
  },
};

export const rowDisabled: Styles = {
  '& td': {
    color: 'action.disabled',
  },
};

export const rolesTable: Styles = {
  width: 'auto',
  minWidth: '50%',
};
