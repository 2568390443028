import { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { resetPassword, resetStartupPassword } from 'api/actions/password/passwordActions';
import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Queries } from 'api/queries/queries.enum';
import { UsersResponse } from 'api/actions/users/usersActions.types';
import { UsersQueryParams } from 'app/users/usersTable/UserTable.types';
import { PasswordProvider } from 'api/actions/config/configActions.enum';

export const useResetPassword = ({
  userId,
  usersQueryParams,
}: {
  userId: string;
  usersQueryParams: UsersQueryParams;
}) => {
  const translate = useTranslator();
  const queryClient = useQueryClient();

  const config = useConfigState();
  const passwordProviderType = config.passwordProviderType;

  const mutationFn = useMemo(() => {
    if (passwordProviderType === PasswordProvider.email) {
      return resetPassword;
    }

    return resetStartupPassword;
  }, [passwordProviderType]);

  const mutationResetPassword = useMutation<void, AxiosError, string>(mutationFn, {
    onError: () => {
      toast.error(translate('global.errorMessage'));
    },
    onSuccess: () => {
      if (passwordProviderType === PasswordProvider.generator) {
        const isPreviousGetStartupPasswordData = queryClient.getQueryData([Queries.getStartupPassword, userId]);

        if (isPreviousGetStartupPasswordData) {
          queryClient.refetchQueries([Queries.getStartupPassword, userId]);
        }

        const previousGetUsersData = queryClient.getQueryData<UsersResponse>([Queries.getUsers, usersQueryParams]);
        const isWithStartupPassword = previousGetUsersData?.items.filter((user) => user.id === userId)[0]
          .withStartupPassword;

        if (!isWithStartupPassword) {
          queryClient.setQueryData([Queries.getUsers, usersQueryParams], {
            ...previousGetUsersData,
            items: previousGetUsersData?.items.map((user) =>
              user.id === userId ? { ...user, withStartupPassword: true } : user,
            ),
          });
        }
      }
    },
  });

  return mutationResetPassword;
};
