export enum AppRoute {
  home = '/',
  sso = '/sso',
  login = '/login',
  passwordRemind = '/password/remind',
  passwordNew = '/password/reset',
  passwordChange = '/password/change',
  emailConfirm = '/email/confirm',
  logout = '/logout',
  institutions = '/institutions',
  roles = '/roles',
  users = '/users',
  usersAdd = '/users/add',
  usersDelete = '/users/delete/:id',
  usersEdit = '/users/edit/:id',
  usersImport = '/users/import',
  eventLogs = '/eventLogs',
  profile = '/profile/:tab?',
  loginLogs = '/loginLogs',
  notFound = '/notFound',
}
