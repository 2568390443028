import { formatUsersFilterValues } from 'utils/function/formatUsersFilterValues/formatUsersFilterValues';
import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useStartRemove } from 'hooks/bulk/useStartRemove/useStartRemove';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { BulkActionComponentProps } from '../UsersBulkActions.types';
import { ConfirmDialog } from 'ui/confirmDialog/ConfirmDialog';

export const Remove = ({ onCloseBulkAction }: BulkActionComponentProps) => {
  const translate = useTranslator();

  const { selectedRows, numOfSelectedRows, areSelectedAllOnAllPages, filters, searchPhrase } = useUsersState();
  const { handleResetSelectedRows, handleToggleAreSelectedAllOnAllPages } = useUsersMethods();

  const mutationStartRemove = useStartRemove();

  const onStartRemove = () => {
    const params = removeEmptyFields({
      ...formatUsersFilterValues(filters),
      searchPhrase,
      allUsers: true,
    });

    mutationStartRemove.mutate({
      params: areSelectedAllOnAllPages ? params : undefined,
      userIds: !areSelectedAllOnAllPages ? selectedRows : undefined,
    });

    onCloseBulkAction();
    handleResetSelectedRows();
    handleToggleAreSelectedAllOnAllPages(false);
  };

  return (
    <ConfirmDialog
      id="confirmRemove"
      title={translate('users.bulkActionsRemoveConfirmDialogTitle')}
      description={translate('users.bulkActionsRemoveConfirmDialogDescription', {
        numOfUsers: areSelectedAllOnAllPages
          ? translate('users.buttonBulkActionsAllUsers').toLocaleLowerCase()
          : numOfSelectedRows,
      })}
      show
      onCancel={onCloseBulkAction}
      onConfirm={onStartRemove}
      onConfirmButtonText={translate('users.bulkActionsRemoveConfirmDialogSubmit')}
      isProcessing={mutationStartRemove.isLoading}
    />
  );
};
