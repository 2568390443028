import { Permission } from 'api/actions/users/usersActions.enum';

export const permissions = {
  usersListView: [Permission.superAdministrator, Permission.userListFull, Permission.userListInstitution],
  usersManage: [Permission.superAdministrator, Permission.userManagement, Permission.institutionUserManagement],
  usersImport: [Permission.superAdministrator, Permission.userManagement],
  rolesListView: [Permission.superAdministrator, Permission.roleListFull],
  institutionsListView: [Permission.superAdministrator, Permission.institutionsList],
  eventLogsView: [Permission.superAdministrator, Permission.eventLog],
  passwordChange: [Permission.passwordChange],
  passwordsManagement: [
    Permission.superAdministrator,
    Permission.passwordsManagement,
    Permission.institutionPasswordsManagement,
  ],
  ignoreRolesBlacklist: [Permission.superAdministrator, Permission.ignoreRolesBlacklist],
  loginLogsView: [Permission.superAdministrator, Permission.loginLog],
};
