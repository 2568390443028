import { Styles } from '@typings/common';

export const toolbar: Styles = {
  flexWrap: 'wrap',
  py: 1,
  px: { xs: 2, sm: 2 },
  minHeight: { xs: 'unset', sm: 'unset' },
  rowGap: 1,
};

export const selectedBox: Styles = {
  display: 'flex',
  alignItems: 'center',
  mr: 2,
};

export const selectedBoxTitle: Styles = {
  mr: 2,
  fontWeight: 700,
};

export const filterForm: Styles = {
  mt: { md: 1.5 },
};

export const filterInput: Styles = {
  width: '24rem',
  maxWidth: '100%',
  mb: 2,
};

export const datePicker: Styles = {
  width: '100%',
};

export const chipList: Styles = {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 1,
  m: 0,
  p: 0,
  listStyleType: 'none',
  order: 30,
};

export const noRoles: Styles = {
  fontStyle: 'italic',
};

export const bulkActions: Styles = {
  display: 'flex',
  flexWrap: 'wrap',

  '.searchBarOpen &': {
    order: 20,
    width: '100%',
  },
};
