export enum FilterField {
  context = 'context',
  eventDateTimeFrom = 'eventDateTimeFrom',
  eventDateTimeTo = 'eventDateTimeTo',
  eventType = 'eventType',
  eventSource = 'eventSource',
  processId = 'processId',
}

export enum EventType {
  bulkActionStarted = 'bulk_action_started',
  bulkActionFinished = 'bulk_action_finished',
  bulkActionFailed = 'bulk_action_failed',
  roleAssignedToUser = 'role_assigned_to_user',
  roleDetachedFromUser = 'role_detached_from_user',
  userActivated = 'user_activated',
  userCreated = 'user_created',
  userEdited = 'user_edited',
  userRemoved = 'user_removed',
  userBlocked = 'user_blocked',
  userStartupPasswordChanged = 'user_startup_password_changed',
  userPasswordChanged = 'user_password_changed',
  userPasswordReset = 'user_password_reset',
}

export enum ProcessType {
  userAdding = 'user_adding',
  userEditing = 'user_editing',
  userRemoving = 'user_removing',
  userPasswordChanging = 'user_password_changing',
  userPasswordReseting = 'user_password_reseting',
  userEmailChanging = 'user_email_changing',
  userNotConfirmedEmailChanging = 'user_not_confirmed_email_changing',
}

export enum EventSource {
  ums = 'ums',
  application = 'application',
}

export enum BulkActionType {
  usersStatusChange = 'users_status_change',
  usersRemove = 'users_remove',
  usersImport = 'users_import',
  roleAssign = 'role_assign',
  roleDetach = 'role_detach',
}
