import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { AxiosError } from 'axios';

import { setAuthorized } from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/auth/useAuthDispatch/useAuthDispatch';
import { useGetUserMe } from 'hooks/users/useGetUserMe/useGetUserMe';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useSsoLogin } from 'hooks/auth/useSsoLogin/useSsoLogin';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { AuthModeName } from 'context/auth/authContext/AuthContext.enum';
import { AuthorizeResponse, LoginPayload } from 'api/actions/sso/ssoActions.types';
import { AuthMode } from 'context/auth/authContext/AuthContext.types';
import { ExecutionType } from '@typings/common';
import { LoginForm } from 'ui/loginForm/LoginForm';

import { QueryParamsType } from './SsoContainer.types';

export const SsoContainer = () => {
  const translate = useTranslator();

  const dispatch = useAuthDispatch();
  const { hasAccessOnlyPasswordChange } = usePermissions();

  const location = useLocation();
  const queryParams = useMemo(() => {
    return queryString.parse(location.search) as QueryParamsType;
  }, [location.search]);

  const serviceProviderParams = {
    wtrealm: queryParams.wtrealm,
    wctx: queryParams.wctx,
    wreply: queryParams.wreply,
    wa: queryParams.wa,
  };

  const [isTooManyRequestsError, setIsTooManyRequestsError] = useState(false);

  const mutationSsoLogin = useSsoLogin();
  const mutationGetUserMe = useGetUserMe({ executionType: ExecutionType.mutation });

  const [loginResponse, setLoginResponse] = useState<AuthorizeResponse>();

  const onSubmit = useCallback(
    async (body: Pick<LoginPayload, 'login' | 'password' | 'token'>): Promise<boolean> => {
      const payload = {
        login: body.login,
        password: body.password,
        token: body.token,
        ...serviceProviderParams,
      };

      try {
        const ssoLoginResponse = await mutationSsoLogin.mutateAsync(payload);

        if (ssoLoginResponse) {
          setLoginResponse(ssoLoginResponse);
        } else {
          const user = await mutationGetUserMe.mutateAsync();

          if (user) {
            toast.success(translate('login.success'));

            const authMode: AuthMode = {
              name: AuthModeName.sso,
              params: { ...serviceProviderParams },
            };

            dispatch(setAuthorized(authMode, user));
          }
        }
        return true;
      } catch (error) {
        const isTooManyRequestsError = (error as AxiosError)?.response?.status === 429;

        if (isTooManyRequestsError) {
          setIsTooManyRequestsError(true);
        } else {
          setIsTooManyRequestsError(false);
        }

        return false;
      }
    },
    [dispatch, mutationSsoLogin.mutateAsync, mutationGetUserMe.mutateAsync],
  );

  useEffect(() => {
    if (loginResponse) {
      document.forms[1].submit();
    }
  }, [loginResponse]);

  if (hasAccessOnlyPasswordChange) {
    return <Redirect to={AppRoute.passwordChange} />;
  }

  return (
    <>
      <LoginForm
        disabled={!!loginResponse}
        error={mutationSsoLogin.error}
        formTitle={translate('sso.formTitle')}
        formSubTitle={translate('sso.formSubTitle')}
        onSubmit={onSubmit}
        enableReCaptcha={isTooManyRequestsError}
      />
      {loginResponse && <div className="visuallyHidden" dangerouslySetInnerHTML={{ __html: loginResponse }}></div>}
    </>
  );
};
