import { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useFormatUserStatus } from 'hooks/format/useFormatUserStatus/useFormatUserStatus';
import { useGetInstitutionsShortNames } from 'hooks/institutions/useGetInstitutionsShortNames/useGetInstitutionsShortNames';
import { useGetRoles } from 'hooks/useGetRoles/useGetRoles';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { Autocomplete } from 'ui/autocomplete/Autocomplete';
import { DatePicker } from 'ui/datePicker/DatePicker';
import { FiltersPopover } from 'ui/table/toolbar/filtersPopover/FiltersPopover';
import { FilterField } from 'api/actions/users/usersActions.enum';
import { Filters } from 'api/actions/users/usersActions.types';
import { Role } from 'api/actions/roles/rolesActions.types';
import { SearchBar } from 'ui/table/toolbar/searchBar/SearchBar';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { useFiltersDataSchema } from './FiltersDataSchema';
import { UsersBulkActions } from './usersBulkActions/UsersBulkActions';
import { PopoverRef, SearchFormData } from './UsersTableToolbar.types';
import { UsersTableToolbarActiveFilters } from './usersTableToolbarActiveFilters/UsersTableToolbarActiveFilters';
import { UsersSelectAllOnAllPages } from './usersSelectAllOnAllPages/UsersSelectAllOnAllPages';

export const UsersTableToolbar = () => {
  const translate = useTranslator();
  const formatUserStatus = useFormatUserStatus();
  const { numOfSelectedRows, statusOptions, isSelectAllOnAllPagesNotificationVisible, filters } = useUsersState();

  const {
    handleSubmitFilters,
    handleSubmitSearchPhrase,
    handleResetSelectedRows,
    handleToggleAreSelectedAllOnAllPages,
  } = useUsersMethods();

  const { areMultipleBindingKeys, bindingKeyValueLabel } = useBindingKey();

  const [rolesSearchPhrase, setRolesSearchPhrase] = useState('');
  const [institutionSearchPhrase, setInstitutionSearchPhrase] = useState('');

  const popoverRef = useRef<PopoverRef>();

  const filtersDataSchema = useFiltersDataSchema();

  const methods = useForm<Filters>({
    mode: 'onChange',
    resolver: yupResolver(filtersDataSchema),
  });

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = methods;

  const lastLoginDateFrom = watch(FilterField.lastLoginDateFrom);
  const neverLogged = watch(FilterField.neverLogged);

  useEffect(() => {
    if (errors?.lastLoginDateTo) {
      trigger([FilterField.lastLoginDateTo]);
    }
  }, [lastLoginDateFrom]);

  const clearFieldsWithErrors = (listenedFields: FilterField[]) => {
    listenedFields.forEach((field) => {
      if (errors?.[field]) {
        clearErrors(field);
        setValue(field, null);
      }
    });
  };

  const dateFields: FilterField[] = [FilterField.lastLoginDateFrom, FilterField.lastLoginDateTo];

  useEffect(() => {
    if (neverLogged) {
      clearFieldsWithErrors(dateFields);
    }
  }, [neverLogged]);

  const queryGetRoles = useGetRoles({
    itemsPerPage: 50,
    searchPhrase: rolesSearchPhrase,
  });

  const noRolesId = 'no_roles';
  const noRolesOption: Role = {
    id: noRolesId,
    builtIn: false,
    creationDate: null,
    key: noRolesId,
    name: translate('users.filtersNoRoles'),
  };
  const rolesOptions = queryGetRoles.data?.items ? [noRolesOption, ...queryGetRoles.data?.items] : [];

  const queryGetInstitutions = useGetInstitutionsShortNames({
    itemsPerPage: 100,
    searchPhrase: institutionSearchPhrase,
  });

  const handleSubmitSearchForm = ({ searchPhrase }: SearchFormData) => {
    handleSubmitSearchPhrase(searchPhrase);
    handleResetSelectedRows();
    handleToggleAreSelectedAllOnAllPages(false);
  };

  const onSubmitFilterForm = (data: Filters) => {
    handleSubmitFilters(data);
    handleResetSelectedRows();
    handleToggleAreSelectedAllOnAllPages(false);
    popoverRef?.current?.handleCloseFiltersPopover();
  };

  const resetValuesToSubmitted = useCallback(() => {
    const filtersFields = Object.keys(filters) as FilterField[];

    filtersFields.forEach((key) => {
      setValue(key, filters[key]);
    });

    clearErrors();
  }, [filters, setValue]);

  useEffect(() => {
    resetValuesToSubmitted();
  }, [filters, resetValuesToSubmitted]);

  const handleChangeInstitutionSearchPhrase = () => (event: { target: { value: string } }) => {
    setInstitutionSearchPhrase(event.target.value);
  };

  const handleChangeRolesSearchPhrase = () => (event: { target: { value: string } }) => {
    setRolesSearchPhrase(event.target.value);
  };

  const resetInstitutionSearchPhrase = () => {
    setInstitutionSearchPhrase('');
  };

  const resetRolesSearchPhrase = () => {
    setRolesSearchPhrase('');
  };

  const showBulkActions = numOfSelectedRows > 0;

  return (
    <Toolbar sx={styles.toolbar}>
      {showBulkActions && (
        <Box sx={styles.bulkActions}>
          {showBulkActions && <UsersBulkActions />}

          {isSelectAllOnAllPagesNotificationVisible && <UsersSelectAllOnAllPages />}
        </Box>
      )}

      <SearchBar
        onSubmit={handleSubmitSearchForm}
        textFieldPlaceholder={translate('users.searchPlaceholder', {
          bindingKey: areMultipleBindingKeys ? translate('global.bindingKeyValue') : bindingKeyValueLabel,
        })}
      />

      <FiltersPopover TransitionProps={{ onExited: resetValuesToSubmitted }} ref={popoverRef}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitFilterForm)} noValidate autoComplete="off">
            <Box padding={2}>
              <Typography paragraph>{translate('global.filtersTitle')}</Typography>
              <Controller
                render={({ field }) => (
                  <>
                    <Autocomplete
                      {...field}
                      multiple
                      loading={queryGetRoles.isFetching}
                      size="small"
                      sx={styles.filterInput}
                      options={rolesOptions}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.id === noRolesId ? (
                            <Typography sx={styles.noRoles}>{option.name}</Typography>
                          ) : (
                            option.name
                          )}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={translate('users.filtersRoleLabel')}
                          onChange={debounce(handleChangeRolesSearchPhrase(), 250)}
                          variant="outlined"
                        />
                      )}
                      onClose={resetRolesSearchPhrase}
                      onChange={(_, data) => {
                        field.onChange(data);
                      }}
                      data-testid="role-autocomplete"
                      ListboxProps={{ 'data-testid': 'role-list' } as HTMLAttributes<HTMLUListElement>}
                    />
                  </>
                )}
                name={FilterField.role}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <>
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      sx={styles.filterInput}
                      loading={queryGetInstitutions.isFetching}
                      options={queryGetInstitutions.data ? queryGetInstitutions.data?.items : []}
                      getOptionLabel={(option) => option.shortName}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={debounce(handleChangeInstitutionSearchPhrase(), 250)}
                          variant="outlined"
                          label={translate('users.filtersInstitutionLabel')}
                        />
                      )}
                      onClose={resetInstitutionSearchPhrase}
                      onChange={(_, data) => {
                        field.onChange(data);
                      }}
                      data-testid="institution-autocomplete"
                      ListboxProps={{ 'data-testid': 'institution-list' } as HTMLAttributes<HTMLUListElement>}
                    />
                  </>
                )}
                name={FilterField.institution}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    size="small"
                    sx={styles.filterInput}
                    options={statusOptions}
                    getOptionLabel={(option) => formatUserStatus(option.name)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label={translate('users.filtersStatusPlaceholder')} />
                    )}
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                    data-testid="status-autocomplete"
                    ListboxProps={{ 'data-testid': 'status-list' } as HTMLAttributes<HTMLUListElement>}
                  />
                )}
                name={FilterField.status}
                control={control}
              />
              <Box sx={styles.filterInput}>
                <Tooltip
                  title={translate('users.filtersLastLoginDateTooltip')}
                  disableHoverListener={!neverLogged}
                  disableFocusListener={!neverLogged}
                  disableTouchListener={!neverLogged}
                  arrow
                >
                  <DatePicker<Filters>
                    label={translate('users.filtersLastLoginDateFrom')}
                    name={FilterField.lastLoginDateFrom}
                    disabled={neverLogged}
                    textFieldProps={{ sx: styles.datePicker }}
                  />
                </Tooltip>
              </Box>
              <Box sx={styles.filterInput}>
                <Tooltip
                  title={translate('users.filtersLastLoginDateTooltip')}
                  disableHoverListener={!neverLogged}
                  disableFocusListener={!neverLogged}
                  disableTouchListener={!neverLogged}
                  arrow
                >
                  <DatePicker<Filters>
                    label={translate('users.filtersLastLoginDateTo')}
                    name={FilterField.lastLoginDateTo}
                    disabled={neverLogged}
                    textFieldProps={{ sx: styles.datePicker }}
                  />
                </Tooltip>
              </Box>
              <FormGroup sx={styles.filterInput}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name={FilterField.neverLogged}
                      render={(props) => (
                        <Checkbox
                          {...props}
                          checked={props.field.value}
                          color="primary"
                          onChange={(e) => props.field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={translate('users.filtersNeverLoggedLabel')}
                />
              </FormGroup>
              <Button type="submit" variant="contained">
                {translate('global.filtersSubmit')}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </FiltersPopover>

      <UsersTableToolbarActiveFilters onSubmitFilterForm={onSubmitFilterForm} />
    </Toolbar>
  );
};
