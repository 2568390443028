import { endOfDay, getUnixTime, isSameDay, startOfDay } from 'date-fns';

import { keysOf } from '../keysOf/keysOf';
import { FilterField } from 'api/actions/users/usersActions.enum';
import { Filters, FiltersPayload, Status } from 'api/actions/users/usersActions.types';
import { InstitutionShortName } from 'api/actions/institutions/institutionsActions.types';
import { Role } from 'api/actions/roles/rolesActions.types';

const timeStampStartDate = new Date(1970, 0, 1);

export const formatUsersFilterValues = (filters: Filters): FiltersPayload => {
  const output: FiltersPayload = {};

  const filtersFields: FilterField[] = keysOf(filters);

  for (const key of filtersFields) {
    if (key === FilterField.lastLoginDateFrom) {
      const lastLoginTimestampFrom = (() => {
        const date = new Date(filters[key] as string);

        if (isSameDay(date, timeStampStartDate)) {
          return 0;
        }

        return getUnixTime(startOfDay(date));
      })();

      output.lastLoginTimestampFrom = filters[key] ? lastLoginTimestampFrom : undefined;
      continue;
    }

    if (key === FilterField.lastLoginDateTo) {
      const lastLoginTimestampTo = (() => {
        const date = new Date(filters[key] as string);

        if (isSameDay(date, timeStampStartDate)) {
          return 0;
        }

        return getUnixTime(endOfDay(date));
      })();

      output.lastLoginTimestampTo = filters[key] ? lastLoginTimestampTo : undefined;
      continue;
    }

    if (key === FilterField.neverLogged) {
      output[key] = filters[key] ? filters[key] : false;
      continue;
    }

    if (filters[key]?.length) {
      output[key] = (filters[key] as (Role | InstitutionShortName | Status)[])?.map((item) => item.id);
      continue;
    }
  }

  if (output.neverLogged) {
    delete output.lastLoginTimestampFrom;
    delete output.lastLoginTimestampTo;
  } else {
    delete output.neverLogged;
  }

  return output;
};
