import { Styles } from '@typings/common';

export const wrapper: Styles = {
  display: 'flex',
  width: '36rem',
  maxWidth: '100%',
  my: 3,
  mx: 'auto',
  pt: 2,
  pb: { xs: 2, md: 4 },
  px: 2,
};

export const icon: Styles = {
  fontSize: { xs: '2rem', md: '4rem' },
};

export const textBox: Styles = {
  ml: { xs: 1, md: 2 },
};

export const title: Styles = {
  fontWeight: 500,
};

export const description: Styles = {
  mt: 2,

  '&:first-child': {
    mt: 0,
  },
};

export const button: Styles = {
  mt: 2,
};
