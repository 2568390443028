import * as yup from 'yup';
import YupPassword from 'yup-password';
import { AnyObject, Maybe } from 'yup/lib/types';
import { addSeconds } from 'date-fns';

import { regExp } from './regExp';

YupPassword(yup);

const pluralize = (word: string, num: number) => {
  return num === 1 ? word : `${word}s`;
};

yup.addMethod<yup.DateSchema>(yup.date, 'isMinTimestampDate', function (message?: string) {
  const defaultMessage = 'Date should be after 1st Janurary 1970.';
  const returnMessage = message || defaultMessage;

  return this.min(new Date(1970, 0, 1), returnMessage);
});

yup.addMethod<yup.DateSchema>(yup.date, 'isNotFuture', function (message?: string) {
  const defaultMessage = 'Date can not be in future.';
  const returnMessage = message || defaultMessage;

  return this.max(addSeconds(new Date(), 10), returnMessage);
});

yup.addMethod<yup.StringSchema>(yup.string, 'minSymbols', function (length = 1, message?: string) {
  const defaultMessage = '${path} must contain at least ${length} ' + pluralize('symbol', length);
  const returnMessage = message || defaultMessage;

  return this.test({
    name: 'minSymbols',
    exclusive: true,
    message: returnMessage,
    params: { length },
    test(value) {
      if (value === null || value === undefined) {
        return true;
      }

      return (value.match(regExp.specialCharacters) || []).length >= length;
    },
  });
});

declare module 'yup' {
  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isMinTimestampDate(message?: string): DateSchema<TType, TContext>;
    isNotFuture(message?: string): DateSchema<TType, TContext>;
  }

  interface StringSchema {
    minSymbols(length?: number, message?: string): StringSchema;
  }
}
// eslint-disable-next-line import/no-default-export
export default yup;
