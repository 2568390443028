import { useState } from 'react';
import { Step, StepLabel, Stepper, Typography, StepContent, useTheme, useMediaQuery } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { ErrorValidation } from './steps/errorValidation/ErrorValidation';
import { FilePreparation } from './steps/filePreparation/FilePreparation';
import { FileFormState, Step as ImportStep } from './UsersImport.types';
import { FormFields } from './steps/filePreparation/FilePreparation.types';
import * as styles from './UsersImport.styles';

export const UsersImport = () => {
  const translate = useTranslator();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const initialState: FileFormState = {
    data: {
      [FormFields.file]: null,
    },
    id: null,
    isValid: false,
  };

  const [formState, setFormState] = useState<FileFormState>(initialState);

  const handleChangeFormState = (formState: FileFormState) => {
    setFormState(formState);
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleClickNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleClickBack = () => {
    setFormState(initialState);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps: ImportStep[] = [
    {
      label: translate('usersImport.step1Title'),
      component: (
        <FilePreparation
          formState={formState}
          onChangeFormState={handleChangeFormState}
          onClickNext={handleClickNext}
        />
      ),
    },
    {
      label: translate('usersImport.step2Title'),
      component: <ErrorValidation formState={formState} onClickBack={handleClickBack} />,
    },
  ];

  const stepsLabels: string[] = steps.map(({ label }) => label);

  const renderStepContent = (step: number): JSX.Element => {
    return steps[step].component;
  };

  return (
    <>
      <Typography component="h2" variant="h5">
        {translate('usersImport.pageTitle')}
      </Typography>

      <Stepper activeStep={activeStep} sx={styles.stepper} orientation={matches ? 'horizontal' : 'vertical'}>
        {stepsLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            {!matches && (
              <StepContent TransitionProps={{ unmountOnExit: false }}>{renderStepContent(activeStep)}</StepContent>
            )}
          </Step>
        ))}
      </Stepper>

      {matches && renderStepContent(activeStep)}
    </>
  );
};
