import { TableCell, TableSortLabel } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { OrderDirection, SortableTableCellProps } from './SortableTableCell.props';

export const SortableTableCell = <T,>({
  id,
  orderBy,
  orderDirection,
  onRequestSort,
  children,
  ...props
}: SortableTableCellProps<T>) => {
  const translate = useTranslator();

  return (
    <TableCell key={id} sortDirection={orderBy === id ? orderDirection : false} {...props}>
      <TableSortLabel
        active={orderBy === id}
        direction={orderBy === id ? orderDirection : OrderDirection.asc}
        onClick={(e) => onRequestSort(e, id)}
      >
        {children}
        {orderBy === id ? (
          <span className="visuallyHidden">
            {orderDirection === OrderDirection.desc
              ? translate('global.sortedDescending')
              : translate('global.sortedAscending')}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};
