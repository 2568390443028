import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker as MUIDatePicker, DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers';
import { TextField, TextFieldProps } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { DatePickerProps } from './DatePicker.types';

export const DatePicker = <T,>({
  label,
  name,
  textFieldProps,
  ...props
}: DatePickerProps<T> & Partial<MUIDatePickerProps<Date, Date>>) => {
  const translate = useTranslator();
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const { ...formMethodsProps } = register(name as 'name');

  return (
    <Controller
      {...formMethodsProps}
      render={({ field }) => (
        <MUIDatePicker
          {...props}
          {...field}
          disableFuture={true}
          OpenPickerButtonProps={{ size: 'small', 'aria-label': translate('dateTimePicker.selectDate') }}
          componentsProps={{
            actionBar: {
              actions: ['cancel', 'clear', 'today'],
            },
          }}
          toolbarTitle={translate('dateTimePicker.selectDate')}
          inputFormat="dd-MM-yyyy"
          mask={'__-__-____'}
          value={field.value}
          onChange={(date: Date | null) => {
            field.onChange(date);
          }}
          renderInput={(props: TextFieldProps) => (
            <TextField
              {...props}
              {...textFieldProps}
              size="small"
              label={label}
              placeholder={translate('datePicker.placeholder')}
              error={!!errors[name as string]}
              helperText={errors[name as string]?.message}
            />
          )}
        />
      )}
      defaultValue={null}
      name={name as string}
      control={control}
    />
  );
};
