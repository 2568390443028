import { Styles } from '@typings/common';

export const indexRow: Styles = {
  width: '1px',
};

export const slimCell: Styles = {
  p: '0.25rem',
  width: '1px',
};

export const goToUsers: Styles = {
  whiteSpace: 'nowrap',
};

export const rowDisabled: Styles = {
  '& td': {
    color: 'action.disabled',
  },
};
