import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { executeOneTime } from 'api/queries/options';
import { getLoggedInRealms } from 'api/actions/sso/ssoActions';
import { LoggedInRealmsResponse } from 'api/actions/sso/ssoActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetLoggedInRealms = () => {
  const queryCheckLoggedInRealms = useQuery<LoggedInRealmsResponse, AxiosError>(
    Queries.checkLoggedInRealms,
    getLoggedInRealms,
    {
      ...executeOneTime,
    },
  );

  return queryCheckLoggedInRealms;
};
