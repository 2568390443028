import { AxiosRequestConfig } from 'axios';

import { endpoints } from 'api/endpoints/endpoints';
import { toUsersEndpointFilterSearchParams } from 'utils/function/toUsersEndpointFilterSearchParams/toUsersEndpointFilterSearchParams';
import { umsApi } from 'api/axios/axiosInstance';

import {
  StartBulkActionResponse,
  StartChangeStatusPayload,
  StartManageRolePayload,
  StartRemovePayload,
} from './bulk.types';
import { ManageRole } from './bulk.enum';

export const startChangeStatus = async (payload: StartChangeStatusPayload): Promise<StartBulkActionResponse> => {
  const { params, status, userIds } = payload;

  const data = {
    status,
    userIds,
  };

  const config: AxiosRequestConfig = {
    params: toUsersEndpointFilterSearchParams(params),
  };

  const response = await umsApi.post(endpoints.bulk.changeStatus, data, config);

  return response.data;
};

const manageRoleUrls: Record<ManageRole, string> = {
  [ManageRole.assign]: endpoints.bulk.assignRole,
  [ManageRole.detach]: endpoints.bulk.detachRole,
};

export const startManageRole = async (payload: StartManageRolePayload): Promise<StartBulkActionResponse> => {
  const { variant, params, roleId, institutionId, userIds } = payload;

  const data = {
    roleId,
    institutionId,
    userIds,
  };

  const config: AxiosRequestConfig = {
    params: toUsersEndpointFilterSearchParams(params),
  };

  const response = await umsApi.post(manageRoleUrls[variant], data, config);

  return response.data;
};

export const startRemove = async (payload: StartRemovePayload): Promise<StartBulkActionResponse> => {
  const { params, userIds } = payload;

  const data = {
    userIds,
  };

  const config: AxiosRequestConfig = {
    params: toUsersEndpointFilterSearchParams(params),
  };

  const response = await umsApi.post(endpoints.bulk.remove, data, config);

  return response.data;
};
