import { Box, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';
import { UsersFormData } from '../UsersForm.types';
import { UserSummaryTable } from 'ui/userSummaryTable/UserSummaryTable';
import * as styles from '../UsersForm.styles';

import { UsersFormSummaryProps } from './UsersFormSummary.types';

export const UsersFormSummary = ({
  isEditForm,
  updateError,
  isUpdateLoading,
  checkCanEditUserError,
  onClickBack,
  onSubmitFormData,
}: UsersFormSummaryProps) => {
  const translate = useTranslator();

  const {
    formState: { touchedFields },
    getValues,
  } = useFormContext<UsersFormData>();

  const usersFormData = getValues();

  const checkCanEditUserErrors = checkCanEditUserError?.response?.data?.errors;
  const updateErrors = updateError?.response?.data?.errors;

  const serverErrors: string[] | Record<string, string> = checkCanEditUserErrors || updateErrors;

  const isUpdateErrorsListVisible = updateErrors && Object.keys(touchedFields).length === 0;

  const isServerErrorsListVisible = checkCanEditUserErrors || isUpdateErrorsListVisible;

  return (
    <>
      <UserSummaryTable data={usersFormData} options={{ showLogin: isEditForm }} />

      {isServerErrorsListVisible && <FormServerErrors serverErrors={serverErrors} sx={styles.serverErrorsList} />}

      <Box sx={styles.buttonBox}>
        <Button onClick={onClickBack} color="inherit">
          {translate('global.buttonBack')}
        </Button>

        <Button type="submit" disabled={isUpdateLoading} onClick={onSubmitFormData} variant="contained" color="primary">
          {translate('global.buttonSave')}
        </Button>
      </Box>
    </>
  );
};
