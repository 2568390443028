import { Styles } from '@typings/common';

export const table: Styles = {
  '& .MuiTableCell-sizeSmall': {
    p: 0.5,

    '&:first-child': {
      pl: 2,
    },
  },
  '& .MuiTableRow-hover': {
    height: '2.75rem',
  },
};

export const loader: Styles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  m: 'auto',
};

export const tableBody: Styles = {
  height: '100%',
};

export const tableContainer: Styles = {
  flexGrow: 1,
  position: 'relative',
};

export const tableWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: { md: '15rem' },
};

export const slimCell: Styles = {
  p: '0.25rem',
  width: '1px',
};

export const indexCell: Styles = {
  width: '1px',
};

export const wrappedContentCell: Styles = {
  whiteSpace: 'normal',
  wordBreak: 'break-word',
};

export const eventObjectCell: Styles = {
  ...wrappedContentCell,
  minWidth: '30rem',
};
