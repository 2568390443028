import { Box, Typography } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { LoginLogsTable } from './loginLogsTable/LoginLogsTable';

export const LoginLogs = () => {
  const translate = useTranslator();

  return (
    <>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          {translate('loginLogs.pageTitle')}
        </Typography>
      </Box>

      <LoginLogsTable />
    </>
  );
};
