import { useEffect, useRef, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Link, TextField } from '@mui/material';
import parseHtml from 'html-react-parser';

import { endpoints } from 'api/endpoints/endpoints';
import { getDownloadLink } from 'utils/function/getDownloadLink/getDownloadLink';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useCancelForm } from 'hooks/useCancelForm/useCancelForm';
import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useFileUploader } from 'hooks/upload/useFileUploader/useFileUploader';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { CircularProgressWithLabel } from 'ui/circularProgresWithLabel/CircularProgressWithLabel';
import { UploadFileData } from 'api/actions/upload/upload.types';

import { useFileDataSchema } from './FileDataSchema';
import { FileForm, FilePreparationProps, FormFields, UploadImportFileResponse } from './FilePreparation.types';
import { ServerErrorsList } from './serverErrorsLIst/ServerErrorsList';
import * as styles from './FilePreparation.styles';

export const FilePreparation = ({ formState, onChangeFormState, onClickNext }: FilePreparationProps) => {
  const translate = useTranslator();

  const { cancelForm } = useCancelForm();

  const [completed, setCompleted] = useState(0);

  const fileDataSchema = useFileDataSchema();

  const { bindingKeys } = useBindingKey();

  const { passwordProviderType } = useConfigState();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    watch,
    control,
  } = useForm<FileForm>({
    defaultValues: formState.data,
    resolver: yupResolver(fileDataSchema),
    mode: 'onChange',
  });

  useConfirmFormAbort(isDirty);

  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const inputFileId = 'select-file';

  const file = watch(FormFields.file);
  const hasFile = file && file.length > 0;
  const fileName = hasFile ? file[0].name : '';

  const { error, isError, isLoading, mutate, reset } = useFileUploader<UploadImportFileResponse>();

  const onSubmit = (data: UploadFileData) => {
    mutate(
      { data, url: endpoints.imports.users.initValidation, setCompleted },
      {
        onSuccess: ({ id }) => {
          onChangeFormState({ data, id, isValid: true });
        },
        onError: () => {
          onChangeFormState({ ...formState, data: formState.data, isValid: false });
        },
      },
    );
  };

  const validationError = (errors[FormFields.file] as FieldError)?.message;
  const serverErrors: string[] = error?.response?.data?.errors;
  const hasErrors = validationError || serverErrors;

  useEffect(() => {
    if (isError) {
      const errorType = 'server';

      if (serverErrors) {
        setError(FormFields.file, {
          type: errorType,
          message: translate('global.fileWrongContent'),
        });
      }
    }
  }, [isError, serverErrors]);

  const bindingKeysList = bindingKeys.map(({ key }) => key).join(' / ');
  const importFileTemplateHref = getDownloadLink('imports.users.importFileTemplate');

  return (
    <>
      <Box sx={styles.description}>
        {parseHtml(translate('usersImport.filePreparation.description', { bindingKeysList, passwordProviderType }))}
      </Box>

      <Link href={importFileTemplateHref} target="_blank" sx={styles.importFileTemplateLink}>
        {translate('usersImport.filePreparation.downloadImportFileTemplate')}
      </Link>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.inputFileWrapper}>
          <Box component="label" htmlFor={inputFileId} sx={styles.inputFileLabel}>
            <strong>{translate('global.selectFile')}</strong>
          </Box>

          <TextField
            variant="standard"
            id={inputFileId}
            value={fileName}
            sx={styles.customInputFile}
            onClick={() => inputFileRef.current?.click()}
            InputProps={{ readOnly: true }}
            error={!!validationError}
            data-testid="input-file-custom"
          />

          <Controller
            name="file"
            render={({ field }) => (
              <input
                id={inputFileId}
                type="file"
                accept=".csv"
                hidden
                ref={inputFileRef}
                onChange={(event) => {
                  reset();

                  if (event.currentTarget.files) {
                    field.onChange(event.currentTarget.files);
                    handleSubmit(onSubmit)();
                  } else {
                    field.onChange(null);
                  }
                }}
                onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                  event.currentTarget.value = '';
                }}
                data-testid="input-file-native"
              />
            )}
            control={control}
          />

          <Button
            disabled={isLoading}
            variant="contained"
            component="span"
            color="primary"
            onClick={() => inputFileRef.current?.click()}
          >
            {translate('global.select')}
          </Button>

          <Box sx={styles.loaderInputFileContainer}>
            {isLoading && <CircularProgressWithLabel size="2.5rem" value={completed} data-testid="input-file-loader" />}
          </Box>
        </Box>

        {hasErrors && (
          <Box sx={styles.errorBox}>
            {serverErrors ? (
              <ServerErrorsList errors={serverErrors} sx={styles.serverErrors} />
            ) : (
              <Alert severity="error">{validationError}</Alert>
            )}
          </Box>
        )}

        <Box sx={styles.buttonBox}>
          <Button onClick={cancelForm} color="inherit">
            {translate('global.buttonCancel')}
          </Button>

          <Button disabled={!formState.isValid || isLoading} variant="contained" color="primary" onClick={onClickNext}>
            {translate('global.buttonNext')}
          </Button>
        </Box>
      </form>
    </>
  );
};
