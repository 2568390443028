import { useState } from 'react';
import { IconButton, TextField, TextFieldProps, Tooltip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import * as styles from './Select.styles';

export const Select = (props: TextFieldProps) => {
  const translate = useTranslator();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleOpenSelect = () => {
    setIsSelectOpen(true);
  };

  const handleCloseSelect = () => {
    setIsSelectOpen(false);
  };

  const handleClickButton = () => {
    isSelectOpen ? handleCloseSelect() : handleOpenSelect();
  };

  const isSelectDisabled = props.disabled;

  const tooltipDisabledProps = {
    disableFocusListener: true,
    disableHoverListener: true,
    disableTouchListener: true,
  };

  return (
    <TextField
      {...props}
      select
      SelectProps={{
        IconComponent: (props) => (
          <Tooltip title={translate('global.open')} {...(isSelectDisabled ? tooltipDisabledProps : {})} {...props}>
            <IconButton
              size="small"
              sx={styles.iconButton}
              onClick={handleClickButton}
              disabled={isSelectDisabled}
              tabIndex={-1}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </Tooltip>
        ),
        open: isSelectOpen,
        onClose: handleCloseSelect,
        onOpen: handleOpenSelect,
      }}
    />
  );
};
