import { useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import parseHtml from 'html-react-parser';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Paper, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { PasswordActionMessage } from 'ui/password/passwordActionMessage/PasswordActionMessage';
import { RemindPasswordPayload } from 'api/actions/password/passwordActions.types';
import { TextField } from 'ui/textField/TextField';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';

import { usePasswordRemindSchema } from './PasswordRemindSchema';
import { FormFields, LocationState, PasswordRemindForm, PasswordRemindProps } from './PasswordRemind.types';
import * as styles from './PasswordRemind.styles';

export const PasswordRemind = ({ error, isSuccess, onSubmit }: PasswordRemindProps) => {
  const translate = useTranslator();

  const { state } = useLocation<LocationState>();
  const backButtonLocation = {
    pathname: state?.from ? state.from : AppRoute.login,
    search: state?.search ? state.search : '',
  };

  const passwordRemindSchema = usePasswordRemindSchema();
  const methods = useForm<PasswordRemindForm>({
    resolver: yupResolver(passwordRemindSchema),
    mode: 'onChange',
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  const handleSubmitCallback = useCallback(
    async (body: RemindPasswordPayload) => {
      const valid = await onSubmit(body);

      if (!valid) {
        const errorType = 'server';

        setError(FormFields.login, { type: errorType });
      }
    },
    [onSubmit],
  );

  const serverErrors: string[] | Record<string, string> = error?.response?.data?.errors;

  if (isSuccess) {
    return (
      <PasswordActionMessage
        description={parseHtml(translate('passwordRemind.successDescription'))}
        icon={InfoIcon}
        iconColor={'primary'}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <Paper component="form" onSubmit={handleSubmit(handleSubmitCallback)} sx={styles.form(isSuccess)}>
        <Typography variant="body1" component="h4" sx={styles.formTitle}>
          {translate('passwordRemind.formTitle')}
        </Typography>
        <Typography variant="h5" component="h2" sx={styles.formSubTitle}>
          {translate('passwordRemind.formSubTitle')}
        </Typography>

        <Typography variant="body2" sx={styles.formDescription}>
          {parseHtml(translate('passwordRemind.formDescription'))}
        </Typography>

        <TextField<PasswordRemindForm>
          name={FormFields.login}
          label={translate('passwordRemind.userLogin')}
          sx={styles.textField}
          fullWidth
        />

        {serverErrors && <FormServerErrors serverErrors={serverErrors} />}

        <Box sx={styles.buttonBox}>
          <Button to={backButtonLocation} component={RouterLink} sx={styles.backButton} color="inherit">
            {translate('passwordRemind.cancelPasswordRemind')}
          </Button>
          <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
            {translate('passwordRemind.submitPasswordRemind')}
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};
