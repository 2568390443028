import { CircularProgress, TableCell, TableRow } from '@mui/material';

import * as styles from './DataLoading.styles';

export const DataLoading = ({ ...props }) => (
  <TableRow {...props}>
    <TableCell sx={styles.loaderCell} colSpan={100}>
      <CircularProgress sx={styles.loader} />
    </TableCell>
  </TableRow>
);
