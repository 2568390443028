import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { setNewPassword } from 'api/actions/password/passwordActions';
import { SetNewPasswordPayload } from 'api/actions/password/passwordActions.types';

export const useSetNewPassword = () => {
  const mutationSetNewPassword = useMutation<void, AxiosError, SetNewPasswordPayload>(setNewPassword);

  return mutationSetNewPassword;
};
