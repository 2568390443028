import { lighten, Theme } from '@mui/material';

import { drawerCloseTransitionMixin, drawerOpenTransitionMixin } from 'utils/mixins';
import { drawerCloseWidth, drawerOpenWidth } from 'utils/theme';
import { Styles } from '@typings/common';

export const drawer = (isDrawerOpen: boolean): Styles => ({
  width: drawerOpenWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  ...(isDrawerOpen ? drawerOpen : drawerClose),
});

export const drawerPaper = (isDrawerOpen: boolean): Styles => ({
  position: { lg: 'static' },
  ...(isDrawerOpen ? drawerOpen : drawerClose),
});

export const drawerOpen: Styles = {
  ...drawerOpenTransitionMixin,
  width: drawerOpenWidth,
  overflowX: 'hidden',
};

export const drawerClose: Styles = {
  ...drawerCloseTransitionMixin,
  overflowX: 'hidden',
  width: drawerCloseWidth,
};

export const userBox: Styles = {
  display: { lg: 'none' },
};

export const user: Styles = {
  py: 3,
  px: 1,
};

export const userFullName: Styles = {
  ml: 2,
  wordBreak: 'break-word',
  whiteSpace: 'break-spaces',
};

export const drawerFooter: Styles = {
  mt: 'auto',
  textAlign: 'right',
};

export const drawerFooterIcon: Styles = {
  mr: 1.5,
  mb: 1,
};

export const navLink: Styles = {
  '&.active': {
    position: 'relative',
    backgroundColor: (theme: Theme) => lighten(theme.palette.primary.main, 0.92),

    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: (theme: Theme) => theme.spacing(0.5),
      height: '100%',
      backgroundColor: 'primary.main',
    },
  },
};
