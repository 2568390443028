import { useMemo } from 'react';
import { TableCell, TableRow } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { RestrictedSectionTooltip } from 'hoc/restrictedSectionTooltip/RestrictedSectionTooltip';
import { Restriction, useCheckRestrictedRoles } from 'hooks/useCheckRestrictedRoles/useCheckRestrictedRoles';

import { RestrictedRoleRowProps } from './RestrictedRoleRow.types';
import * as styles from './RestrictedRoleRow.styles';

export const RestrictedRoleRow = ({ role, institution, withInstitutionName }: RestrictedRoleRowProps) => {
  const translate = useTranslator();
  const { checkIsRestrictedRole } = useCheckRestrictedRoles();

  const isBlaclistedRole = checkIsRestrictedRole(role, { restrictionType: Restriction.blacklisted });
  const isNonAssignableRole = checkIsRestrictedRole(role, { restrictionType: Restriction.nonAssignable });
  const isRestrictedRole = isBlaclistedRole || isNonAssignableRole;

  const tooltipTitle = useMemo(() => {
    if (isBlaclistedRole) {
      return translate('global.roleBlacklisted');
    } else if (isNonAssignableRole) {
      return translate('global.roleNonAssignable');
    }

    return null;
  }, [isBlaclistedRole, isNonAssignableRole]);

  return (
    <TableRow sx={isRestrictedRole ? styles.rowDisabled : undefined}>
      <TableCell>
        <RestrictedSectionTooltip isRestricted={isRestrictedRole} title={tooltipTitle}>
          {role.name}
        </RestrictedSectionTooltip>
      </TableCell>
      <TableCell>
        <RestrictedSectionTooltip isRestricted={isRestrictedRole} title={tooltipTitle}>
          {institution?.shortName}
        </RestrictedSectionTooltip>
      </TableCell>
      {withInstitutionName && (
        <TableCell>
          <RestrictedSectionTooltip isRestricted={isRestrictedRole} title={tooltipTitle}>
            {institution?.name}
          </RestrictedSectionTooltip>
        </TableCell>
      )}
    </TableRow>
  );
};
