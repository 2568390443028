import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Alert } from '@mui/material';

import { errorMessages } from 'api/errors/messages';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useDeleteUser } from 'hooks/users/useDeleteUser/useDeleteUser';
import { useGetUser } from 'hooks/users/useGetUser/useGetUser';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { UserSummaryTable } from 'ui/userSummaryTable/UserSummaryTable';

import { UsersDeleteForm } from './usersDeleteForm/UsersDeleteForm';
import * as styles from './UsersDelete.styles';

export const UsersDelete = () => {
  const translate = useTranslator();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const queryGetUser = useGetUser(id);

  const confirmFormAbort = useConfirmFormAbort();

  const userErrorMessage: string[] = queryGetUser.error?.response?.data?.errors || [];
  const isInvalidUserId = userErrorMessage.includes(errorMessages.user.invalidId);
  const doesUserNotExit = userErrorMessage.includes(errorMessages.user.doesNotExist);
  const hasRoles = queryGetUser.data && queryGetUser.data.roles.length > 0;
  const notShowUserView = hasRoles || doesUserNotExit || isInvalidUserId;

  const backToUsersList = () => {
    history.push(AppRoute.users);
  };

  useEffect(() => {
    if (notShowUserView) {
      if (hasRoles) {
        toast.error(translate('CONSTRAINT.VALIDATION.USER_HAS_ROLES'));
      } else if (doesUserNotExit) {
        toast.error(translate('CONSTRAINT.VALIDATION.USER_DOES_NOT_EXIST'));
      } else if (isInvalidUserId) {
        toast.error(translate('CONSTRAINT.VALIDATION.INVALID_USER_ID'));
      }

      backToUsersList();
    }
  }, [notShowUserView]);

  const mutationDeleteUser = useDeleteUser();

  const handleSubmitDeleteUser = useCallback(() => {
    mutationDeleteUser.mutate(id, {
      onSuccess: () => {
        confirmFormAbort.disable();
        history.push(AppRoute.users);
        toast.success(translate('usersDelete.successResponse'));
      },
      onError: () => {
        toast.error(translate('usersDelete.errorResponse'));
      },
    });
  }, [mutationDeleteUser.mutate]);

  const renderFormBody = () => {
    if (queryGetUser.data) {
      return (
        <>
          <UserSummaryTable data={queryGetUser.data} options={{ showLogin: true }} />

          <Box mt={2}>
            <UsersDeleteForm
              error={mutationDeleteUser.error}
              isLoading={mutationDeleteUser.isLoading}
              onSubmit={handleSubmitDeleteUser}
            />
          </Box>
        </>
      );
    }

    if (queryGetUser.isError) {
      return (
        <Box mt={2}>
          <Alert severity="error">{translate('global.errorMessage')}</Alert>
        </Box>
      );
    }

    return <CircularProgress sx={styles.loader} data-testid="users-delete-loader" />;
  };

  return (
    <>
      <Box mb={2}>
        <Typography component="h2" variant="h5" sx={styles.pageTitle}>
          {translate('usersDelete.pageTitle')} {queryGetUser.data?.firstName} {queryGetUser.data?.lastName}
        </Typography>
      </Box>

      {renderFormBody()}
    </>
  );
};
