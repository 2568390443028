import { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Toolbar, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { DateTimePicker } from 'ui/dateTimePicker/DateTimePicker';
import { FilterField } from 'api/actions/loginLogs/loginLogs.enum';
import { Filters } from 'api/actions/loginLogs/loginLogs.types';
import { FiltersPopover } from 'ui/table/toolbar/filtersPopover/FiltersPopover';
import { TextField } from 'ui/textField/TextField';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { useFiltersDataSchema } from './FiltersDataSchema';
import { LoginLogsTableToolbarProps, PopoverRef } from './LoginLogsTableToolbar.types';
import { LoginLogsTableToolbarActiveFilters } from './loginLogsTableToolbarActiveFilters/LoginLogsTableToolbarActiveFilters';

export const LoginLogsTableToolbar = ({ filters, onPageChange, onSubmitFilters }: LoginLogsTableToolbarProps) => {
  const translate = useTranslator();

  const filtersDataSchema = useFiltersDataSchema();

  const methods = useForm<Filters>({
    resolver: yupResolver(filtersDataSchema),
    mode: 'onChange',
  });

  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = methods;

  const createdAtDateTimeFrom = watch(FilterField.createdAtDateTimeFrom);
  useEffect(() => {
    if (errors?.createdAtDateTimeTo) {
      trigger([FilterField.createdAtDateTimeTo]);
    }
  }, [createdAtDateTimeFrom]);

  const popoverRef = useRef<PopoverRef>();

  const onSubmitFilterForm = (data: Filters) => {
    onSubmitFilters(data);
    popoverRef?.current?.handleCloseFiltersPopover();
  };

  const resetFiltersFormValuesToSubmitted = useCallback(() => {
    const filtersFields = Object.keys(filters) as FilterField[];

    filtersFields.forEach((key) => {
      setValue(key, filters[key]);
    });

    clearErrors();
  }, [filters, setValue]);

  useEffect(() => {
    resetFiltersFormValuesToSubmitted();
  }, [filters, resetFiltersFormValuesToSubmitted]);

  return (
    <Toolbar sx={styles.toolbar} style={{ justifyContent: 'flex-end' }}>
      <FiltersPopover TransitionProps={{ onExited: resetFiltersFormValuesToSubmitted }} ref={popoverRef}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitFilterForm)} noValidate autoComplete="off">
            <Box padding={2}>
              <Typography paragraph>{translate('global.filtersTitle')}</Typography>

              <Box sx={styles.filterInput}>
                <DateTimePicker<Filters>
                  label={translate('global.dateTimeFrom')}
                  name={FilterField.createdAtDateTimeFrom}
                  textFieldProps={{ sx: styles.datePicker }}
                />
              </Box>
              <Box sx={styles.filterInput}>
                <DateTimePicker<Filters>
                  label={translate('global.dateTimeTo')}
                  name={FilterField.createdAtDateTimeTo}
                  textFieldProps={{ sx: styles.datePicker }}
                />
              </Box>
              <Box>
                <TextField<Filters>
                  name={FilterField.login}
                  label={translate('global.login')}
                  size="small"
                  sx={styles.filterInput}
                />
              </Box>
              <Box>
                <TextField<Filters>
                  name={FilterField.ipAddress}
                  label={translate('global.ipAddress')}
                  size="small"
                  sx={styles.filterInput}
                />
              </Box>
              <Box>
                <TextField<Filters>
                  name={FilterField.origin}
                  label={translate('loginLogs.origin')}
                  size="small"
                  sx={styles.filterInput}
                />
              </Box>
              <Box>
                <TextField<Filters>
                  name={FilterField.destination}
                  label={translate('loginLogs.destination')}
                  size="small"
                  sx={styles.filterInput}
                />
              </Box>
              <Button type="submit" variant="contained">
                {translate('global.filtersSubmit')}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </FiltersPopover>

      <LoginLogsTableToolbarActiveFilters
        filters={filters}
        onPageChange={onPageChange}
        onSubmitFilterForm={onSubmitFilterForm}
      />
    </Toolbar>
  );
};
