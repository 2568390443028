export enum Permission {
  superAdministrator = 'super_administrator',
  userListFull = 'user_list_full',
  userListInstitution = 'user_list_institution',
  userManagement = 'user_management',
  roleListFull = 'role_list_full',
  institutionPasswordsManagement = 'institution_passwords_management',
  institutionUserManagement = 'institution_user_management',
  institutionsList = 'institutions_list',
  eventLog = 'event_log',
  passwordChange = 'password_change',
  passwordsManagement = 'passwords_management',
  ignoreRolesBlacklist = 'ignore_roles_blacklist',
  loginLog = 'login_log',
}

export enum FilterField {
  institution = 'institution',
  role = 'role',
  status = 'status',
  lastLoginDateFrom = 'lastLoginDateFrom',
  lastLoginDateTo = 'lastLoginDateTo',
  neverLogged = 'neverLogged',
}

export enum StatusName {
  active = 'active',
  blocked = 'blocked',
  removed = 'removed',
}
