import { ToastContainer as ToastifyContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const ToastContainer = () => {
  return (
    <ToastifyContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};
