import { Styles } from '@typings/common';

export const changePasswordForm: Styles = {
  width: '36rem',
  maxWidth: '100%',
  my: 3,
  mx: 'auto',
  pt: 2,
  px: 2,
  pb: 4,
};

export const formTitle: Styles = {
  mt: 2,
  mb: 4,
  textAlign: 'center',
  fontWeight: 500,
};
