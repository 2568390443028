import { Route, Redirect, useLocation } from 'react-router-dom';
import { useIsFetching } from 'react-query';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useDefaultRoute } from 'hooks/useDefaultRoute/useDefaultRoute';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { AppRoute } from '../AppRoute.enum';
import { Loader } from 'ui/loader/Loader';
import { Queries } from 'api/queries/queries.enum';

import { AuthorizedRouteProps } from './AuthorizedRoute.types';

export const AuthorizedRoute = (props: AuthorizedRouteProps) => {
  const { component: Component, allowedPermissions, ...otherProps } = props;

  const { isAuthorized, isAuthorizing } = useAuthState();
  const location = useLocation();

  const defaultRoute = useDefaultRoute();

  const isLocationPasswordChange = location.pathname === AppRoute.passwordChange;

  const { hasAccess, hasAccessOnlyPasswordChange } = usePermissions();
  const isFetchingUserMe = useIsFetching([Queries.getUserMe]);
  const access = hasAccess(allowedPermissions);

  if (!Component) {
    return null;
  }

  if (isAuthorizing) {
    return <Loader />;
  }

  if (isAuthorized) {
    const shouldRedirectToPasswordChange =
      hasAccessOnlyPasswordChange && !isLocationPasswordChange && !isFetchingUserMe;

    if (shouldRedirectToPasswordChange) {
      return <Redirect to={AppRoute.passwordChange} />;
    }

    if (!access) {
      return <Redirect to={defaultRoute} />;
    }
  }

  return (
    <Route
      {...otherProps}
      render={(props) =>
        isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: AppRoute.login, state: { from: props.location.pathname } }} />
        )
      }
    />
  );
};
