import React from 'react';
import ReactDOM from 'react-dom';

import { clearLogs, passLogsToMonitoring } from 'utils/queryClient';
import { getAppConfig, loadAppConfiguration } from 'utils/config';
import { sentryInitializer } from 'utils/helpers/sentryInitializer/sentryInitializer';
import { AppProviders } from 'providers/AppProviders';

import { App } from './app/App';
import * as serviceWorker from './serviceWorker';

import 'assets/styles/main.css';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const render = () => {
  ReactDOM.render(
    <AppProviders>
      <App />
    </AppProviders>,
    document.getElementById('root'),
  );
};

loadAppConfiguration(`${process.env?.PUBLIC_URL}/config.json`).then(() => {
  const isSentryInConfig = !!getAppConfig('SENTRY_DSN');

  if (isSentryInConfig) {
    sentryInitializer();
  }

  if (process.env.NODE_ENV === 'production') {
    if (isSentryInConfig) {
      passLogsToMonitoring();
    } else {
      clearLogs();
    }
  }

  render();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
