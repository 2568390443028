import { SnakeCasedProperties } from 'type-fest';

import { Pagination } from 'api/actions/actions.types';

export type RolesPayload = {
  pageNumber?: number;
  itemsPerPage?: number;
  searchPhrase?: string;
};

export type RolesParams = SnakeCasedProperties<RolesPayload>;

export type Role = {
  id: string;
  builtIn: boolean;
  creationDate: string | null;
  key: BuiltInRoles | string;
  name: string;
};

export enum BuiltInRoles {
  institutionAdministrator = 'institution_administrator',
  superAdministrator = 'super_administrator',
  umsAdministrator = 'ums_administrator',
}

export type Roles = Role[];

export type RolesResponse = Pagination & {
  items: Roles;
};
