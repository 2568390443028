import parseHtml from 'html-react-parser';
import { TableCell } from '@mui/material';

import { useFormatEvent } from 'hooks/format/useFormatEvent/useFormatEvent';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { BulkActionType, EventType } from 'api/actions/eventLogs/eventLogs.enum';
import { EventContext } from 'api/actions/eventLogs/eventLogs.types';
import * as styles from 'ui/table/Table.styles';

import { BulkActionEventType, EventObjectCellProps } from './EventObjectCell.types';

export const EventObjectCell = ({ context, eventType }: EventObjectCellProps) => {
  const translate = useTranslator();

  const { formatEventContextValues } = useFormatEvent();

  const formatEventContext = (eventType: EventType, context: EventContext): string => {
    const values = formatEventContextValues(context);

    const bulkActionEventTypeLabels: Record<BulkActionType, Record<BulkActionEventType, string>> = {
      [BulkActionType.usersImport]: {
        [EventType.bulkActionStarted]: translate('eventContext.bulkActionStarted', values),
        [EventType.bulkActionFinished]: translate('eventContext.bulkActionFinished', values),
        [EventType.bulkActionFailed]: translate('eventContext.bulkActionUsersImportFailed', values),
      },
      [BulkActionType.roleAssign]: {
        [EventType.bulkActionStarted]: translate('eventContext.bulkActionStarted', values),
        [EventType.bulkActionFinished]: translate('eventContext.bulkActionFinished', values),
        [EventType.bulkActionFailed]: translate('eventContext.bulkActionFailed', values),
      },
      [BulkActionType.roleDetach]: {
        [EventType.bulkActionStarted]: translate('eventContext.bulkActionStarted', values),
        [EventType.bulkActionFinished]: translate('eventContext.bulkActionFinished', values),
        [EventType.bulkActionFailed]: translate('eventContext.bulkActionFailed', values),
      },
      [BulkActionType.usersRemove]: {
        [EventType.bulkActionStarted]: translate('eventContext.bulkActionStarted', values),
        [EventType.bulkActionFinished]: translate('eventContext.bulkActionFinished', values),
        [EventType.bulkActionFailed]: translate('eventContext.bulkActionFailed', values),
      },
      [BulkActionType.usersStatusChange]: {
        [EventType.bulkActionStarted]: translate('eventContext.bulkActionStarted', values),
        [EventType.bulkActionFinished]: translate('eventContext.bulkActionFinished', values),
        [EventType.bulkActionFailed]: translate('eventContext.bulkActionFailed', values),
      },
    };

    const eventTypeLabels: Record<Exclude<EventType, BulkActionEventType>, string> = {
      [EventType.userActivated]: translate('eventContext.userActivated', values),
      [EventType.userCreated]: translate('eventContext.userCreated', values),
      [EventType.userEdited]: translate('eventContext.userEdited', values),
      [EventType.userRemoved]: translate('eventContext.userRemoved', values),
      [EventType.userBlocked]: translate('eventContext.userBlocked', values),
      [EventType.roleAssignedToUser]: translate('eventContext.roleAssignedToUser', values),
      [EventType.roleDetachedFromUser]: translate('eventContext.roleDetachedFromUser', values),
      [EventType.userStartupPasswordChanged]: translate('eventContext.userStartupPasswordChanged', values),
      [EventType.userPasswordChanged]: translate('eventContext.userPasswordChanged', values),
      [EventType.userPasswordReset]: translate('eventContext.userPasswordReset', values),
    };

    if (
      eventType === EventType.bulkActionStarted ||
      eventType === EventType.bulkActionFinished ||
      eventType === EventType.bulkActionFailed
    ) {
      const bulkActionType = context.bulkActionType;

      if (bulkActionType) {
        const eventTypeLabels = bulkActionEventTypeLabels[bulkActionType];

        return eventTypeLabels[eventType];
      }
    } else {
      return eventTypeLabels[eventType];
    }

    return eventType;
  };

  return <TableCell sx={styles.eventObjectCell}>{parseHtml(formatEventContext(eventType, context))}</TableCell>;
};
