import * as Sentry from '@sentry/browser';
import { QueryClient, setLogger } from 'react-query';

export const queryClient = new QueryClient();

export const passLogsToMonitoring = () => {
  setLogger({
    log: (message) => {
      Sentry.captureMessage(message);
    },
    warn: (message) => {
      Sentry.captureMessage(message);
    },
    error: (error) => {
      Sentry.captureException(error);
    },
  });
};

export const clearLogs = () => {
  setLogger({
    log: () => {},
    warn: () => {},
    error: () => {},
  });
};
