import { useState } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { permissions } from 'utils/permissions';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { AuthorizedSection } from 'hoc/authorizedSection/AuthorizedSection';
import { ProfileTab } from 'app/profile/Profile.enum';

import { DrawerMenuProps, MenuListItem, UserMenuListItem } from './DrawerMenu.types';
import * as styles from './DrawerMenu.styles';

export const DrawerMenu = ({ drawerOpen, handleToggleDrawer }: DrawerMenuProps) => {
  const translate = useTranslator();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const { user } = useAuthState();
  const { hasAccessOnlyPasswordChange } = usePermissions();
  const [userOpen, setUserOpen] = useState(false);

  const menuListItems: MenuListItem[] = [
    {
      route: AppRoute.users,
      title: translate('drawerMenu.users'),
      icon: <PeopleIcon />,
      allowedPermissions: permissions.usersListView,
    },
    {
      route: AppRoute.roles,
      title: translate('drawerMenu.roles'),
      icon: <AssignmentIcon />,
      allowedPermissions: permissions.rolesListView,
    },
    {
      route: AppRoute.institutions,
      title: translate('drawerMenu.institutions'),
      icon: <HomeIcon />,
      allowedPermissions: permissions.institutionsListView,
    },
    {
      route: AppRoute.eventLogs,
      title: translate('drawerMenu.eventLogs'),
      icon: <EventNoteIcon />,
      allowedPermissions: permissions.eventLogsView,
    },
    {
      route: AppRoute.loginLogs,
      title: translate('drawerMenu.loginLogs'),
      icon: <LockOpenIcon />,
      allowedPermissions: permissions.loginLogsView,
    },
  ];

  const userMenuItems: UserMenuListItem[] = [
    {
      route: generatePath(AppRoute.profile, { tab: ProfileTab.data }) as AppRoute,
      title: translate('drawerMenu.profile'),
      target: '_blank',
    },
    {
      route: AppRoute.logout,
      title: translate('global.logout'),
      icon: <PowerSettingsNewIcon />,
    },
  ];

  const handleToggleUserMenu = () => {
    setUserOpen((prev) => !prev);
  };

  if (!user) return null;

  return (
    <SwipeableDrawer
      open={drawerOpen}
      onOpen={handleToggleDrawer(true)}
      onClose={handleToggleDrawer(false)}
      sx={styles.drawer(drawerOpen)}
      PaperProps={{
        sx: styles.drawerPaper(drawerOpen),
      }}
      variant={matches ? 'permanent' : 'temporary'}
      data-testid="drawer-menu"
    >
      <Box sx={styles.userBox} display="flex" flexDirection="column" marginBottom={userOpen ? 4 : 0}>
        <Box sx={styles.user} display="flex" alignItems="center" onClick={handleToggleUserMenu}>
          <Avatar />
          <Typography sx={styles.userFullName}>
            {user.firstName} {user.lastName}
          </Typography>
        </Box>

        {!hasAccessOnlyPasswordChange && (
          <>
            <Collapse in={userOpen} timeout="auto" unmountOnExit>
              <Divider />
              <List>
                {userMenuItems.map(({ icon, route, target, title }) => (
                  <ListItem
                    key={title}
                    button
                    component={NavLink}
                    to={route}
                    onClick={handleToggleDrawer(false)}
                    target={target}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Divider />
          </>
        )}
      </Box>

      {!hasAccessOnlyPasswordChange && (
        <>
          {userOpen && !matches && <Divider />}

          <List>
            {menuListItems.map(({ route, title, icon, allowedPermissions }) => (
              <AuthorizedSection allowedPermissions={allowedPermissions} key={title}>
                <ListItem
                  button
                  component={NavLink}
                  to={route}
                  activeClassName="active"
                  onClick={!matches ? handleToggleDrawer(!drawerOpen) : undefined}
                  sx={styles.navLink}
                >
                  <Tooltip title={title} aria-label={title} arrow>
                    <ListItemIcon>{icon}</ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={title} />
                </ListItem>
              </AuthorizedSection>
            ))}
          </List>
        </>
      )}

      <Box sx={styles.drawerFooter}>
        <IconButton
          onClick={handleToggleDrawer(!drawerOpen)}
          sx={styles.drawerFooterIcon}
          size="small"
          aria-label={drawerOpen ? translate('drawerMenu.closeDrawerMenu') : translate('drawerMenu.openDrawerMenu')}
        >
          {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
    </SwipeableDrawer>
  );
};
