import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getUser } from 'api/actions/users/usersActions';
import { Queries } from 'api/queries/queries.enum';
import { UserResponse } from 'api/actions/users/usersActions.types';
import { errorMessages } from 'api/errors/messages';
import { ExecutionType } from '@typings/common';

import { Value } from './useGetUser.types';

export const useGetUser = <T extends ExecutionType>(
  id: T extends ExecutionType.query ? string : undefined,
  executionType?: T,
) => {
  const listenedErrors = [errorMessages.user.doesNotExist, errorMessages.user.invalidId];

  const mutationGetUser = useMutation<UserResponse, AxiosError, string>(getUser);

  const queryGetUser = useQuery<UserResponse, AxiosError>([Queries.getUser, id], () => getUser(id as string), {
    keepPreviousData: true,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: (failureCount, error) => {
      const serverErrors = error?.response?.data?.errors;

      return serverErrors && !listenedErrors.some((errorMessage) => serverErrors?.includes(errorMessage));
    },
    cacheTime: 0,
  });

  if (executionType === ExecutionType.mutation) {
    return mutationGetUser as Value<T>;
  } else {
    return queryGetUser as Value<T>;
  }
};
