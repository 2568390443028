import { Styles } from '@typings/common';

export const loginForm: Styles = {
  width: '36rem',
  maxWidth: '100%',
  pt: 2,
  pb: 4,
  px: 2,
  my: { md: 3 },
  mx: { md: 'auto' },
};

export const formTitle: Styles = {
  mt: 2,
  mb: 4,
  mx: 0,
  textAlign: 'center',
  fontWeight: 500,
};

export const formSubTitle: Styles = {
  mb: 2,
  fontWeight: 500,
};

export const textField: Styles = {
  mb: 2,
};

export const passwordRemind: Styles = {
  color: 'primary.main',
  textDecoration: 'none',
};

export const errorMessage: Styles = {
  mb: 1,
  fontWeight: 500,
};
