import { Styles } from '@typings/common';

export const newPasswordForm: Styles = {
  display: 'flex',
  flexDirection: 'column',
  width: '36rem',
  maxWidth: '100%',
  my: 3,
  mx: 'auto',
  pt: 2,
  px: 2,
  pb: 4,
};

export const formTitle: Styles = {
  mt: 2,
  mb: 4,
  textAlign: 'center',
  fontWeight: 500,
};

export const formSubTitle: Styles = {
  mb: 2,
  fontWeight: 500,
};

export const textField: Styles = {
  mb: 2,
};

export const buttonBox: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const backButton: Styles = {
  mr: 1,
};

export const serverErrorsList: Styles = {
  mb: 2,
};
