import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { changeEmail } from 'api/actions/email/email';
import { ChangeEmailPayload } from 'api/actions/email/email.types';

export const useChangeEmail = () => {
  const mutationChangeEmail = useMutation<void, AxiosError, ChangeEmailPayload>(changeEmail);

  return mutationChangeEmail;
};
