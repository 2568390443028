import { endpoints } from 'api/endpoints/endpoints';
import { umsApi } from 'api/axios/axiosInstance';

import { MarkNotificationAsReadPayload, NotificationsResponse } from './notificationsActions.types';

export const getNotifications = async (): Promise<NotificationsResponse> => {
  const response = await umsApi.get(endpoints.notifications.getAll);

  return response.data;
};

export const markNotificationAsRead = async (payload: MarkNotificationAsReadPayload): Promise<void> => {
  const { id } = payload;

  await umsApi.post(endpoints.notifications.markAsRead(id));
};
