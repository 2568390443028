import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getUserMe } from 'api/actions/users/usersActions';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { AuthModeName } from 'context/auth/authContext/AuthContext.enum';
import { ExecutionType } from '@typings/common';
import { Queries } from 'api/queries/queries.enum';
import { UserMeResponse } from 'api/actions/users/usersActions.types';

import { Value } from './useGetUserMe.types';

export const useGetUserMe = <T extends ExecutionType>({
  executionType,
}: {
  enabled?: boolean;
  executionType?: T;
}): Value<T> => {
  const mutationGetUserMe = useMutation<UserMeResponse, AxiosError, void>(getUserMe);

  const { isAuthorized, authMode } = useAuthState();

  const queryEnabled = isAuthorized && authMode?.name === AuthModeName.default;

  const queryGetUserMe = useQuery<UserMeResponse, AxiosError>(Queries.getUserMe, getUserMe, {
    cacheTime: 0,
    enabled: queryEnabled,
    retry: false,
  });

  if (executionType === ExecutionType.mutation) {
    return mutationGetUserMe as Value<T>;
  } else {
    return queryGetUserMe as Value<T>;
  }
};
