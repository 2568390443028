import { PickersLocaleText } from '@mui/x-date-pickers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPickersLocalization = (pickersTranslations: Partial<PickersLocaleText<any>>) => {
  return {
    components: {
      MuiLocalizationProvider: {
        defaultProps: {
          localeText: { ...pickersTranslations },
        },
      },
    },
  };
};
