import { Styles } from '@typings/common';

export const loaderCell: Styles = {
  border: 'unset',
};

export const loader: Styles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  m: 'auto',
};
