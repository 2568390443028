import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { errorMessages } from 'api/errors/messages';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';
import { PasswordActionMessage } from 'ui/password/passwordActionMessage/PasswordActionMessage';
import { TextField } from 'ui/textField/TextField';

import { usePasswordNewFormData } from './PasswordNewFormDataSchema';
import { PasswordNewFormData, PasswordNewFormFields, PasswordNewFormProps } from './PasswordNewForm.types';
import * as styles from './PasswordNewForm.styles';

export const PasswordNewForm = ({ error, isError, isSuccess, onSubmit, passwordSyntax }: PasswordNewFormProps) => {
  const translate = useTranslator();
  const passwordNewFormDataSchema = usePasswordNewFormData(passwordSyntax);

  const methods = useForm<PasswordNewFormData>({
    resolver: yupResolver(passwordNewFormDataSchema),
    mode: 'onChange',
  });

  const {
    formState: { isSubmitting, touchedFields },
    handleSubmit,
    setError,
    trigger,
    watch,
  } = methods;

  const newPassword = watch(PasswordNewFormFields.newPassword);
  const newPasswordRepeated = watch(PasswordNewFormFields.newPasswordRepeated);
  const isAnyPasswordTouched =
    touchedFields[PasswordNewFormFields.newPassword] || touchedFields[PasswordNewFormFields.newPasswordRepeated];

  useEffect(() => {
    if (isAnyPasswordTouched) {
      trigger(PasswordNewFormFields.newPasswordRepeated);
    }
  }, [newPassword]);

  useEffect(() => {
    if (isAnyPasswordTouched) {
      trigger(PasswordNewFormFields.newPassword);
    }
  }, [newPasswordRepeated]);

  const serverErrors: string[] | Record<string, string> = error?.response?.data?.errors;
  const [isServerErrorsListVisible, setIsServerErrorsListVisible] = useState(false);

  const handleSubmitCallback = useCallback(
    async (body: PasswordNewFormData) => {
      await onSubmit(body);
    },
    [onSubmit],
  );

  useEffect(() => {
    if (isError) {
      const errorType = 'server';

      setIsServerErrorsListVisible(true);

      if (!Array.isArray(serverErrors)) {
        return;
      }

      if (serverErrors.includes(errorMessages.password.passwordUsed)) {
        setIsServerErrorsListVisible(false);

        setError(PasswordNewFormFields.newPassword, {
          type: errorType,
          message: translate('PASSWORD.HISTORY.VALIDATION.USER.PASSWORD.USED'),
        });
      }
    }
  }, [isError]);

  if (isSuccess) {
    return (
      <PasswordActionMessage
        title={translate('passwordNew.successTitle')}
        description={translate('passwordNew.successDescription')}
        icon={CheckCircleIcon}
        iconColor="primary"
        buttonText={translate('passwordNew.successButton')}
        buttonLink={AppRoute.login}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <Paper component="form" onSubmit={handleSubmit(handleSubmitCallback)} sx={styles.newPasswordForm}>
        <Typography variant="body1" component="h2" sx={styles.formTitle}>
          {translate('passwordNew.formTitle')}
        </Typography>

        <Typography variant="h5" component="h2" sx={styles.formSubTitle}>
          {translate('passwordNew.formSubTitle')}
        </Typography>

        <TextField<PasswordNewFormData>
          type="password"
          name={PasswordNewFormFields.newPassword}
          label={translate('global.newPassword')}
          sx={styles.textField}
        />

        <TextField<PasswordNewFormData>
          type="password"
          name={PasswordNewFormFields.newPasswordRepeated}
          label={translate('global.newPasswordRepeated')}
          sx={styles.textField}
        />

        {isServerErrorsListVisible && <FormServerErrors serverErrors={serverErrors} sx={styles.serverErrorsList} />}

        <Box sx={styles.buttonBox}>
          <Button to={AppRoute.login} component={RouterLink} sx={styles.backButton} color="inherit">
            {translate('passwordNew.cancelNewPassword')}
          </Button>
          <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
            {translate('passwordNew.submitNewPassword')}
          </Button>
        </Box>
      </Paper>
    </FormProvider>
  );
};
