import { useEffect, useState, ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Button, MenuItem, Paper } from '@mui/material';
import get from 'lodash.get';

import { errorMessages } from 'api/errors/messages';
import { useBindingKey } from 'hooks/useBindingKey/useBindingKey';
import { useCancelForm } from 'hooks/useCancelForm/useCancelForm';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useFormatUserStatus } from 'hooks/format/useFormatUserStatus/useFormatUserStatus';
import { ConfirmDialog } from 'ui/confirmDialog/ConfirmDialog';
import { FormFields } from '../UsersForm.enum';
import { Select } from 'ui/select/Select';
import { StatusName } from 'api/actions/users/usersActions.enum';
import { TextField } from 'ui/textField/TextField';
import { UsersFormData } from '../UsersForm.types';
import * as styles from '../UsersForm.styles';

import { UsersFormPersonalDataProps } from './UsersFormPersonalData.types';

export const UsersFormPersonalData = ({
  isEditForm,
  isUpdateError,
  updateError,
  onClickNext,
  isCurrentlyLoggedUser,
}: UsersFormPersonalDataProps) => {
  const translate = useTranslator();

  const formatUserStatus = useFormatUserStatus();

  const { areMultipleBindingKeys, bindingKeys, bindingKeyValueLabel } = useBindingKey();

  const [isConfirmChangeStatusDialogVisible, setIsConfirmChangeStatusDialogVisible] = useState(false);

  const { cancelForm } = useCancelForm();

  const {
    clearErrors,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (isUpdateError) {
      const errorType = 'server';
      const serverErrors: string[] | Record<string, string> = updateError?.response?.data?.errors || [];
      const shouldCheckServerErrors = Array.isArray(serverErrors) && serverErrors.length > 0;

      if (shouldCheckServerErrors) {
        serverErrors.forEach((serverError) => {
          if (serverError.includes(errorMessages.user.bindingKeyNotUnique)) {
            setError(FormFields.bindingKeyValue, {
              type: errorType,
              message: translate('usersForm.userBindingKeyUnique', {
                bindingKey: areMultipleBindingKeys ? translate('global.bindingKeyValue') : bindingKeyValueLabel,
              }),
            });
          }

          if (serverError.includes(errorMessages.user.bindingKeyInavlidSyntax)) {
            setError(FormFields.bindingKeyValue, {
              type: errorType,
              message: translate('usersForm.userBindingKeyInvalidSyntax'),
            });
          }
        });
      }
    }
  }, [isUpdateError]);

  const handleChangeBindingKeyKey = (e: ChangeEvent<HTMLInputElement>) => {
    if (errors[FormFields.bindingKey]) {
      clearErrors(FormFields.bindingKey);
    }

    setValue(FormFields.bindingKeyKey, e.target.value);
  };

  const handleCloseChangeStatusDialog = () => {
    setIsConfirmChangeStatusDialogVisible(false);
  };

  const handleConfirmChangeStatusDialog = () => {
    setValue(FormFields.status, StatusName.blocked);
    setIsConfirmChangeStatusDialogVisible(false);
  };

  const handleChangeStatus = (event: { target: { value: string } }) => {
    const currentStatus = event.target.value as StatusName;
    const isCurrentStatusBlocked = currentStatus === StatusName.blocked;

    if (isEditForm && isCurrentStatusBlocked) {
      setIsConfirmChangeStatusDialogVisible(true);
    } else {
      setValue(FormFields.status, currentStatus);
    }
  };

  const readonlyTextFieldSx = { ...styles.textField, ...(isEditForm ? styles.readOnly : {}) };

  return (
    <>
      {isEditForm && (
        <ConfirmDialog
          id="confirm-status-dialog"
          description={translate('usersEdit.statusChangeConfirmDialogDescription')}
          onCancel={handleCloseChangeStatusDialog}
          onConfirm={handleConfirmChangeStatusDialog}
          show={isConfirmChangeStatusDialogVisible}
          title={translate('usersEdit.statusChangeConfirmDialogTitle')}
        />
      )}

      <form>
        <Paper sx={styles.step}>
          <TextField<UsersFormData>
            name={FormFields.lastName}
            label={translate('global.lastName')}
            sx={styles.textField}
          />

          <TextField<UsersFormData>
            name={FormFields.firstName}
            label={translate('global.firstName')}
            sx={styles.textField}
          />

          <TextField<UsersFormData>
            name={FormFields.email}
            label={translate('global.email')}
            inputProps={{ noValidate: 'noValidate' }}
            sx={styles.textField}
          />

          {areMultipleBindingKeys && (
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  id="bindingKey"
                  label={translate('global.bindingKeyType')}
                  sx={readonlyTextFieldSx}
                  variant="outlined"
                  inputProps={{
                    'data-testid': 'bindingKey-select',
                    'aria-label': translate('global.bindingKeyType'),
                    'aria-labelledby': 'bindingKey',
                  }}
                  onChange={handleChangeBindingKeyKey}
                  InputProps={{ readOnly: isEditForm, sx: readonlyTextFieldSx }}
                  error={!!get(errors, FormFields.bindingKeyKey)?.message}
                  helperText={get(errors, FormFields.bindingKeyKey)?.message}
                  select
                >
                  {bindingKeys?.map(({ key, label }) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={FormFields.bindingKeyKey}
              control={control}
            />
          )}

          <TextField<UsersFormData>
            name={FormFields.bindingKeyValue}
            label={bindingKeyValueLabel}
            sx={readonlyTextFieldSx}
            InputProps={{ readOnly: isEditForm }}
          />

          {isEditForm && (
            <TextField<UsersFormData>
              name={FormFields.login}
              label={translate('global.login')}
              sx={readonlyTextFieldSx}
              InputProps={{ readOnly: true }}
            />
          )}

          <Controller
            render={({ field }) => (
              <Select
                {...field}
                id="status"
                label={translate('global.status')}
                sx={styles.textField}
                variant="outlined"
                inputProps={{
                  'data-testid': 'status-select',
                  'aria-label': translate('global.status'),
                  'aria-labelledby': 'status',
                }}
                onChange={(event) => handleChangeStatus(event)}
                disabled={isCurrentlyLoggedUser}
              >
                <MenuItem value={StatusName.active}>{formatUserStatus(StatusName.active)}</MenuItem>
                <MenuItem value={StatusName.blocked}>{formatUserStatus(StatusName.blocked)}</MenuItem>
              </Select>
            )}
            name="status"
            control={control}
          />
        </Paper>

        <Box sx={styles.buttonBox}>
          <Button onClick={cancelForm} color="inherit">
            {translate('global.buttonCancel')}
          </Button>

          <Button onClick={onClickNext} variant="contained" color="primary">
            {translate('global.buttonNext')}
          </Button>
        </Box>
      </form>
    </>
  );
};
