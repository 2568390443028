import { RouteProps } from 'react-router-dom';
import { AxiosError } from 'axios';

import { ChangeEmailPayload } from 'api/actions/email/email.types';

export type ProfileDataRef = {
  handleResetForm(): void;
};

export type ProfileDataProps = RouteProps & {
  error: AxiosError | null;
  onSubmit: (body: ChangeEmailPayload) => Promise<boolean>;
};

export enum FormFields {
  email = 'email',
}

export type ProfileDataForm = {
  [FormFields.email]: string;
};
