import yup from 'utils/yup';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const useLoginFormSchema = () => {
  const translate = useTranslator();

  return yup.object().shape({
    login: yup.string().required(translate('global.fieldRequired')),
    password: yup.string().required(translate('global.fieldRequired')),
  });
};
