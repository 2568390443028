type InitRecaptchaObserver = (onHide: () => void) => void;

export const initRecaptchaObserver: InitRecaptchaObserver = (onHide) => {
  const iframes = document.getElementsByTagName('iframe');

  const recaptchaIframeWrapper = [...iframes].find((iframe) => iframe.src.includes('google.com/recaptcha/api2/bframe'))
    ?.parentNode?.parentNode;

  if (!recaptchaIframeWrapper) {
    return;
  }

  if (recaptchaIframeWrapper instanceof HTMLElement) {
    new MutationObserver(() => {
      if (
        recaptchaIframeWrapper.style.visibility !== 'visible' ||
        recaptchaIframeWrapper.style.opacity !== '1' ||
        recaptchaIframeWrapper.style.top !== '10px'
      ) {
        onHide();
      }
    }).observe(recaptchaIframeWrapper, {
      attributeFilter: ['style'],
    });
  }
};
