import { Box, Chip } from '@mui/material';

import { hasActiveFilters } from 'utils/function/hasActiveFilters/hasActiveFilters';
import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useFormatUserStatus } from 'hooks/format/useFormatUserStatus/useFormatUserStatus';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { FilterField } from 'api/actions/users/usersActions.enum';
import { InstitutionShortName } from 'api/actions/institutions/institutionsActions.types';
import { Status } from 'api/actions/users/usersActions.types';
import { Role } from 'api/actions/roles/rolesActions.types';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { ChipListItemProps, UsersTableToolbarActiveFiltersProps } from './UsersTableToolbarActiveFilters.types';

export const UsersTableToolbarActiveFilters = ({ onSubmitFilterForm }: UsersTableToolbarActiveFiltersProps) => {
  const translate = useTranslator();

  const { filters } = useUsersState();
  const { handleChangePage, handleResetSelectedRows, handleToggleAreSelectedAllOnAllPages } = useUsersMethods();

  const formatUserStatus = useFormatUserStatus();
  const { formatDate } = useFormatDate();

  const filtersFields = Object.keys(filters) as FilterField[];

  const getLabelName = (key: FilterField, property?: Role | InstitutionShortName | Status) => {
    switch (key) {
      case FilterField.role:
        return (property as Status).name;
        break;
      case FilterField.institution:
        return (property as InstitutionShortName).shortName;
        break;
      case FilterField.status:
        return formatUserStatus((property as Status).name);
        break;
      case FilterField.lastLoginDateFrom:
        return `${translate('users.filtersLastLoginDateFrom')}: ${formatDate(filters?.[key] as string)}`;
        break;
      case FilterField.lastLoginDateTo:
        return `${translate('users.filtersLastLoginDateTo')}: ${formatDate(filters?.[key] as string)}`;
        break;
      case FilterField.neverLogged:
        return `${translate('users.filtersNeverLogged')}`;
        break;
    }
  };

  const arrayFilterFields = [FilterField.role, FilterField.institution, FilterField.status];
  const isNeverLoggedFilterActive = filters[FilterField.neverLogged];

  const ChipListItem = ({ key, label, newFilterFormData }: ChipListItemProps) => (
    <li key={key}>
      <Chip
        size="small"
        label={label}
        onDelete={() => {
          onSubmitFilterForm(newFilterFormData);
          handleChangePage(null, 0);
          handleResetSelectedRows();
          handleToggleAreSelectedAllOnAllPages(false);
        }}
      />
    </li>
  );

  if (!hasActiveFilters(filters)) {
    return null;
  }

  return (
    <Box component="ul" sx={styles.chipList}>
      {filtersFields.map((key) => {
        if (filters?.[key]) {
          if (arrayFilterFields.includes(key)) {
            return (filters[key] as (Role | InstitutionShortName | Status)[])?.map((property) => {
              const newFiltersFormData = {
                ...filters,
                [key]: (filters[key] as (Role | InstitutionShortName | Status)[])?.filter(
                  ({ id }) => id !== property.id,
                ),
              };

              return (
                <ChipListItem key={key} label={getLabelName(key, property)} newFilterFormData={newFiltersFormData} />
              );
            });
          } else if (key === FilterField.neverLogged) {
            const newFiltersFormData = {
              ...filters,
              [key]: false,
              [FilterField.lastLoginDateFrom]: null,
              [FilterField.lastLoginDateTo]: null,
            };

            return <ChipListItem key={key} label={getLabelName(key)} newFilterFormData={newFiltersFormData} />;
          } else if (
            !isNeverLoggedFilterActive &&
            (key === FilterField.lastLoginDateFrom || key === FilterField.lastLoginDateTo)
          ) {
            const newFiltersFormData = { ...filters, [key]: null };

            return <ChipListItem key={key} label={getLabelName(key)} newFilterFormData={newFiltersFormData} />;
          }
        }
      })}
    </Box>
  );
};
