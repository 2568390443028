import { AxiosError } from 'axios';

import { PasswordSyntax } from 'api/actions/config/configActions.types';

export type PasswordNewFormProps = {
  error: AxiosError | null;
  isError: boolean;
  isSuccess: boolean;
  onSubmit: (body: PasswordNewFormData) => Promise<boolean>;
  passwordSyntax: PasswordSyntax;
};

export enum PasswordNewFormFields {
  newPassword = 'newPassword',
  newPasswordRepeated = 'newPasswordRepeated',
}

export type PasswordNewFormData = {
  [PasswordNewFormFields.newPassword]: string;
  [PasswordNewFormFields.newPasswordRepeated]: string;
};
