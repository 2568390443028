import { Styles } from '@typings/common';

export const row: Styles = {
  '& .MuiTableCell-root': {
    borderBottom: 'unset',
  },
};

export const nestedTable: Styles = {
  width: 'auto',
  minWidth: '50%',
  mt: { md: 2 },
  mb: { xs: 2, mb: 4 },
  mr: { xs: 2, md: 8 },
};

export const nestedCell: Styles = {
  p: 0,
  m: 0,

  '&.MuiTableCell-sizeSmall': {
    p: 0,
  },

  '& td:nth-child(1),th:nth-child(1)': {
    width: { md: '250px' },
  },

  '& td:nth-child(2),th:nth-child(2)': {
    width: { md: '150px' },
  },
};

export const slimCell: Styles = {
  p: '0.25rem',
  width: '1px',
};

export const collapseCell: Styles = {
  '&.MuiTableCell-sizeSmall:first-child': {
    pl: 0.5,
  },
};
