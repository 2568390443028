import { Controller, useFormContext } from 'react-hook-form';
import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps as MUIDateTimePickerProps,
} from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { DateTimePickerProps } from './DateTimePicker.types';
import * as styles from './DateTimePicker.styles';

export const DateTimePicker = <T,>({
  label,
  name,
  textFieldProps,
  ...props
}: DateTimePickerProps<T> & Partial<MUIDateTimePickerProps<Date, Date>>) => {
  const translate = useTranslator();
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const { ...formMethodsProps } = register(name as 'name');

  return (
    <Controller
      {...formMethodsProps}
      render={({ field }) => (
        <MUIDateTimePicker
          {...props}
          {...field}
          ampm={false}
          disableFuture={true}
          OpenPickerButtonProps={{ size: 'small', 'aria-label': translate('dateTimePicker.selectDate') }}
          componentsProps={{
            actionBar: {
              actions: ['cancel', 'clear', 'today'],
            },
          }}
          toolbarTitle={translate('dateTimePicker.selectDate')}
          inputFormat="dd-MM-yyyy HH:mm"
          mask={'__-__-____ __:__'}
          value={field.value}
          onChange={(date) => {
            field.onChange(date);
          }}
          DialogProps={{
            PaperProps: {
              sx: styles.dialog,
            },
          }}
          PopperProps={{
            sx: styles.dialog,
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              {...textFieldProps}
              size="small"
              label={label}
              placeholder={translate('dateTimePicker.placeholder')}
              error={!!errors[name as string]}
              helperText={errors[name as string]?.message}
            />
          )}
        />
      )}
      defaultValue={null}
      name={name as string}
      control={control}
    />
  );
};
