import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { Queries } from 'api/queries/queries.enum';
import { getConfig } from 'api/actions/config/configActions';
import { ConfigResponse } from 'api/actions/config/configActions.types';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { usePermissions } from 'hooks/usePermissions/usePermissions';

export const useGetConfig = () => {
  const { isAuthorized } = useAuthState();

  const { hasPermissions } = usePermissions();

  const enabled = isAuthorized && hasPermissions;

  const queryGetConfig = useQuery<ConfigResponse, AxiosError>(Queries.getConfig, getConfig, {
    keepPreviousData: true,
    enabled,
  });

  return queryGetConfig;
};
