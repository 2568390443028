import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { uploadFile } from 'api/actions/upload/upload';
import { UploadFilePayload } from 'api/actions/upload/upload.types';

export const useFileUploader = <T>() => {
  const mutationFileUploader = useMutation<T, AxiosError, UploadFilePayload>((payload) => uploadFile<T>(payload));

  return mutationFileUploader;
};
