import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';
import { toUsersEndpointFilterSearchParams } from 'utils/function/toUsersEndpointFilterSearchParams/toUsersEndpointFilterSearchParams';

import {
  CreateUserPayload,
  EditUserPayload,
  UsersPayload,
  UserResponse,
  UsersResponse,
  UserMeResponse,
  UsersParams,
} from './usersActions.types';

export const getUsers = async (payload: UsersPayload): Promise<UsersResponse> => {
  const { filters, pageNumber = 0, itemsPerPage = 30, orderBy, orderDirection, searchPhrase } = payload;

  const params: UsersParams = {
    items_per_page: itemsPerPage,
    page_number: pageNumber + 1,
    order_by: orderBy,
    order_direction: orderDirection,
    ...toUsersEndpointFilterSearchParams({ ...filters, searchPhrase }),
  };

  const response = await umsApi.get(endpoints.users.all, {
    params,
  });

  return response.data;
};

export const getUser = async (id: string): Promise<UserResponse> => {
  const response = await umsApi.get(endpoints.users.single(id));

  return response.data;
};

export const getUserMe = async (): Promise<UserMeResponse> => {
  const response = await umsApi.get(endpoints.users.me);

  return response.data;
};

export const createUser = async (values: CreateUserPayload): Promise<Pick<UserResponse, 'id'>> => {
  const response = await umsApi.post(endpoints.users.all, values);

  return response.data;
};

export const deleteUser = async (id: string): Promise<void> => {
  await umsApi.delete(endpoints.users.single(id));
};

export const editUser = async ({ id, values }: EditUserPayload): Promise<UserResponse> => {
  const response = await umsApi.put(endpoints.users.single(id), values);

  return response.data;
};

export const checkCanEditUser = async ({ id, values }: EditUserPayload): Promise<void> => {
  const response = await umsApi.post(endpoints.users.canEdit(id), values);

  return response.data;
};
