import { useEffect, useState } from 'react';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useGetConfig } from 'hooks/useGetConfig/useGetConfig';
import { useGetPasswordSyntax } from 'hooks/password/useGetPasswordSyntax/useGetPasswordSyntax';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { ConfigContext } from '../configContext/ConfigContext';
import { ConfigResponse } from 'api/actions/config/configActions.types';
import { Loader } from 'ui/loader/Loader';

import { ConfigContextControllerProps } from './ConfigContextController.types';

export const ConfigContextController = ({ children }: ConfigContextControllerProps) => {
  const [config, setConfig] = useState<ConfigResponse | null>(null);

  const { user } = useAuthState();

  const { hasPermissions } = usePermissions();

  useGetPasswordSyntax();

  const queryGetConfig = useGetConfig();

  const isConfigLoading = config == null && user && hasPermissions;

  useEffect(() => {
    if (!queryGetConfig.error && queryGetConfig.data) {
      setConfig(queryGetConfig.data);
    }

    if (user && queryGetConfig.error) {
      throw new Error('Can not fetch config.');
    }
  }, [queryGetConfig.data, queryGetConfig.error]);

  if (isConfigLoading) {
    return <Loader />;
  }

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
