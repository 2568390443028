import { CalendarPickerView, PickersLocaleText } from '@mui/x-date-pickers';

import { getPickersLocalization } from './getPickersLocalization';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const plPLPickers: PickersLocaleText<any> = {
  // Calendar navigation
  previousMonth: 'Poprzedni miesiąc',
  nextMonth: 'Następny miesiąc',

  // View navigation
  openPreviousView: 'otwórz poprzedni widok',
  openNextView: 'otwórz następny widok',
  calendarViewSwitchingButtonAriaLabel: (view: CalendarPickerView) =>
    view === 'year'
      ? 'Widok roku jest otwarty, przejdź do widoku kalendarza'
      : 'Widok kalendarza jest otwarty, przejdź do widoku roku',

  // DateRange placeholders
  start: 'Początek',
  end: 'Koniec',

  // Action bar
  cancelButtonLabel: 'Anuluj',
  clearButtonLabel: 'Wyczyść',
  okButtonLabel: 'Ok',
  todayButtonLabel: 'Dzisiaj',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `Wybierz ${view}. ${
      time === null ? 'Czas nie został wybrany' : `Wybrany czas to ${adapter.format(time, 'fullTime')}`
    }`,
  hoursClockNumberText: (hours) => `${hours} godzin`,
  minutesClockNumberText: (minutes) => `${minutes} minut`,
  secondsClockNumberText: (seconds) => `${seconds} sekund`,

  // Open picker labels
  openDatePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Wybierz datę, wybrana data to ${utils.format(utils.date(rawValue)!, 'fullDate')}`
      : 'Wybierz datę',
  openTimePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Wybierz czas, wybrany czas to ${utils.format(utils.date(rawValue)!, 'fullTime')}`
      : 'Wybierz czas',

  // Table labels
  timeTableLabel: 'zaznacz czas',
  dateTableLabel: 'zaznacz datę',
};

export const DEFAULT_LOCALE = plPLPickers;

export const plPL = getPickersLocalization(plPLPickers);
