import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';
import { getAppConfig } from 'utils/config';

import { LoginPayload } from './authActions.types';

export const loginAction = async (values: LoginPayload): Promise<void> => {
  await umsApi.post(endpoints.authentication.login, values);
};

export const logoutAction = async (): Promise<void> => {
  await umsApi.post(endpoints.authentication.logout);
};

export const beaconLogoutAction = (): void => {
  const logoutApiUrl = `${getAppConfig('API_URL')}${endpoints.authentication.logout}`;

  navigator.sendBeacon(logoutApiUrl);
};
