import { generatePath } from 'react-router-dom';

export const endpoints = {
  authentication: {
    login: '/authentication/login',
    logout: '/authentication/logout',
  },
  bulk: {
    assignRole: '/users/bulk-role-assign',
    changeStatus: '/users/bulk-change-status',
    detachRole: '/users/bulk-role-detach',
    remove: '/users/bulk-remove',
  },
  configuration: '/configuration',
  email: {
    change: '/users/me/change-email',
    confirm: 'users/confirm-email',
  },
  eventLogs: '/event_logs',
  exports: {
    start: '/exports/users',
    check: (id: string): string => generatePath('/exports/:id', { id }),
    download: (id: string): string => generatePath('/exports/:id/download', { id }),
  },
  imports: {
    users: {
      initValidation: '/imports/users',
      checkStatus: (id: string): string => generatePath('/imports/users/:id', { id }),
      validationResult: (id: string): string => generatePath('/imports/users/:id/validation-result', { id }),
      init: (id: string): string => generatePath('/imports/users/:id/init', { id }),
      importFileTemplate: '/imports/users/import-file-template',
    },
  },
  institutions: {
    fullNames: '/institutions',
    shortNames: '/institutions/short-names',
  },
  loginLogs: '/login_logs',
  notifications: {
    getAll: '/notifications',
    markAsRead: (id: string): string => generatePath('/notifications/:id', { id }),
  },
  passwords: {
    startupPassword: (id: string): string => generatePath('/users/:id/startup-password', { id }),
    reset: (id: string): string => generatePath('/users/:id/password/reset', { id }),
    change: '/users/me/password',
    remind: '/users/password/reset',
    verifyLink: '/users/password/reset-link/verify',
    setNew: '/users/password/reset-link',
    getSyntax: '/configuration/password-syntax',
  },
  roles: '/roles',
  sso: {
    login: '/sso/ws-federation',
    loggedInRealms: '/wsfed/logged-in-realms',
  },
  users: {
    all: '/users',
    single: (id: string): string => generatePath('/users/:id', { id }),
    me: '/users/me',
    canEdit: (id: string): string => generatePath('/users/:id/check-roles', { id }),
  },
};
