import { Button } from '@mui/material';
import { useMemo } from 'react';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { RestrictedSectionTooltip } from 'hoc/restrictedSectionTooltip/RestrictedSectionTooltip';

import { ResetPasswordButtonProps } from './ResetPasswordButton.types';

export const ResetPasswordButton = ({
  isCurrentlyLoggedUser,
  isUserBlacklisted,
  isUserBlocked,
  onClickResetPassword,
  canResetPassword,
}: ResetPasswordButtonProps) => {
  const translate = useTranslator();

  const isButtonDisabled = isUserBlacklisted || isUserBlocked || isCurrentlyLoggedUser || !canResetPassword;

  const tooltipTitle = useMemo(() => {
    if (isUserBlacklisted) {
      return translate('global.roleBlacklisted');
    }

    if (isUserBlocked) {
      return translate('users.buttonResetPasswordTooltipBlockedUser');
    }

    if (isCurrentlyLoggedUser) {
      return translate('users.buttonResetPasswordTooltipCurrentlyLoggedUser');
    }

    return null;
  }, [isUserBlacklisted, isUserBlocked, isCurrentlyLoggedUser]);

  return (
    <RestrictedSectionTooltip isRestricted={isButtonDisabled} title={tooltipTitle}>
      <Button disabled={isButtonDisabled} onClick={onClickResetPassword} color="primary" variant="outlined">
        {translate('users.buttonResetPassword')}
      </Button>
    </RestrictedSectionTooltip>
  );
};
