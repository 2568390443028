import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { createUser } from 'api/actions/users/usersActions';
import { CreateUserPayload, UserResponse } from 'api/actions/users/usersActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  const mutationCreateUser = useMutation<Pick<UserResponse, 'id'>, AxiosError, CreateUserPayload>(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(Queries.getUsers);
    },
  });

  return mutationCreateUser;
};
