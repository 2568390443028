import { ReactNode } from 'react';
import { TableCellProps } from '@mui/material';

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SortableTableCellProps<T> = {
  id: keyof T;
  orderBy?: keyof T;
  orderDirection: OrderDirection;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  children: ReactNode;
} & TableCellProps;
