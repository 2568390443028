import { Styles } from '@typings/common';

export const textField: Styles = {
  mb: 2,
};

export const editProfileButton: Styles = {
  alignSelf: 'flex-end',
};

export const form: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const notConfirmedEmailAdornment: Styles = {
  '& p': {
    color: 'error.main',
    fontStyle: 'italic',
  },
};

export const confirmation: Styles = {
  mb: 2,
};

export const buttonBox: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const abortButton: Styles = {
  mr: 2,
};

export const serverErrorsList: Styles = {
  mt: 2,
};
