import { Styles } from '@typings/common';

export const loader: Styles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  m: 'auto',
};

export const pageTitle: Styles = {
  wordBreak: 'break-word',
};
