import { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Alert, Button } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { UserResponse } from 'api/actions/users/usersActions.types';

import * as styles from './LastAddedUser.styles';

export const LastAddedUser = () => {
  const translate = useTranslator();

  const history = useHistory();
  const location = useLocation<{ lastAddedUser?: UserResponse | null }>();

  const lastAddedUser = location.state?.lastAddedUser;

  const [isLastAddedUserVisible, setIsLastAddedUserVisible] = useState(!!lastAddedUser);

  useEffect(() => {
    if (!lastAddedUser) {
      setIsLastAddedUserVisible(false);
    }
  }, [lastAddedUser]);

  const handleCloseLastAddedUser = (): void => {
    setIsLastAddedUserVisible(false);

    history.replace({ ...location, state: { ...location.state, lastAddedUser: null } });
  };

  if (isLastAddedUserVisible) {
    return (
      <Alert
        severity="success"
        onClose={handleCloseLastAddedUser}
        closeText={translate('global.buttonClose')}
        sx={styles.lastAddedUser}
      >
        {translate('users.lastAddedUser', {
          firstName: lastAddedUser?.firstName,
          lastName: lastAddedUser?.lastName,
          login: lastAddedUser?.login,
        })}
        <Button component={RouterLink} to={`/users/edit/${lastAddedUser?.id}`} color="primary" variant="contained">
          {translate('global.buttonEdit')}
        </Button>
      </Alert>
    );
  }

  return null;
};
