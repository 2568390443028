import { useState } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { getUnixTime } from 'date-fns';

import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useGetEventLogs } from 'hooks/useGetEventLogs/useGetEventLogs';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { DataLoading } from 'ui/table/row/dataLoading/DataLoading';
import { DataLoadingError } from 'ui/table/row/dataLoadingError/DataLoadingError';
import { NoData } from 'ui/table/row/noData/NoData';
import { OrderDirection } from '@typings/common';
import { Pagination } from 'ui/table/pagination/Pagination';
import { EventLog, EventLogWithTimestamp, Filters, FiltersPayload } from 'api/actions/eventLogs/eventLogs.types';
import { FilterField } from 'api/actions/eventLogs/eventLogs.enum';
import * as styles from 'ui/table/Table.styles';

import { EventLogsTableHeader } from './eventLogsHeader/EventLogsTableHeader';
import { EventLogsTableRow } from './eventLogsTableRow/EventLogsTableRow';
import { EventLogsTableToolbar } from './eventLogsTableToolbar/EventLogsTableToolbar';

export const EventLogsTable = () => {
  const translate = useTranslator();

  const [orderBy, setOrderBy] = useState<keyof EventLogWithTimestamp>();
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.asc);
  const [page, setPage] = useState(0);
  const paginationOptions = [30, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchPhrase, setSearchPhrase] = useState('');

  const defaultFilters: Filters = {
    [FilterField.context]: null,
    [FilterField.eventDateTimeFrom]: null,
    [FilterField.eventDateTimeTo]: null,
    [FilterField.eventType]: [],
    [FilterField.eventSource]: null,
    [FilterField.processId]: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const formatFilters = (filters: Filters): FiltersPayload => ({
    context: filters.context,
    eventTimestampFrom: !!filters.eventDateTimeFrom ? getUnixTime(new Date(filters.eventDateTimeFrom)) : null,
    eventTimestampTo: !!filters.eventDateTimeTo ? getUnixTime(new Date(filters.eventDateTimeTo)) : null,
    eventType: filters.eventType,
    eventSource: filters.eventSource,
    processId: filters.processId,
  });

  const queryParams = removeEmptyFields({
    pageNumber: page,
    itemsPerPage: rowsPerPage,
    orderBy,
    ...(orderBy && { orderDirection }),
    searchPhrase,
    filters: formatFilters(filters),
  });
  const { data, isError, isFetching, isLoading, isPreviousData } = useGetEventLogs(queryParams);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof EventLogWithTimestamp) => {
    const isAsc = orderBy === property && orderDirection === OrderDirection.asc;
    setOrderDirection(isAsc ? OrderDirection.desc : OrderDirection.asc);
    setOrderBy(property);
    setPage(0);
  };

  const handleSubmitSearchPhrase = (searchPhrase: string) => {
    setSearchPhrase(searchPhrase.trim());
    setPage(0);
  };

  const handleSubmitFilters = (filters: Filters) => {
    setFilters(filters);
    setPage(0);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <DataLoading data-testid="loader" />;
    }

    if (isError) {
      return <DataLoadingError />;
    }

    if (data) {
      return data.items.length > 0 ? (
        <>
          {data?.items.map((eventLog: EventLog) => (
            <EventLogsTableRow key={eventLog.id} eventLog={eventLog} />
          ))}
        </>
      ) : (
        <NoData />
      );
    }
  };

  return (
    <Paper sx={styles.tableWrapper} data-testid="eventLogs-table">
      {isFetching && isPreviousData && <CircularProgress sx={styles.loader} data-testid="loader" />}

      <EventLogsTableToolbar
        filters={filters}
        onPageChange={handleChangePage}
        onSubmitFilters={handleSubmitFilters}
        onSubmitSearchPhrase={handleSubmitSearchPhrase}
      />

      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table} stickyHeader aria-label={translate('eventLogs.pageTitle')} size="small">
          <EventLogsTableHeader orderDirection={orderDirection} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody sx={styles.tableBody}>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>

      {data && (
        <Pagination
          count={data.totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={paginationOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
