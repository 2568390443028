import axios from 'axios';

import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';

import { LoginPayload, AuthorizeResponse, LoggedInRealmsResponse } from './ssoActions.types';

export const loginAction = async (values: LoginPayload): Promise<AuthorizeResponse | void> => {
  const response = await umsApi.post(endpoints.sso.login, values);

  if (response.status === 200) {
    return response.data;
  }
};

export const getLoggedInRealms = async (): Promise<LoggedInRealmsResponse> => {
  const response = await umsApi.get(endpoints.sso.loggedInRealms);

  return response.data;
};

export const logoutFromServiceProviderAction = async (url: string): Promise<void> => {
  await axios.get(url);
};
