import { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { AppRoutes } from 'routing/AppRoutes';
import { Breadcrumbs } from 'ui/breadcrumbs/Breadcrumbs';
import { ConfigContextController } from 'context/config/configContextController/ConfigContextController';
import { DrawerMenu } from 'ui/drawerMenu/DrawerMenu';
import { Footer } from 'ui/footer/Footer';
import { ToastContainer } from 'ui/toast/toastContainer/ToastContainer';
import { Topbar } from 'ui/topbar/Topbar';

import * as styles from './App.styles';

export const App = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const { isAuthorized } = useAuthState();
  const { hasPermissions } = usePermissions();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const showDrawer = hasPermissions || matches;

  const handleToggleDrawer = (open: boolean) => (event: React.MouseEvent) => {
    setDrawerOpen(open);
  };

  return (
    <Box sx={styles.wrapper}>
      <ToastContainer />
      <Topbar drawerOpen={drawerOpen} handleToggleDrawer={handleToggleDrawer} />
      <Box sx={styles.container}>
        {showDrawer && <DrawerMenu drawerOpen={drawerOpen} handleToggleDrawer={handleToggleDrawer} />}
        <Box sx={styles.content}>
          <ConfigContextController>
            <Box component="main" sx={styles.main}>
              {isAuthorized && <Breadcrumbs sx={styles.breadcrumbs} />}
              <AppRoutes />
            </Box>
          </ConfigContextController>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
