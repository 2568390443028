import { Styles } from '@typings/common';

export const container: Styles = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  mr: { md: 4 },
};

export const confirmButton: Styles = {
  mr: { xs: 1, md: 2 },
  ml: { md: 2 },
};
