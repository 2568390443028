import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getInstitutions } from 'api/actions/institutions/institutionsActions';
import { InstitutionsPayload, InstitutionsResponse } from 'api/actions/institutions/institutionsActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetInstitutions = (params: InstitutionsPayload) => {
  const queryGetInstitutions = useQuery<InstitutionsResponse, AxiosError>(
    [Queries.getInstitutions, [params]],
    () => getInstitutions(params),
    { keepPreviousData: true },
  );

  return queryGetInstitutions;
};
