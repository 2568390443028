import { isAfter, isSameMinute } from 'date-fns';

import yup from 'utils/yup';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const useFiltersDataSchema = () => {
  const translate = useTranslator();

  return yup.object().shape({
    createdAtDateTimeFrom: yup
      .date()
      .nullable()
      .isMinTimestampDate(translate('dateTimePicker.minTimestampDate'))
      .isNotFuture(translate('dateTimePicker.dateNotInFuture'))
      .typeError(translate('global.fieldWrongFormat')),
    createdAtDateTimeTo: yup
      .date()
      .nullable()
      .isMinTimestampDate(translate('dateTimePicker.minTimestampDate'))
      .isNotFuture(translate('dateTimePicker.dateNotInFuture'))
      .typeError(translate('global.fieldWrongFormat'))
      .test(function (createdAtDateTimeTo) {
        const { createError, parent, path } = this;
        const createdAtDateTimeFrom = parent.createdAtDateTimeFrom;

        if (createdAtDateTimeTo && createdAtDateTimeFrom) {
          const isToAfterFrom = isAfter(createdAtDateTimeTo, createdAtDateTimeFrom);
          const isToSameMinuteFrom = isSameMinute(createdAtDateTimeTo, createdAtDateTimeFrom);

          if (isToSameMinuteFrom) return true;

          if (!isToAfterFrom) {
            throw createError({
              path: path,
              message: translate('global.errorDateToAfterFrom'),
            });
          }
        }

        return true;
      }),
    login: yup.string().nullable(),
    ipAddress: yup.string().nullable(),
    origin: yup.string().nullable(),
    destination: yup.string().nullable(),
  });
};
