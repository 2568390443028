import { ReactText, useRef } from 'react';
import { MutationFunction, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';
import { useCheckExportStatus } from 'hooks/export/useCheckExportStatus/useCheckExportStatus';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { StartExportResponse } from 'api/actions/exports/exportActions.types';

export const useStartExport = <T>(mutationFn: MutationFunction<StartExportResponse, T>, mutationKey: string) => {
  const translate = useTranslator();
  const toastRef = useRef<ReactText>('toast');

  const mutationCheckExportStatus = useCheckExportStatus(toastRef);

  const mutationStartUsersExport = useMutation<StartExportResponse, AxiosError, T>(mutationFn, {
    mutationKey,
    onSuccess: ({ id }) => {
      toastRef.current = toast(translate('export.startExport'), { type: 'default', autoClose: false, isLoading: true });

      mutationCheckExportStatus.mutate({ id });
    },
    onError: (error) => {
      const serverErrors: string[] = error.response?.data?.errors;

      if (!serverErrors) {
        return;
      }

      serverErrors.forEach((serverError) => {
        isTranslationAvailable(serverError) ? toast.error(translate(serverError)) : toast.error(serverError);
      });
    },
  });

  return mutationStartUsersExport;
};
