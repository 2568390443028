import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { StartupPasswordProps } from './StartupPassword.types';
import * as styles from './StartupPassword.styles';

export const StartupPassword = ({
  getStartupPasswordData,
  isGetStartupPasswordLoading,
  onToggleShowStartupPassword,
  showStartupPassword,
  userHasStartupPassword,
}: StartupPasswordProps) => {
  const translate = useTranslator();

  const startupPassword = getStartupPasswordData?.startupPassword;
  const canShowStartupPassword = showStartupPassword && startupPassword;

  if (!userHasStartupPassword) {
    return null;
  }

  if (canShowStartupPassword) {
    return (
      <Box sx={styles.startupPasswordBox}>
        {startupPassword}
        <Tooltip title={translate('global.passwordHide')} arrow>
          <IconButton size="small" aria-label={translate('global.passwordHide')} onClick={onToggleShowStartupPassword}>
            <VisibilityOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  } else {
    return (
      <Box sx={styles.startupPasswordBox}>
        {translate('users.maskedPassword')}
        <Tooltip title={translate('global.passwordShow')} arrow>
          <IconButton size="small" aria-label={translate('global.passwordShow')} onClick={onToggleShowStartupPassword}>
            {isGetStartupPasswordLoading ? <CircularProgress data-testid="loader" size={24} /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
};
