import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { markNotificationAsRead } from 'api/actions/notifications/notificationsActions';
import { MarkNotificationAsReadPayload } from 'api/actions/notifications/notificationsActions.types';
import { Mutations } from 'api/mutations/mutation.enum';
import { Queries } from 'api/queries/queries.enum';

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();

  const mutationMarkNotificationAsRead = useMutation<void, AxiosError, MarkNotificationAsReadPayload>(
    markNotificationAsRead,
    {
      mutationKey: Mutations.markNotificationAsRead,
      onMutate: () => {
        queryClient.cancelQueries(Queries.getNotifications);
      },
    },
  );

  return mutationMarkNotificationAsRead;
};
