import { useState, useEffect, SyntheticEvent } from 'react';
import { Box, Paper, Tab as MUITab, Tabs, Typography } from '@mui/material';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { PasswordChangeFormContainer } from 'ui/password/passwordChangeForm/PasswordChangeFormContainer';

import { ProfileDataContainer } from './profileData/ProfileDataContainer';
import { ProfileTab } from './Profile.enum';
import { Tab, TabPanelProps } from './Profile.types';
import * as styles from './Profile.styles';

export const defaultProfileTab = ProfileTab.data;

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yTabProps = (index: number) => {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
};

export const Profile = () => {
  const translate = useTranslator();

  const { user } = useAuthState();

  const history = useHistory();
  const location = useLocation();

  const { tab } = useParams<{ tab: ProfileTab }>();

  const tabs: Tab[] = [
    {
      name: ProfileTab.data,
      label: translate('profile.tabData'),
      component: <ProfileDataContainer />,
    },
    {
      name: ProfileTab.passwordChange,
      label: translate('profile.tabPasswordChange'),
      component: <PasswordChangeFormContainer />,
    },
  ];

  const tabIndexFromParam = tabs.findIndex(({ name }) => name === tab);

  const [currentTab, setCurrentTab] = useState(tabIndexFromParam);

  useEffect(() => {
    if (tabIndexFromParam === -1) {
      history.push(generatePath(AppRoute.profile, { tab: defaultProfileTab }));
    }

    setCurrentTab(tabIndexFromParam);
  }, [location]);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    const path = generatePath(AppRoute.profile, { tab: tabs[newValue].name });

    history.push(path);
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          {translate('profile.pageTitle')}
        </Typography>
      </Box>

      <Paper sx={styles.tabsContainer}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          aria-label={translate('profile.tabsLabel')}
          indicatorColor="primary"
        >
          {tabs.map(({ label, name }, index) => (
            <MUITab label={label} key={name} {...a11yTabProps(index)} />
          ))}
        </Tabs>
        {tabs.map(({ component, name }, index) => (
          <TabPanel value={currentTab} index={index} key={name}>
            {component}
          </TabPanel>
        ))}
      </Paper>
    </>
  );
};
