import { Styles } from '@typings/common';

export const header: Styles = {
  fontWeight: 500,
};

export const listWrapper: Styles = {
  pt: 2,
  px: 2,
  pb: 0,
};

export const list: Styles = {
  m: 0,
};

export const listItem: Styles = {
  mb: 2,

  '&:last-child': {
    mb: 0,
  },
};

export const errorType: Styles = {
  mb: 1,
  color: 'error.main',
  fontWeight: 500,
};

export const errorLines: Styles = {
  m: 0,
};
