import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { getUserStartupPassword } from 'api/actions/password/passwordActions';
import { StartupPasswordResponse } from 'api/actions/password/passwordActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetStartupPassword = ({ userId, queryEnabled }: { userId: string; queryEnabled: boolean }) => {
  const queryClient = useQueryClient();

  const queryGetStartupPassword = useQuery<StartupPasswordResponse, AxiosError>(
    [Queries.getStartupPassword, userId],
    () => getUserStartupPassword(userId),
    {
      enabled: queryEnabled,
      retry: false,
      onError: async (error) => {
        if (error.response?.status === 404) {
          await queryClient.cancelQueries([Queries.getStartupPassword, userId]);

          await queryClient.invalidateQueries(Queries.getUsers);
        }
      },
    },
  );

  return queryGetStartupPassword;
};
