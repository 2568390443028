import { Styles } from '@typings/common';

export const changePasswordForm: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const textField: Styles = {
  mb: 2,
};

export const buttonBox: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const backButton: Styles = {
  mr: 1,
};

export const serverErrorsList: Styles = {
  mb: 2,
};

export const loader: Styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
