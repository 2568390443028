import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, TableCell, TableRow } from '@mui/material';

import { permissions } from 'utils/permissions';
import { Restriction, useCheckRestrictedRoles } from 'hooks/useCheckRestrictedRoles/useCheckRestrictedRoles';
import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { RestrictedSectionTooltip } from 'hoc/restrictedSectionTooltip/RestrictedSectionTooltip';
import { AuthorizedSection } from 'hoc/authorizedSection/AuthorizedSection';

import { RolesTableRowProps } from './RolesTableRow.types';
import * as styles from './RolesTableRow.styles';

export const RolesTableRow = ({ index, role }: RolesTableRowProps) => {
  const translate = useTranslator();

  const { formatDate } = useFormatDate();

  const { checkIsRestrictedRole } = useCheckRestrictedRoles();

  const isNonAssignableRole = checkIsRestrictedRole(role, { restrictionType: Restriction.nonAssignable });
  const isBlacklistedRole = checkIsRestrictedRole(role, { restrictionType: Restriction.blacklisted });
  const isRestrictedRole = isNonAssignableRole || isBlacklistedRole;

  const tooltipTitle = useMemo(() => {
    if (isNonAssignableRole) {
      return translate('global.roleNonAssignable');
    }

    if (isBlacklistedRole) {
      return translate('global.roleBlacklisted');
    }
  }, [isRestrictedRole]);

  const { name, creationDate, builtIn } = role;

  const setRoleInStorage = () => localStorage.setItem('role', JSON.stringify(role));

  return (
    <TableRow sx={isRestrictedRole ? styles.rowDisabled : undefined} hover={!isRestrictedRole}>
      <TableCell sx={styles.indexRow}>
        <RestrictedSectionTooltip isRestricted={isRestrictedRole} title={tooltipTitle}>
          {index + 1}
        </RestrictedSectionTooltip>
      </TableCell>
      <TableCell>
        <RestrictedSectionTooltip isRestricted={isRestrictedRole} title={tooltipTitle}>
          {name}
        </RestrictedSectionTooltip>
      </TableCell>
      <TableCell>
        <RestrictedSectionTooltip isRestricted={isRestrictedRole} title={tooltipTitle}>
          {!!creationDate && formatDate(creationDate)}
        </RestrictedSectionTooltip>
      </TableCell>
      <TableCell>
        <RestrictedSectionTooltip isRestricted={isRestrictedRole} title={tooltipTitle}>
          {translate(builtIn ? 'roles.roleIsBuiltIn' : 'roles.roleIsNotBuiltIn')}
        </RestrictedSectionTooltip>
      </TableCell>
      <AuthorizedSection allowedPermissions={permissions.usersListView}>
        <TableCell sx={styles.slimCell}>
          <Button
            sx={styles.goToUsers}
            component={RouterLink}
            to={AppRoute.users}
            onClick={setRoleInStorage}
            color="primary"
            variant="outlined"
          >
            {translate('roles.buttonGoToUsers')}
          </Button>
        </TableCell>
      </AuthorizedSection>
    </TableRow>
  );
};
