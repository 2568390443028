import { RouteProps } from 'react-router-dom';
import { AxiosError } from 'axios';

import { RemindPasswordPayload } from 'api/actions/password/passwordActions.types';

export type PasswordRemindProps = {
  error: AxiosError | null;
  isSuccess: boolean;
  onSubmit: (body: RemindPasswordPayload) => Promise<boolean>;
} & RouteProps;

export type LocationState = {
  from?: string;
  search?: string;
};

export enum FormFields {
  login = 'login',
}

export type PasswordRemindForm = {
  [FormFields.login]: string;
};
