export const errorMessages = {
  authentication: {
    invalidCredentials: 'ERROR.AUTHENTICATION.INVALID_CREDENTIALS',
  },
  bulkActions: {
    printStartupPasswords: {
      failureReason: {
        noStartupPasswordsToPrint: 'ERROR.BULK_ACTION.PRINT_STARTUP_PASSWORDS.NO_STARTUP_PASSWORDS_TO_PRINT',
      },
    },
  },
  import: {
    missingHeader: 'CONSTRAINT.VALIDATION.MISSING_HEADER',
    duplicatedHeader: 'CONSTRAINT.VALIDATION.DUPLICATED_HEADER',
    unknownHeader: 'CONSTRAINT.VALIDATION.UNKNOWN_HEADER',
    rowLengthDoesNotMatchRequiredLength: 'CONSTRAINT.VALIDATION.NO_OF_COLUMNS_DOES_NOT_MATCH',
    rowUnsupportedDelimiter: 'CONSTRAINT.VALIDATION.IMPORT_FILE_HAS_UNSUPPORTED_DELIMITER',
    emptyLine: 'CONSTRAINT.VALIDATION.IMPORT_FILE_EMPTY_LINE',
    unsupportedLineTerminator: 'CONSTRAINT.VALIDATION.IMPORT_FILE_HAS_UNSUPPORTED_LINE_TERMINATOR',
  },
  pagination: {
    pageDoesNotExist: 'PAGE_NUMBER.VALIDATION.PAGE_DOES_NOT_EXIST',
  },
  password: {
    oldPasswordNotValid: 'CONSTRAINT.VALIDATION.USER.PASSWORD.NOT_VALID_OLD_PASSWORD',
    passwordUsed: 'PASSWORD.HISTORY.VALIDATION.USER.PASSWORD.USED',
    invalidResetLinkToken: 'PASSWORD.RESET_TOKEN.INVALID',
  },
  user: {
    bindingKeyNotUnique: 'CONSTRAINT.VALIDATION.USER.UNIQUE_BINDING_KEY',
    bindingKeyInavlidSyntax: 'CONSTRAINT.VALIDATION.USER.BINDING_KEY',
    canNotGenerateLogin: 'ERROR.USER_ADD.LOGIN.CAN_NOT_GENERATE',
    deleteLastSuperAdminNotPossible: 'CONSTRAINT.VALIDATION.USER.LAST_SUPER_ADMINISTRATOR',
    doesNotExist: 'CONSTRAINT.VALIDATION.USER_DOES_NOT_EXIST',
    invalidId: 'CONSTRAINT.VALIDATION.INVALID_USER_ID',
    notActive: 'ERROR.AUTHENTICATION.USER_NOT_ACTIVE',
  },
};
