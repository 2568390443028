import { Styles } from '@typings/common';

export const pagination: Styles = (theme) => ({
  overflow: 'initial',
  mt: 'auto',

  [theme.breakpoints.down('md')]: {
    '& .MuiToolbar-regular': {
      flexWrap: 'wrap',
    },

    '& .MuiTablePagination-actions': {
      ml: 'auto',
    },
  },
});
