import { HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Toolbar, Typography, TextField as MUITextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFormatEvent } from 'hooks/format/useFormatEvent/useFormatEvent';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Autocomplete } from 'ui/autocomplete/Autocomplete';
import { DateTimePicker } from 'ui/dateTimePicker/DateTimePicker';
import { EventSource, EventType, FilterField } from 'api/actions/eventLogs/eventLogs.enum';
import { Filters } from 'api/actions/eventLogs/eventLogs.types';
import { FiltersPopover } from 'ui/table/toolbar/filtersPopover/FiltersPopover';
import { SearchBar } from 'ui/table/toolbar/searchBar/SearchBar';
import { TextField } from 'ui/textField/TextField';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { EventLogsTableToolbarProps, PopoverRef, SearchFormData } from './EventLogsTableToolbar.types';
import { EventLogsTableToolbarActiveFilters } from './eventLogsTableToolbarActiveFilters/EventLogsTableToolbarActiveFilters';
import { useFiltersDataSchema } from './FiltersDataSchema';

export const EventLogsTableToolbar = ({
  filters,
  onPageChange,
  onSubmitFilters,
  onSubmitSearchPhrase,
}: EventLogsTableToolbarProps) => {
  const translate = useTranslator();

  const { formatEventSource, formatEventType } = useFormatEvent();

  const filtersDataSchema = useFiltersDataSchema();

  const methods = useForm<Filters>({
    resolver: yupResolver(filtersDataSchema),
    mode: 'onChange',
  });

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = methods;

  const eventDateTimeFrom = watch(FilterField.eventDateTimeFrom);
  useEffect(() => {
    if (errors?.eventDateTimeTo) {
      trigger([FilterField.eventDateTimeTo]);
    }
  }, [eventDateTimeFrom]);

  const handleSubmitSearchForm = ({ searchPhrase }: SearchFormData) => {
    onSubmitSearchPhrase(searchPhrase);
  };

  const popoverRef = useRef<PopoverRef>();

  const onSubmitFilterForm = (data: Filters) => {
    onSubmitFilters(data);
    popoverRef?.current?.handleCloseFiltersPopover();
  };

  const resetFiltersFormValuesToSubmitted = useCallback(() => {
    const filtersFields = Object.keys(filters) as FilterField[];

    filtersFields.forEach((key) => {
      setValue(key, filters[key]);
    });

    clearErrors();
  }, [filters, setValue]);

  useEffect(() => {
    resetFiltersFormValuesToSubmitted();
  }, [filters, resetFiltersFormValuesToSubmitted]);

  return (
    <Toolbar sx={styles.toolbar}>
      <SearchBar onSubmit={handleSubmitSearchForm} textFieldPlaceholder={translate('eventLogs.searchPlaceholder')} />

      <FiltersPopover TransitionProps={{ onExited: resetFiltersFormValuesToSubmitted }} ref={popoverRef}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitFilterForm)} noValidate autoComplete="off">
            <Box padding={2}>
              <Typography paragraph>{translate('global.filtersTitle')}</Typography>

              <Box sx={styles.filterInput}>
                <DateTimePicker<Filters>
                  label={translate('global.dateTimeFrom')}
                  name={FilterField.eventDateTimeFrom}
                  textFieldProps={{ sx: styles.datePicker }}
                />
              </Box>
              <Box sx={styles.filterInput}>
                <DateTimePicker<Filters>
                  label={translate('global.dateTimeTo')}
                  name={FilterField.eventDateTimeTo}
                  textFieldProps={{ sx: styles.datePicker }}
                />
              </Box>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    size="small"
                    sx={styles.filterInput}
                    options={Object.values(EventType)}
                    getOptionLabel={(option) => formatEventType(option)}
                    renderInput={(params) => (
                      <MUITextField {...params} variant="outlined" label={translate('eventLogs.eventType')} />
                    )}
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                    data-testid="eventType-autocomplete"
                    ListboxProps={{ 'data-testid': 'eventType-list' } as HTMLAttributes<HTMLUListElement>}
                  />
                )}
                name={FilterField.eventType}
                control={control}
              />
              <Box>
                <TextField<Filters>
                  name={FilterField.context}
                  label={translate('eventLogs.eventObject')}
                  size="small"
                  sx={styles.filterInput}
                />
              </Box>
              <Box>
                <TextField<Filters>
                  name={FilterField.processId}
                  label={translate('eventLogs.processId')}
                  size="small"
                  sx={styles.filterInput}
                />
              </Box>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    sx={styles.filterInput}
                    options={Object.values(EventSource)}
                    getOptionLabel={(option) => formatEventSource(option)}
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        placeholder={translate('eventLogs.eventSource')}
                        variant="outlined"
                        label={translate('eventLogs.eventSource')}
                      />
                    )}
                    onChange={(_, data) => {
                      field.onChange(data);
                    }}
                    data-testid="eventSource-autocomplete"
                    ListboxProps={{ 'data-testid': 'eventSource-list' } as HTMLAttributes<HTMLUListElement>}
                  />
                )}
                name={FilterField.eventSource}
                control={control}
              />
              <Button type="submit" variant="contained">
                {translate('global.filtersSubmit')}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </FiltersPopover>

      <EventLogsTableToolbarActiveFilters
        filters={filters}
        onPageChange={onPageChange}
        onSubmitFilterForm={onSubmitFilterForm}
      />
    </Toolbar>
  );
};
