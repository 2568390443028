export const removeEmptyFields = <T>(obj: T): Partial<T> => {
  const acc: Partial<T> = {};

  for (const key in obj) {
    if (obj[key]) {
      acc[key] = obj[key];
    }
  }

  return acc;
};
