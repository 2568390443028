import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import InfoIcon from '@mui/icons-material/Info';

import { useVerifyPasswordLink } from 'hooks/password/useVerifyPasswordLink/useVerifyPasswordLink';
import { Loader } from 'ui/loader/Loader';
import { PasswordActionMessage } from 'ui/password/passwordActionMessage/PasswordActionMessage';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';

import { PasswordNewFormContainer } from './passwordNewForm/PasswordNewFormContainer';

export const PasswordNew = () => {
  const translate = useTranslator();

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const token = queryParams.token as string;

  const mutationVerifyPasswordLink = useVerifyPasswordLink();
  const { isError, isLoading, mutate } = mutationVerifyPasswordLink;

  const [isVisibleLinkExpiredMessage, setIsVisibleLinkExpiredMessage] = useState(false);

  const handleShowLinkExpiredMessage = () => {
    setIsVisibleLinkExpiredMessage(true);
  };

  useEffect(() => {
    mutate({
      resetLink: window.location.href,
    });
  }, []);

  if (isLoading) {
    return <Loader data-testid="password-new-loader" />;
  }

  if (isError || isVisibleLinkExpiredMessage) {
    return (
      <PasswordActionMessage
        title={translate('passwordNew.linkExpiredTitle')}
        description={translate('passwordNew.linkExpiredDescription')}
        icon={InfoIcon}
        iconColor={'disabled'}
        buttonText={translate('passwordNew.linkExpiredButton')}
        buttonLink={AppRoute.passwordRemind}
      />
    );
  }

  return <PasswordNewFormContainer onShowLinkExpiredMessage={handleShowLinkExpiredMessage} token={token} />;
};
