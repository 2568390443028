import isEqual from 'lodash.isequal';

import { Permission } from 'api/actions/users/usersActions.enum';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { permissions } from 'utils/permissions';

import { ChecKPermissionsOptions } from './usePermission.types';

export const usePermissions = () => {
  const { user } = useAuthState();

  const userPermissions = user?.permissions || [];
  const hasAccess = (permissions?: Permission[] | [], options: ChecKPermissionsOptions = {}) => {
    if (!permissions) {
      return true;
    }

    if (userPermissions && userPermissions.length) {
      if (options.exact) {
        return isEqual(permissions, userPermissions);
      }

      return permissions.some((permission) => userPermissions.includes(permission));
    }

    return false;
  };

  const hasAccessOnlyPasswordChange = hasAccess(permissions.passwordChange, { exact: true });

  const hasPermissions = userPermissions.length > 0 && !hasAccessOnlyPasswordChange;

  return { hasAccess, hasAccessOnlyPasswordChange, hasPermissions, userPermissions };
};
