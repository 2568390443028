import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { getNotifications } from 'api/actions/notifications/notificationsActions';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useHasLogoutStarted } from 'hooks/auth/useHasLogoutStarted/useHasLogoutStarted';
import { useIsMarkingNotificationAsRead } from '../useIsMarkingNotificationAsRead/useIsMarkingNotificationAsRead';
import { NotificationsResponse } from 'api/actions/notifications/notificationsActions.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetNotifications = () => {
  const queryClient = useQueryClient();

  const { isAuthorized } = useAuthState();

  const { hasLogoutStarted } = useHasLogoutStarted();

  const { isMarkingNotificationAsRead } = useIsMarkingNotificationAsRead();

  useEffect(() => {
    if (hasLogoutStarted) {
      queryClient.cancelQueries(Queries.getNotifications);
    }
  }, [hasLogoutStarted, queryClient]);

  const enabled = isAuthorized && !isMarkingNotificationAsRead && !hasLogoutStarted;

  const queryGetNotifications = useQuery<NotificationsResponse, AxiosError>(
    Queries.getNotifications,
    getNotifications,
    {
      enabled,
      keepPreviousData: true,
      refetchInterval: 2000,
    },
  );

  return queryGetNotifications;
};
