import { ReactNode } from 'react';
import { TableCell, TableCellProps, TableHead, TableRow } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Institution } from 'api/actions/institutions/institutionsActions.types';
import { SortableTableCell as GenericSortableCell } from 'ui/table/cell/SortableTableCell';
import * as styles from 'ui/table/Table.styles';

import { InstitutionsTableHeaderProps } from './InstitutionsTableHeader.props';

export const InstitutionsTableHeader = ({ orderBy, orderDirection, onRequestSort }: InstitutionsTableHeaderProps) => {
  const translate = useTranslator();

  const SortableTableCell = ({
    id,
    children,
    ...props
  }: { id: keyof Institution; children: ReactNode } & TableCellProps) => (
    <GenericSortableCell<Institution>
      id={id}
      orderBy={orderBy}
      orderDirection={orderDirection}
      onRequestSort={onRequestSort}
      {...props}
    >
      {children}
    </GenericSortableCell>
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell sx={styles.indexCell}>{translate('global.no')}</TableCell>
        <TableCell sx={styles.slimCell}>{translate('institutions.institutionId')}</TableCell>
        <SortableTableCell id="shortName" sx={styles.slimCell}>
          {translate('institutions.institutionShortName')}
        </SortableTableCell>
        <SortableTableCell id="name">{translate('institutions.institutionName')}</SortableTableCell>
        <TableCell>{translate('institutions.institutionRegon')}</TableCell>
        <TableCell>{translate('institutions.institutionRspo')}</TableCell>
      </TableRow>
    </TableHead>
  );
};
