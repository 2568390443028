import { Styles } from '@typings/common';

export const form = (isSuccess: boolean): Styles => ({
  width: '36rem',
  maxWidth: '100%',
  my: 3,
  mx: 'auto',
  pt: 2,
  px: 2,
  pb: 4,
  ...(isSuccess ? formSuccess : {}),
});

export const formSuccess: Styles = {
  p: 0,
};

export const formTitle: Styles = {
  mt: 2,
  mb: 4,
  textAlign: 'center',
  fontWeight: 500,
};

export const formSubTitle: Styles = {
  mb: 2,
  fontWeight: 500,
};

export const formDescription: Styles = {
  mb: 2,
  overflowWrap: 'break-word',
};

export const textField: Styles = {
  mb: 2,
};

export const login: Styles = {
  color: 'primary.main',
  textDecoration: 'none',
};

export const buttonBox: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const backButton: Styles = {
  mr: 1,
};

export const errorMessage: Styles = {
  mb: 2,
};

export const successMessage: Styles = {
  fontSize: '1rem',
};
