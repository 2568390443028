import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import groupBy from 'lodash.groupby';
import { useQueryClient } from 'react-query';

import { setAuthorized, setUnauthorized, startAuthorizing } from '../authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/auth/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useGetNotifications } from 'hooks/notifications/useGetNotifications/useGetNotifications';
import { useGetUserMe } from 'hooks/users/useGetUserMe/useGetUserMe';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useShowNotifications } from 'hooks/notifications/useShowNotifications/useShowNotifications';
import { AppRoute } from 'routing/AppRoute.enum';
import { AuthModeName } from '../authContext/AuthContext.enum';
import { ExecutionType } from '@typings/common';
import { Queries } from 'api/queries/queries.enum';

import { UserControllerProps } from './UserController.types';

const authorizationExceptionsRoutes: AppRoute[] = [AppRoute.emailConfirm, AppRoute.passwordNew];

export const UserController = ({ children }: UserControllerProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const { authMode } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryGetUserMe = useGetUserMe({ executionType: ExecutionType.query });

  const { hasAccessOnlyPasswordChange } = usePermissions();

  const getAuthMode = () => {
    return authMode || { name: AuthModeName.default };
  };

  const initAuthorization = () => {
    if (authorizationExceptionsRoutes.includes(location.pathname as AppRoute)) {
      dispatch(setUnauthorized());

      return;
    }

    queryClient.refetchQueries(Queries.getUserMe);
    dispatch(startAuthorizing());
  };

  useEffect(() => {
    initAuthorization();
  }, [dispatch]);

  useEffect(() => {
    if (!queryGetUserMe.error && queryGetUserMe.data) {
      hasAccessOnlyPasswordChange && history.push(AppRoute.passwordChange);

      return dispatch(setAuthorized(getAuthMode(), queryGetUserMe.data));
    }
  }, [dispatch, queryGetUserMe.error, queryGetUserMe.data]);

  const queryGetNotifications = useGetNotifications();
  const showNotifications = useShowNotifications();

  const notifications = groupBy(queryGetNotifications.data?.items, function (notification) {
    return notification.id ? 'confirmable' : 'nonConfirmable';
  });

  useEffect(() => {
    if (queryGetNotifications.data && notifications.confirmable) {
      showNotifications(notifications.confirmable);
    }
  }, [queryGetNotifications.data]);

  useEffect(() => {
    if (queryGetNotifications.isFetchedAfterMount && notifications.nonConfirmable) {
      showNotifications(notifications.nonConfirmable);
    }
  }, [queryGetNotifications.isFetchedAfterMount]);

  return <>{children}</>;
};
