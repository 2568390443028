import { Box, Typography } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { EventLogsTable } from './eventLogsTable/EventLogsTable';

export const EventLogs = () => {
  const translate = useTranslator();

  return (
    <>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          {translate('eventLogs.pageTitle')}
        </Typography>
      </Box>

      <EventLogsTable />
    </>
  );
};
