import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { DisabledOptionWithTooltip } from '../disabledOptionWithTooltip/DisabledOptionWithTooltip';
import { Restriction, useCheckRestrictedRoles } from 'hooks/useCheckRestrictedRoles/useCheckRestrictedRoles';

import { RoleOptionProps } from './RoleOption.types';

export const RoleOption = ({ option }: RoleOptionProps) => {
  const translate = useTranslator();

  const { checkIsRestrictedRole } = useCheckRestrictedRoles();

  if (checkIsRestrictedRole(option, { restrictionType: Restriction.nonAssignable })) {
    return (
      <DisabledOptionWithTooltip tooltipTitle={translate('global.roleNonAssignable')}>
        {option.name}
      </DisabledOptionWithTooltip>
    );
  }

  if (checkIsRestrictedRole(option, { restrictionType: Restriction.blacklisted })) {
    return (
      <DisabledOptionWithTooltip tooltipTitle={translate('global.autocompleteRoleBlacklistedOption')}>
        {option.name}
      </DisabledOptionWithTooltip>
    );
  }

  return <>{option.name}</>;
};
