import { isAfter, isSameDay } from 'date-fns';

import yup from 'utils/yup';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { FilterField } from 'api/actions/users/usersActions.enum';

export const useFiltersDataSchema = () => {
  const translate = useTranslator();

  return yup.object().shape({
    role: yup.array(),
    institution: yup.array(),
    status: yup.array(),
    lastLoginDateFrom: yup
      .date()
      .typeError(translate('global.fieldWrongFormat'))
      .nullable()
      .when(FilterField.neverLogged, {
        is: false,
        then: yup
          .date()
          .nullable()
          .isMinTimestampDate(translate('dateTimePicker.minTimestampDate'))
          .isNotFuture(translate('dateTimePicker.dateNotInFuture'))
          .typeError(translate('global.fieldWrongFormat')),
      }),
    lastLoginDateTo: yup
      .date()
      .typeError(translate('global.fieldWrongFormat'))
      .nullable()
      .when(FilterField.neverLogged, {
        is: false,
        then: yup
          .date()
          .typeError(translate('global.fieldWrongFormat'))
          .nullable()
          .isMinTimestampDate(translate('dateTimePicker.minTimestampDate'))
          .isNotFuture(translate('dateTimePicker.dateNotInFuture'))
          .typeError(translate('global.fieldWrongFormat'))
          .test(function (lastLoginDateTo) {
            const { createError, parent, path } = this;
            const lastLoginDateFrom = parent.lastLoginDateFrom;

            if (lastLoginDateTo && lastLoginDateFrom) {
              const isToAfterFrom = isAfter(lastLoginDateTo, lastLoginDateFrom);
              const isToSameDayFrom = isSameDay(lastLoginDateTo, lastLoginDateFrom);

              if (isToSameDayFrom) return true;

              if (!isToAfterFrom) {
                throw createError({
                  path: path,
                  message: translate('global.errorDateToAfterFrom'),
                });
              }
            }

            return true;
          }),
      }),
    neverLogged: yup.boolean(),
  });
};
