import { useMemo } from 'react';

import { useConfigState } from 'hooks/useConfigState/useConfigState';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const useBindingKey = () => {
  const translate = useTranslator();
  const config = useConfigState();

  const bindingKeys = config.bindingKeys;

  const areMultipleBindingKeys = useMemo(() => {
    return !!bindingKeys && bindingKeys.length > 1;
  }, [bindingKeys]);

  const getBindingKeyLabel = (key: string | null) => {
    if (key) {
      return bindingKeys?.filter((bindingKey) => bindingKey.key === key)[0].label;
    }

    return null;
  };

  const bindingKeyValueLabel = useMemo(() => {
    if (bindingKeys && !areMultipleBindingKeys) {
      return bindingKeys[0].label;
    }

    return translate('global.bindingKeyValue');
  }, [bindingKeys]);

  return { areMultipleBindingKeys, bindingKeys, bindingKeyValueLabel, getBindingKeyLabel };
};
