import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Fade, IconButton, TextField, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { SearchBarProps, SearchFormData, SearchFormFields } from './SearchBar.types';
import * as styles from './SearchBar.styles';

export const SearchBar = ({ onSubmit, textFieldPlaceholder }: SearchBarProps) => {
  const translate = useTranslator();

  const [searchOpen, setSearchOpen] = useState(false);

  const { getValues, handleSubmit, register, reset, watch } = useForm<SearchFormData>();

  const { ref: refSearchPhrase, ...searchPhraseProps } = register(SearchFormFields.searchPhrase);

  const isSearchPhraseFilled = watch(SearchFormFields.searchPhrase)?.length;

  const tooltipRef = useRef<HTMLElement>(null);

  const handleToggleSearch = () => {
    tooltipRef.current?.parentElement?.classList.toggle('searchBarOpen');

    setSearchOpen((prev) => !prev);
  };

  const handleSubmitCallback = useCallback((data) => onSubmit(data), [onSubmit]);

  const handleResetCallback = useCallback(async () => {
    await reset();

    const data = getValues();
    onSubmit(data);
  }, [onSubmit]);

  useEffect(() => {
    if (!searchOpen && isSearchPhraseFilled) {
      handleResetCallback();
    }
  }, [searchOpen]);

  return (
    <>
      <Fade in={searchOpen} unmountOnExit>
        <Box
          component="form"
          sx={styles.searchForm}
          onSubmit={handleSubmit(handleSubmitCallback)}
          noValidate
          autoComplete="off"
        >
          <Box display="flex" alignItems="center">
            <label htmlFor="search" className="visuallyHidden">
              {translate('searchBar.inputLabel')}
            </label>
            <TextField
              {...searchPhraseProps}
              id="search"
              sx={styles.searchInput}
              name={SearchFormFields.searchPhrase}
              placeholder={textFieldPlaceholder}
              variant="outlined"
              size="small"
              inputRef={refSearchPhrase}
              InputProps={{
                ...(isSearchPhraseFilled && {
                  endAdornment: (
                    <IconButton
                      onClick={handleResetCallback}
                      size="small"
                      data-testid="searchPhrase-clear"
                      aria-label={translate('searchBar.buttonClear')}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }),
              }}
            />
            <Button type="submit" variant="contained">
              {translate('searchBar.buttonSubmit')}
            </Button>
          </Box>
        </Box>
      </Fade>
      <Tooltip
        onClick={handleToggleSearch}
        sx={styles.searchIcon}
        title={translate('searchBar.buttonOpenTooltip')}
        arrow
        ref={tooltipRef}
      >
        <IconButton aria-label={translate('searchBar.buttonOpenTooltip')} size="large">
          <SearchIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
