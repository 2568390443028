import { SxProps, Theme } from '@mui/material';

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}

export type Styles = SxProps<Theme>;

export enum ExecutionType {
  query = 'query',
  mutation = 'mutation',
}
