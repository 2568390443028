import { LabelDisplayedRowsArgs, TablePagination, TablePaginationProps } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import * as styles from './Pagination.styles';

export const Pagination = ({
  count,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  ...props
}: TablePaginationProps) => {
  const translate = useTranslator();

  const formatDisplayedRowsLabel = ({ from, to, count }: LabelDisplayedRowsArgs) =>
    `${from}-${to} ${translate('global.displayedRowsOf')} ${count}`;

  return (
    <TablePagination
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      sx={styles.pagination}
      component="div"
      labelDisplayedRows={(labelDisplayedRowsArgs) => formatDisplayedRowsLabel(labelDisplayedRowsArgs)}
      labelRowsPerPage={translate('global.rowsPerPage')}
      getItemAriaLabel={(type) => {
        if (type === 'previous') {
          return translate('global.previousPage');
        }
        if (type === 'next') {
          return translate('global.nextPage');
        }

        return '';
      }}
      SelectProps={{
        inputProps: {
          'aria-label': translate('global.rowsPerPage'),
          'aria-labelledby': 'rowsPerPage',
        },
        id: 'rowsPerPage',
      }}
    />
  );
};
