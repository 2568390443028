import { Redirect, Route } from 'react-router-dom';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useDefaultRoute } from 'hooks/useDefaultRoute/useDefaultRoute';
import { Loader } from 'ui/loader/Loader';

import { PreventAuthorizedRouteProps } from './PreventAuthorizedRoute.types';

export const PreventAuthorizedRoute = (props: PreventAuthorizedRouteProps) => {
  const { component: Component, preventAuthorized, ...otherProps } = props;

  const { isAuthorized, isAuthorizing } = useAuthState();

  const defaultRoute = useDefaultRoute();

  if (!Component) return null;

  if (isAuthorizing) {
    return <Loader />;
  }

  if (isAuthorized && preventAuthorized) {
    return <Redirect to={defaultRoute} />;
  }

  return <Route {...otherProps} render={(props) => <Component {...props} />} />;
};
