import { useCallback, useRef } from 'react';
import { useQueryClient } from 'react-query';

import { useChangeEmail } from 'hooks/email/useChangeEmail/useChangeEmail';
import { ChangeEmailPayload } from 'api/actions/email/email.types';
import { Queries } from 'api/queries/queries.enum';

import { ProfileData } from './ProfileData';
import { ProfileDataRef } from './ProfileData.types';

export const ProfileDataContainer = () => {
  const queryClient = useQueryClient();

  const mutationChangeEmail = useChangeEmail();
  const { error, mutateAsync } = mutationChangeEmail;

  const profileDataRef = useRef<ProfileDataRef>();

  const onSubmit = useCallback(
    async (body: ChangeEmailPayload): Promise<boolean> => {
      try {
        await mutateAsync(body);
        await queryClient.invalidateQueries(Queries.getUserMe);

        profileDataRef.current?.handleResetForm();
      } catch {
        return false;
      }

      return true;
    },
    [mutateAsync],
  );

  return <ProfileData error={error} onSubmit={onSubmit} ref={profileDataRef} />;
};
