import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { RestrictedSectionTooltip } from 'hoc/restrictedSectionTooltip/RestrictedSectionTooltip';

import { DeleteUserButtonProps } from './DeleteUserButton.types';

export const DeleteUserButton = ({ id, isCurrentlyLoggedUser, hasRoles }: DeleteUserButtonProps) => {
  const translate = useTranslator();

  return (
    <RestrictedSectionTooltip
      isRestricted={hasRoles && !isCurrentlyLoggedUser}
      title={translate('CONSTRAINT.VALIDATION.USER_HAS_ROLES')}
    >
      <Button
        component={RouterLink}
        to={`/users/delete/${id}`}
        disabled={hasRoles || isCurrentlyLoggedUser}
        color="secondary"
        variant="contained"
      >
        {translate('global.buttonDelete')}
      </Button>
    </RestrictedSectionTooltip>
  );
};
