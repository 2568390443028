import { useCallback } from 'react';

import { useRemindPassword } from 'hooks/password/useRemindPassword/useRemindPassword';
import { RemindPasswordPayload } from 'api/actions/password/passwordActions.types';

import { PasswordRemind } from './PasswordRemind';

export const PasswordRemindContainer = () => {
  const mutationRemindPassword = useRemindPassword();
  const { error, isSuccess, mutateAsync } = mutationRemindPassword;

  const onSubmit = useCallback(
    async (body: RemindPasswordPayload): Promise<boolean> => {
      try {
        await mutateAsync(body);
      } catch {
        return false;
      }

      return true;
    },
    [mutateAsync],
  );

  return <PasswordRemind error={error} isSuccess={isSuccess} onSubmit={onSubmit} />;
};
