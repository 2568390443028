import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';

import { errorMessages } from 'api/errors/messages';
import { useCancelForm } from 'hooks/useCancelForm/useCancelForm';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { ChangePasswordPayload } from 'api/actions/password/passwordActions.types';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';
import { TextField } from 'ui/textField/TextField';

import { usePasswordChangeFormData } from './PasswordChangeFormDataSchema';
import { PasswordChangeFormData, PasswordChangeFormFields, PasswordChangeFormProps } from './PasswordChangeForm.types';
import * as styles from './PasswordChangeForm.styles';

export const PasswordChangeForm = forwardRef((props: PasswordChangeFormProps, ref) => {
  const { disabled, error, isError, numberOfStoredPasswords, onSubmit, passwordSyntax, redirectOnCancel } = props;

  const translate = useTranslator();

  const history = useHistory();

  const changePasswordDataSchema = usePasswordChangeFormData({ passwordSyntax, numberOfStoredPasswords });

  const methods = useForm<PasswordChangeFormData>({
    resolver: yupResolver(changePasswordDataSchema),
    mode: 'onChange',
  });

  const {
    formState: { isDirty, isSubmitting, touchedFields },
    handleSubmit,
    setError,
    reset,
    trigger,
    watch,
  } = methods;

  const oldPassword = watch(PasswordChangeFormFields.oldPassword);
  const newPassword = watch(PasswordChangeFormFields.newPassword);
  const newPasswordRepeated = watch(PasswordChangeFormFields.newPasswordRepeated);
  const isAnyFieldTouched = Object.values(touchedFields).length > 0;

  useEffect(() => {
    if (isAnyFieldTouched) {
      trigger(PasswordChangeFormFields.newPassword);
    }
  }, [oldPassword]);

  useEffect(() => {
    if (isAnyFieldTouched) {
      trigger(PasswordChangeFormFields.newPasswordRepeated);
    }
  }, [newPassword]);

  useEffect(() => {
    if (isAnyFieldTouched) {
      trigger(PasswordChangeFormFields.newPassword);
    }
  }, [newPasswordRepeated]);

  useConfirmFormAbort(isDirty);

  const serverErrors: string[] | Record<string, string> = error?.response?.data?.errors;
  const [isServerErrorsListVisible, setIsServerErrorsListVisible] = useState(false);

  const handleSubmitCallback = useCallback(
    async (body: ChangePasswordPayload) => {
      await onSubmit(body);
    },
    [onSubmit],
  );

  useEffect(() => {
    if (isError) {
      const errorType = 'server';

      setIsServerErrorsListVisible(true);

      if (!Array.isArray(serverErrors)) {
        return;
      }

      if (serverErrors.includes(errorMessages.password.oldPasswordNotValid)) {
        setIsServerErrorsListVisible(false);

        setError(PasswordChangeFormFields.oldPassword, {
          type: errorType,
          message: translate('CONSTRAINT.VALIDATION.USER.PASSWORD.NOT_VALID_OLD_PASSWORD'),
        });
      }

      if (serverErrors.includes(errorMessages.password.passwordUsed)) {
        setIsServerErrorsListVisible(false);

        setError(PasswordChangeFormFields.newPassword, {
          type: errorType,
          message: translate('PASSWORD.HISTORY.VALIDATION.USER.PASSWORD.USED'),
        });
      }
    }
  }, [isError]);

  useImperativeHandle(ref, () => ({
    handleResetForm() {
      reset();
    },
  }));

  const cancelFormCallback = () => {
    if (redirectOnCancel) {
      history.push(redirectOnCancel.to);
    }
  };

  const { cancelForm } = useCancelForm(cancelFormCallback);

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(handleSubmitCallback)} sx={styles.changePasswordForm}>
        <TextField<PasswordChangeFormData>
          type="password"
          name={PasswordChangeFormFields.oldPassword}
          label={translate('global.oldPassword')}
          sx={styles.textField}
        />

        <TextField<PasswordChangeFormData>
          type="password"
          name={PasswordChangeFormFields.newPassword}
          label={translate('global.newPassword')}
          sx={styles.textField}
        />

        <TextField<PasswordChangeFormData>
          type="password"
          name={PasswordChangeFormFields.newPasswordRepeated}
          label={translate('global.newPasswordRepeated')}
          sx={styles.textField}
        />

        {isServerErrorsListVisible && <FormServerErrors serverErrors={serverErrors} sx={styles.serverErrorsList} />}

        <Box sx={styles.buttonBox}>
          {redirectOnCancel && (
            <Button onClick={cancelForm} sx={styles.backButton} color="inherit">
              {translate('passwordChangeForm.cancelChangePassword')}
            </Button>
          )}

          <Button disabled={isSubmitting || disabled} type="submit" variant="contained" color="primary">
            {translate('passwordChangeForm.submitChangePassword')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
});
