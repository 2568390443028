import { umsApi } from 'api/axios/axiosInstance';

import { CheckImportStatusResponse, ImportActionPayload } from './impportActions.types';

export const checkImportStatus = async <T extends ImportActionPayload>(
  payload: T,
): Promise<CheckImportStatusResponse> => {
  const { url } = payload;

  const response = await umsApi.get(url);

  return response.data;
};

export const getImportValidationResult = async <T extends ImportActionPayload, U>(payload: T): Promise<U> => {
  const { url } = payload;

  const response = await umsApi.get(url);

  return response.data;
};

export const initImport = async <T extends ImportActionPayload>(payload: T): Promise<void> => {
  const { url } = payload;

  const response = await umsApi.post(url);

  return response.data;
};
