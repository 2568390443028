import { useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, InputAdornment } from '@mui/material';

import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { ChangeEmailPayload } from 'api/actions/email/email.types';
import { TextField } from 'ui/textField/TextField';
import { FormServerErrors } from 'ui/formServerErrors/FormServerErrors';

import { useProfileDataSchema } from './ProfileDataSchema';
import { FormFields, ProfileDataForm, ProfileDataProps } from './ProfileData.types';
import { NonEditableProfileDataItem as NonEditableProfileDataItemType } from './nonEditableProfileDataItem/nonEditableProfileDataItem.types';
import { NonEditableProfileDataItem } from './nonEditableProfileDataItem/nonEditableProfileDataItem';
import * as styles from './ProfileData.styles';

export const ProfileData = forwardRef((props: ProfileDataProps, ref) => {
  const translate = useTranslator();

  const { user } = useAuthState();

  const [isVisibleConfirmation, setIsVisibleConfirmation] = useState(false);

  const { error, onSubmit } = props;

  const serverErrors: string[] | Record<string, string> = error?.response?.data?.errors;

  const profileDataSchema = useProfileDataSchema();
  const methods = useForm<ProfileDataForm>({
    defaultValues: {
      email: user?.email,
    },
    resolver: yupResolver(profileDataSchema),
  });
  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    getValues,
    reset,
    setValue,
  } = methods;

  useConfirmFormAbort(isDirty);

  useImperativeHandle(ref, () => ({
    handleResetForm() {
      setIsVisibleConfirmation(false);
    },
  }));

  const handleSubmitCallback = useCallback(
    async (body: ChangeEmailPayload) => {
      if (isVisibleConfirmation) {
        await onSubmit(body);
      } else {
        setIsVisibleConfirmation(true);
      }
    },
    [isVisibleConfirmation, onSubmit],
  );

  const handleAbortChangeEmail = () => {
    reset();
    setIsVisibleConfirmation(false);
  };

  const emailConfirmHadler = () => {
    if (!user?.email) {
      return;
    }

    if (getValues(FormFields.email) !== user.email) {
      setValue(FormFields.email, user.email);
    }
  };

  useEffect(() => {
    emailConfirmHadler();
  }, [user?.email, user?.notConfirmedEmail]);

  if (!user) {
    return <></>;
  }

  const { lastName, firstName, notConfirmedEmail, login } = user;

  const nonEditableProfileDataItems: NonEditableProfileDataItemType[] = [
    {
      id: 'lastName',
      label: translate('global.lastName'),
      value: lastName,
    },
    {
      id: 'firstName',
      label: translate('global.firstName'),
      value: firstName,
    },
    {
      id: 'login',
      label: translate('global.login'),
      value: login,
    },
    {
      id: 'notConfirmedEmail',
      label: translate('profile.notConfirmedEmail'),
      value: notConfirmedEmail || '',
    },
  ];

  const notConfirmedEmailItem = nonEditableProfileDataItems[3];

  return (
    <Box display="flex" flexDirection="column">
      {nonEditableProfileDataItems.slice(0, 3).map(({ id, label, value }) => (
        <NonEditableProfileDataItem key={id} id={id} label={label} value={value} />
      ))}

      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(handleSubmitCallback)} sx={styles.form}>
          <TextField<ProfileDataForm> label={translate('global.email')} name={FormFields.email} sx={styles.textField} />

          {notConfirmedEmail && (
            <NonEditableProfileDataItem
              id={notConfirmedEmailItem.id}
              value={notConfirmedEmailItem.value}
              label={notConfirmedEmailItem.label}
              endAdornment={
                <InputAdornment position="end" sx={styles.notConfirmedEmailAdornment}>
                  {translate('profile.notConfirmedEmailInputAdornment')}
                </InputAdornment>
              }
            />
          )}

          {isVisibleConfirmation && (
            <Alert severity="success" sx={styles.confirmation}>
              {translate('profile.changeEmailConfirmationAlert', { email: getValues(FormFields.email) })}
            </Alert>
          )}

          {serverErrors && <FormServerErrors serverErrors={serverErrors} sx={styles.serverErrorsList} />}

          <Box sx={styles.buttonBox}>
            {isVisibleConfirmation && (
              <Button onClick={handleAbortChangeEmail} sx={styles.abortButton}>
                {translate('profile.abortChangeEmail')}
              </Button>
            )}

            <Button
              disabled={isSubmitting || !isDirty}
              type="submit"
              color="primary"
              variant="contained"
              sx={styles.editProfileButton}
            >
              {translate('profile.submitChangeEmail')}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
});
