import { useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import parseHtml from 'html-react-parser';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { User } from 'api/actions/users/usersActions.types';
import { useFormatUserStatus } from 'hooks/format/useFormatUserStatus/useFormatUserStatus';

import { ImportDataPreviewProps } from './ImportDataPreview.types';

const previewDataLimit = 20;

export const ImportDataPreview = ({ data, totalItemsCount, totalErrorsCount }: ImportDataPreviewProps) => {
  const translate = useTranslator();

  const formatUserStatus = useFormatUserStatus();

  const columns: { id: DotNestedKeys<User>; label: string }[] = [
    { id: 'firstName', label: translate('global.firstName') },
    { id: 'lastName', label: translate('global.lastName') },
    { id: 'bindingKey.key', label: translate('global.bindingKeyType') },
    { id: 'bindingKey.value', label: translate('global.bindingKeyValue') },
    { id: 'email', label: translate('global.email') },
    { id: 'status', label: translate('global.status') },
  ];

  const isDescriptionVisible = useMemo(() => {
    if (data.length < previewDataLimit) {
      return false;
    }

    if (totalErrorsCount === 0) {
      return totalItemsCount > previewDataLimit;
    }

    return totalItemsCount - totalErrorsCount > previewDataLimit;
  }, [data.length, totalItemsCount, totalErrorsCount]);

  return (
    <section>
      {isDescriptionVisible && (
        <Box mb={2}>
          <Typography>{translate('usersImport.errorValidation.importDataPreview.description')}</Typography>
        </Box>
      )}

      <Paper>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate('global.no')}</TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label.toLowerCase()}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  {columns.map((column) => {
                    if (column.id === 'bindingKey.key') {
                      return <TableCell key={column.id}>{row.bindingKey?.key}</TableCell>;
                    }

                    if (column.id === 'bindingKey.value') {
                      return <TableCell key={column.id}>{row.bindingKey?.value}</TableCell>;
                    }

                    if (column.id === 'status') {
                      return (
                        <TableCell key={column.id}>
                          {row.status && formatUserStatus(row.status).toLowerCase()}
                        </TableCell>
                      );
                    }

                    return <TableCell key={column.id}>{row[column.id as keyof User]}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box mt={2} component="footer">
        <Typography paragraph align="right">
          {parseHtml(translate('usersImport.errorValidation.importDataPreview.summary', { totalItemsCount }))}
        </Typography>
      </Box>
    </section>
  );
};
