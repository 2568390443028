import { useCallback, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormHelperText, Paper, Typography } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

import { errorMessages } from 'api/errors/messages';
import { getAppConfig } from 'utils/config';
import { initRecaptchaObserver } from 'utils/helpers/recaptchaObserver/recaptchaObserver';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { LoginPayload } from 'api/actions/auth/authActions.types';
import { TextField } from 'ui/textField/TextField';
import { isTranslationAvailable } from 'utils/function/isTranslationAvailable/isTranslationAvailable';

import { useLoginFormSchema } from './LoginFormSchema';
import { FormFields, LoginForm as LoginFormType, LoginFormProps } from './LoginForm.types';
import * as styles from './LoginForm.styles';

export const LoginForm = ({ disabled, enableReCaptcha, error, formTitle, formSubTitle, onSubmit }: LoginFormProps) => {
  const translate = useTranslator();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const { pathname, search } = useLocation();

  const loginFormSchema = useLoginFormSchema();
  const methods = useForm<LoginFormType>({
    resolver: yupResolver(loginFormSchema),
    mode: 'onChange',
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setError,
  } = methods;

  const handleHideRecaptcha = () => {
    reset(undefined, { keepValues: true });
    reCaptchaRef.current?.reset();
  };

  const handleSubmitCallback = useCallback(
    async (body: LoginPayload) => {
      initRecaptchaObserver(handleHideRecaptcha);

      const token = await reCaptchaRef.current?.executeAsync();
      reCaptchaRef.current?.reset();

      const bodyWithToken = {
        ...body,
        token,
      };

      const valid = await onSubmit(bodyWithToken);

      if (!valid) {
        const errorType = 'server';

        setError(FormFields.login, { type: errorType });
        setError(FormFields.password, { type: errorType });
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    if (error?.response?.data?.error === errorMessages.user.notActive) {
      reset();
    }

    if (error?.response?.status === 429) {
      handleSubmit(handleSubmitCallback)();
    }
  }, [error]);

  const renderErrorMessage = () => {
    const messages: string[] = error?.response?.data?.errors || [];
    const hasMessages = messages?.length > 0;

    if (error?.response?.status === 429) {
      return null;
    }

    if (hasMessages) {
      return messages.map((message) => {
        if (isTranslationAvailable(message)) {
          return (
            <FormHelperText error sx={styles.errorMessage}>
              {translate(message)}
            </FormHelperText>
          );
        }

        return (
          <FormHelperText error sx={styles.errorMessage}>
            {message}
          </FormHelperText>
        );
      });
    }

    if (error) {
      return (
        <FormHelperText error sx={styles.errorMessage}>
          {translate('global.errorMessage')}
        </FormHelperText>
      );
    }

    return null;
  };

  return (
    <FormProvider {...methods}>
      {enableReCaptcha && (
        <ReCAPTCHA size="invisible" ref={reCaptchaRef} sitekey={getAppConfig('RECAPTCHA_SITE_KEY')} />
      )}

      <Paper component="form" onSubmit={handleSubmit(handleSubmitCallback)} sx={styles.loginForm}>
        <Typography variant="body1" component="h4" sx={styles.formTitle}>
          {formTitle}
        </Typography>
        <Typography variant="h5" component="h2" sx={styles.formSubTitle}>
          {formSubTitle}
        </Typography>

        <TextField<LoginFormType>
          name={FormFields.login}
          label={translate('loginForm.userLogin')}
          sx={styles.textField}
          fullWidth
        />
        <TextField<LoginFormType>
          type="password"
          name={FormFields.password}
          label={translate('loginForm.userPassword')}
          sx={styles.textField}
          fullWidth
        />

        {renderErrorMessage()}

        <Box mb={2} textAlign="right">
          <Typography
            variant="body2"
            component={RouterLink}
            to={{ pathname: AppRoute.passwordRemind, state: { from: pathname, search } }}
            sx={styles.passwordRemind}
          >
            {translate('loginForm.passwordRemind')}
          </Typography>
        </Box>

        <Button disabled={isSubmitting || disabled} type="submit" variant="contained" color="primary" fullWidth>
          {translate('loginForm.submitLogin')}
        </Button>
      </Paper>
    </FormProvider>
  );
};
