import { Box, Typography } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { RolesTable } from './rolesTable/RolesTable';

export const Roles = () => {
  const translate = useTranslator();

  return (
    <>
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          {translate('roles.pageTitle')}
        </Typography>
      </Box>

      <RolesTable />
    </>
  );
};
