import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getUsers } from 'api/actions/users/usersActions';
import { Queries } from 'api/queries/queries.enum';
import { UsersPayload, UsersResponse } from 'api/actions/users/usersActions.types';

export const useGetUsers = (params: UsersPayload) => {
  const queryGetUser = useQuery<UsersResponse, AxiosError>([Queries.getUsers, params], () => getUsers(params), {
    keepPreviousData: true,
  });

  return queryGetUser;
};
