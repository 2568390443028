import { usePermissions } from 'hooks/usePermissions/usePermissions';

import { FormServerErrorsProps } from './FormServerErrors.types';
import { WithoutPermissions } from './WithoutPermissions';
import { WithPermissions } from './WithPermissions';

export const FormServerErrors = (props: FormServerErrorsProps) => {
  const { hasPermissions } = usePermissions();

  if (hasPermissions) {
    return <WithPermissions {...props} data-testid="form-server-erros-with-permissions" />;
  }

  return <WithoutPermissions {...props} data-testid="form-server-erros-without-permissions" />;
};
