import yup from 'utils/yup';
import { regExp } from 'utils/regExp';
import { useTranslator } from 'hooks/useTranslator/useTranslator';

export const useProfileDataSchema = () => {
  const translate = useTranslator();

  return yup.object().shape({
    email: yup
      .string()
      .required(translate('global.fieldRequired'))
      .matches(regExp.email, translate('global.fieldWrongFormat'))
      .max(250, translate('global.fieldMaxLength', { fieldName: translate('global.email'), value: 250 })),
  });
};
