import { HTMLAttributes } from 'react';
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { isInstitutionRequired } from 'utils/function/isInstitutionRequired/isInstitutionRequired';
import { useCheckRestrictedRoles } from 'hooks/useCheckRestrictedRoles/useCheckRestrictedRoles';
import { useFormatDate } from 'hooks/format/useFormatDate/useFormatDate';
import { useGetInstitutionsShortNames } from 'hooks/institutions/useGetInstitutionsShortNames/useGetInstitutionsShortNames';
import { useGetRoles } from 'hooks/useGetRoles/useGetRoles';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { Autocomplete } from 'ui/autocomplete/Autocomplete';
import { FormFields } from '../UsersForm.enum';
import { RoleOption } from 'ui/autocomplete/roleOption/RoleOption';
import { Role } from 'api/actions/roles/rolesActions.types';
import { InstitutionShortName } from 'api/actions/institutions/institutionsActions.types';
import * as styles from '../UsersForm.styles';

import { UsersFormRolesProps } from './UsersFormRoles.types';

export const UsersFormRoles = ({ isEditForm, isNextStepLoading, onClickBack, onClickNext }: UsersFormRolesProps) => {
  const translate = useTranslator();

  const { formatDate } = useFormatDate();
  const { checkIsRestrictedRole } = useCheckRestrictedRoles();

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = useFormContext();

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'roles',
  });

  const queryGetRoles = useGetRoles({
    itemsPerPage: 50,
  });

  const queryInstitutions = useGetInstitutionsShortNames({
    itemsPerPage: 100,
  });

  return (
    <form>
      <Paper sx={styles.step}>
        <Box sx={styles.rolesHeader}>
          <Typography component="h2" variant="h5" sx={styles.rolesTitle}>
            {translate('usersForm.userRoles')}{' '}
            <Box component="span" sx={styles.userName}>
              {getValues(FormFields.firstName)} {getValues(FormFields.lastName)}
            </Box>
          </Typography>

          <Button
            sx={styles.addRole}
            onClick={() => {
              append({ role: null, institution: null });
            }}
            color="primary"
            variant="outlined"
            startIcon={<AddIcon />}
            data-testid="add-role"
          >
            {translate('global.buttonAdd')}
          </Button>
        </Box>

        {fields.length > 0 && (
          <Box component="ul" sx={styles.userRoles} data-testid="role-list">
            {fields.map((item, index) => {
              const roleSelected = watch(`roles.${index}.role` as const);
              const isAdminRoleSelected = !!roleSelected && isInstitutionRequired(roleSelected);
              const modificationDate = getValues(`roles.${index}.role` as const)?.modificationDate;

              const isUserRoleRestricted = !!roleSelected && checkIsRestrictedRole(roleSelected);

              const roleOptions = queryGetRoles.data ? queryGetRoles.data.items : [];
              const institutionOptions = queryInstitutions.data ? queryInstitutions.data.items : [];

              return (
                <Box component="li" sx={styles.userRole} key={item.id}>
                  <Controller
                    render={({ field }) => (
                      <Autocomplete<Role>
                        {...field}
                        id="role"
                        disabled={isUserRoleRestricted}
                        loading={queryGetRoles.isLoading}
                        size="small"
                        options={roleOptions}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <RoleOption option={option} />
                          </li>
                        )}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        getOptionDisabled={(option) => checkIsRestrictedRole(option)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate('usersForm.userRole')}
                            placeholder={translate('usersForm.userRole')}
                            variant="outlined"
                            error={!!errors?.roles && !!errors?.roles[index]?.role}
                            helperText={!!errors?.roles && (errors?.roles[index]?.role as FieldError)?.message}
                          />
                        )}
                        onChange={(_, role) => {
                          if (!!role && isInstitutionRequired(role)) {
                            setValue(`roles.${index}.institution` as const, null);
                            clearErrors(`roles.${index}.institution` as const);
                          }
                          field.onChange(role);
                          trigger('roles');
                        }}
                        sx={styles.role}
                        data-testid={`role-autocomplete-${index}`}
                        ListboxProps={{ 'data-testid': `role-list-${index}` } as HTMLAttributes<HTMLUListElement>}
                      />
                    )}
                    name={`roles.${index}.role` as const}
                    control={control}
                  />

                  <Controller
                    render={({ field }) => (
                      <Autocomplete<InstitutionShortName>
                        {...field}
                        id="institution"
                        disabled={isAdminRoleSelected || isUserRoleRestricted}
                        loading={queryInstitutions.isLoading}
                        size="small"
                        options={institutionOptions}
                        getOptionLabel={(option) => option.shortName}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate('usersForm.userInstitution')}
                            placeholder={translate('usersForm.userInstitution')}
                            variant="outlined"
                            error={!!errors?.roles && !!errors?.roles[index]?.institution}
                            helperText={!!errors?.roles && (errors?.roles[index]?.institution as FieldError)?.message}
                          />
                        )}
                        onChange={(_, data) => {
                          field.onChange(data);
                          trigger('roles');
                        }}
                        sx={styles.institution}
                        data-testid={`institution-autocomplete-${index}`}
                        ListboxProps={
                          { 'data-testid': `institution-list-${index}` } as HTMLAttributes<HTMLUListElement>
                        }
                      />
                    )}
                    name={`roles.${index}.institution` as const}
                    control={control}
                  />
                  {isEditForm && (
                    <Box sx={styles.modificationDateWrapper}>
                      {!!modificationDate && (
                        <Box sx={styles.modificationDateContainer}>
                          <Box component="span" sx={styles.modificationDateTitle}>
                            {translate('global.modificationDate')}
                          </Box>
                          {formatDate(modificationDate)}
                        </Box>
                      )}
                    </Box>
                  )}
                  <Tooltip title={translate('global.buttonDelete')} arrow>
                    <IconButton
                      sx={styles.deleteUsersRole}
                      onClick={() => {
                        remove(index);
                        trigger('roles');
                      }}
                      aria-label={translate('global.buttonDelete')}
                      disabled={isUserRoleRestricted}
                      data-testid={`user-role-delete-${index}`}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        )}

        <Button
          sx={styles.addRoleMobile}
          onClick={() => {
            append({ role: null, institution: null });
          }}
          color="primary"
          startIcon={<AddIcon />}
          data-testid="add-role-mobile"
        >
          {translate('global.buttonAdd')}
        </Button>
      </Paper>

      <Box sx={styles.buttonBox}>
        <Button onClick={onClickBack} color="inherit">
          {translate('global.buttonBack')}
        </Button>

        <LoadingButton
          loading={isEditForm && isNextStepLoading}
          onClick={onClickNext}
          variant="contained"
          color="primary"
        >
          {translate('global.buttonNext')}
        </LoadingButton>
      </Box>
    </form>
  );
};
