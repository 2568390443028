import { TableCell, TableHead, TableRow } from '@mui/material';

import { permissions } from 'utils/permissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AuthorizedSection } from 'hoc/authorizedSection/AuthorizedSection';

export const RolesTableHeader = () => {
  const translate = useTranslator();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{translate('global.no')}</TableCell>
        <TableCell>{translate('roles.roleName')}</TableCell>
        <TableCell>{translate('roles.roleCreationDate')}</TableCell>
        <TableCell>{translate('roles.roleBuiltIn')}</TableCell>
        <AuthorizedSection allowedPermissions={permissions.usersListView}>
          <TableCell component="td" />
        </AuthorizedSection>
      </TableRow>
    </TableHead>
  );
};
