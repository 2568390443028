import { Toolbar } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { SearchBar } from 'ui/table/toolbar/searchBar/SearchBar';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { InstitutionsTableToolbarProps, SearchFormData } from './InstitutionsTableProps.types';

export const InstitutionsTableToolbar = ({ onSubmitSearchPhrase }: InstitutionsTableToolbarProps) => {
  const translate = useTranslator();

  const handleSubmitSearchForm = ({ searchPhrase }: SearchFormData) => {
    onSubmitSearchPhrase(searchPhrase);
  };

  return (
    <Toolbar sx={styles.toolbar}>
      <SearchBar onSubmit={handleSubmitSearchForm} textFieldPlaceholder={translate('institutions.searchPlaceholder')} />
    </Toolbar>
  );
};
