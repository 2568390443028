import { useCallback, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { setAuthorized, setUnauthorized, startAuthorizing } from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/auth/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useDefaultRoute } from 'hooks/useDefaultRoute/useDefaultRoute';
import { useGetUserMe } from 'hooks/users/useGetUserMe/useGetUserMe';
import { useLogin } from 'hooks/auth/useLogin/useLogin';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { AuthModeName } from 'context/auth/authContext/AuthContext.enum';
import { ExecutionType } from '@typings/common';
import { LoginForm } from 'ui/loginForm/LoginForm';
import { LoginPayload } from 'api/actions/auth/authActions.types';

import { LoginContainerProps } from './LoginContainer.types';

export const LoginContainer = (props: LoginContainerProps) => {
  const translate = useTranslator();

  const dispatch = useAuthDispatch();
  const { isAuthorized } = useAuthState();
  const { hasAccessOnlyPasswordChange } = usePermissions();

  const defaultRoute = useDefaultRoute();

  const [isTooManyRequestsError, setIsTooManyRequestsError] = useState(false);

  const mutationLogin = useLogin();
  const mutationGetUserMe = useGetUserMe({ executionType: ExecutionType.mutation });

  const onSubmit = useCallback(
    async (body: LoginPayload): Promise<boolean> => {
      try {
        dispatch(startAuthorizing());

        await mutationLogin.mutateAsync(body);

        const user = await mutationGetUserMe.mutateAsync();

        if (user) {
          window.scrollTo(0, 0);

          toast.success(translate('login.success'));

          dispatch(setAuthorized({ name: AuthModeName.default }, user));
        }

        return true;
      } catch (error) {
        const isTooManyRequestsError = (error as AxiosError)?.response?.status === 429;

        if (isTooManyRequestsError) {
          setIsTooManyRequestsError(true);
        } else {
          setIsTooManyRequestsError(false);
        }

        dispatch(setUnauthorized());

        return false;
      }
    },
    [dispatch, mutationLogin.mutateAsync, mutationGetUserMe.mutateAsync],
  );

  if (hasAccessOnlyPasswordChange) {
    return <Redirect to={AppRoute.passwordChange} />;
  }

  if (isAuthorized) {
    const getRouteToRedirect = () => {
      return defaultRoute;
    };

    return <Redirect to={getRouteToRedirect()} />;
  }

  return (
    <LoginForm
      error={mutationLogin.error}
      formTitle={translate('login.formTitle')}
      formSubTitle={translate('login.formSubTitle')}
      onSubmit={onSubmit}
      enableReCaptcha={isTooManyRequestsError}
    />
  );
};
