import { Box, CircularProgress } from '@mui/material';

import { LoaderProps } from './Loader.types';
import * as styles from './Loader.styles';

export const Loader = ({ children, ...props }: LoaderProps) => (
  <Box sx={styles.wrapper} {...props}>
    <CircularProgress />
    {children}
  </Box>
);
