import { forwardRef, useImperativeHandle, useState } from 'react';
import { IconButton, Popover, PopoverProps, Tooltip, Zoom } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { useTranslator } from 'hooks/useTranslator/useTranslator';
import * as styles from 'ui/table/toolbar/TableToolbar.styles';

import { FiltersPopoverProps } from './FiltersPopover.types';

export const FiltersPopover = forwardRef((props: FiltersPopoverProps & Partial<PopoverProps>, ref) => {
  const translate = useTranslator();

  const { children, ...popoverProps } = props;

  const [anchorFiltersPopover, setAnchorFiltersPopover] = useState<HTMLButtonElement | null>(null);
  const filtersOpen = Boolean(anchorFiltersPopover);
  const filtersPopoverId = filtersOpen ? 'filters-popover' : undefined;

  const handleOpenFiltersPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorFiltersPopover(event.currentTarget);
  };

  const handleCloseFiltersPopover = () => {
    setAnchorFiltersPopover(null);
  };

  useImperativeHandle(ref, () => ({
    handleCloseFiltersPopover() {
      handleCloseFiltersPopover();
    },
  }));

  return (
    <>
      <Tooltip title={translate('filtersPopover.openFiltersButton')} arrow TransitionComponent={Zoom}>
        <IconButton
          aria-describedby={filtersPopoverId}
          onClick={handleOpenFiltersPopover}
          aria-label={translate('filtersPopover.openFiltersButton')}
          size="large"
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover
        {...popoverProps}
        id={filtersPopoverId}
        open={filtersOpen}
        anchorEl={anchorFiltersPopover}
        onClose={handleCloseFiltersPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ sx: styles.filterForm }}
        TransitionComponent={Zoom}
      >
        {children}
      </Popover>
    </>
  );
});
