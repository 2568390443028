import { useMemo, ReactNode, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';

import { beaconLogoutAction } from 'api/actions/auth/authActions';
import { endpoints } from 'api/endpoints/endpoints';
import { getAppConfig } from 'utils/config';
import { setUnauthorized } from 'context/auth/authActionCreators/authActionCreators';
import { umsApi } from 'api/axios/axiosInstance';
import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { useAuthDispatch } from 'hooks/auth/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/auth/useAuthState/useAuthState';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { isPathWhiteListed } from 'utils/function/isPathWhiteListed/isPathWhiteListed';

const errorHandlerWhiteList: string[] = [endpoints.passwords.startupPassword(':id')];

const statusRedirects: Record<number, AppRoute> = {
  404: AppRoute.notFound,
};

export const AxiosController = ({ children }: { children: ReactNode }) => {
  const translate = useTranslator();

  const history = useHistory();

  const dispatch = useAuthDispatch();
  const { user } = useAuthState();

  const queryClient = useQueryClient();

  const confirmFormAbort = useConfirmFormAbort();

  umsApi.defaults.baseURL = getAppConfig('API_URL');

  const shouldDisplaySessionExpirationToast = useRef(false);

  const responseInterceptor = useRef<number | null>(null);

  const responseSuccessHandler = (response: AxiosResponse) => {
    if (user) {
      shouldDisplaySessionExpirationToast.current = true;
    }

    return response;
  };

  const shouldSetUserAsUnathorized = (status?: number) => {
    if (status === 401) {
      return true;
    }

    if (status === 403) {
      return true;
    }

    return false;
  };

  const showToast = (status?: number) => {
    toast.dismiss();

    if (status === 401) {
      toast.warning(translate('global.sessionExpiredToast'));
    }

    if (status === 403) {
      beaconLogoutAction();

      toast.warning(translate('global.accessForbiddenToast'));
    }

    shouldDisplaySessionExpirationToast.current = false;
  };

  const setUserAsUnathorized = (status?: number) => {
    if (user) {
      queryClient.clear();

      confirmFormAbort.disable();

      if (shouldDisplaySessionExpirationToast.current) {
        showToast(status);
      }
    }

    return dispatch(setUnauthorized());
  };

  const responseErrorHandler = (error: AxiosError) => {
    const status = error?.response?.status;
    const responsePathname: string = error?.response?.config?.url || '';

    if (shouldSetUserAsUnathorized(status)) {
      setUserAsUnathorized(status);
    }

    if (!isPathWhiteListed(errorHandlerWhiteList, responsePathname)) {
      if (status && Object.keys(statusRedirects).includes(String(status))) {
        confirmFormAbort.disable();
        history.push(statusRedirects[status]);
      }
    }

    return Promise.reject(error);
  };

  useMemo(() => {
    if (!!responseInterceptor.current || responseInterceptor.current === 0) {
      umsApi.interceptors.response.eject(responseInterceptor.current);
    }

    responseInterceptor.current = umsApi.interceptors.response.use(
      (response) => responseSuccessHandler(response),
      (error) => responseErrorHandler(error),
    );
  }, [dispatch, user]);

  return <>{children}</>;
};
