import { Styles } from '@typings/common';

export const confirmTitle: Styles = {
  fontWeight: 700,
  color: 'error.main',
};

export const buttonBox: Styles = {
  display: 'flex',
  flexDirection: { xs: 'row-reverse', md: 'row' },
  justifyContent: 'flex-end',
  mt: 2,
};

export const backButton: Styles = {
  mr: 1,
};

export const formControlError: Styles = {
  color: 'error.main',
};

export const errorHasNonAssignableRoles: Styles = {
  display: 'inline-flex',
};
