import { useState } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableContainer } from '@mui/material';

import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useGetInstitutions } from 'hooks/institutions/useGetInstitutions/useGetInstitutions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { DataLoading } from 'ui/table/row/dataLoading/DataLoading';
import { DataLoadingError } from 'ui/table/row/dataLoadingError/DataLoadingError';
import { Institution } from 'api/actions/institutions/institutionsActions.types';
import { NoData } from 'ui/table/row/noData/NoData';
import { OrderDirection } from '@typings/common';
import { Pagination } from 'ui/table/pagination/Pagination';
import * as styles from 'ui/table/Table.styles';

import { InstitutionsTableHeader } from './institutionsTableHeader/InstitutionsTableHeader';
import { InstitutionsTableRow } from './institutionsTableRow/InstitutionsTableRow';
import { InstitutionsTableToolbar } from './institutionsTableToolbar/InstitutionsTableToolbar';

export const InstitutionsTable = () => {
  const translate = useTranslator();

  const [orderBy, setOrderBy] = useState<keyof Institution>();
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.asc);
  const [page, setPage] = useState(0);
  const paginationOptions = [30, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchPhrase, setSearchPhrase] = useState('');

  const queryParams = removeEmptyFields({
    pageNumber: page,
    itemsPerPage: rowsPerPage,
    orderBy,
    ...(orderBy && { orderDirection }),
    searchPhrase,
  });
  const { data, isError, isFetching, isLoading, isPreviousData } = useGetInstitutions(queryParams);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Institution) => {
    const isAsc = orderBy === property && orderDirection === OrderDirection.asc;
    setOrderDirection(isAsc ? OrderDirection.desc : OrderDirection.asc);
    setOrderBy(property);
    setPage(0);
  };

  const handleSubmitSearchPhrase = (searchPhrase: string) => {
    setSearchPhrase(searchPhrase);
    setPage(0);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <DataLoading data-testid="loader" />;
    }

    if (isError) {
      return <DataLoadingError />;
    }

    if (data) {
      return data.items.length > 0 ? (
        <>
          {data?.items.map((institution: Institution, index: number) => (
            <InstitutionsTableRow
              key={institution.id}
              institution={institution}
              index={(page + 1) * rowsPerPage - rowsPerPage + index}
            />
          ))}
        </>
      ) : (
        <NoData />
      );
    }
  };

  return (
    <Paper sx={styles.tableWrapper} data-testid="institutions-table">
      {isFetching && isPreviousData && <CircularProgress sx={styles.loader} data-testid="loader" />}

      <InstitutionsTableToolbar onSubmitSearchPhrase={handleSubmitSearchPhrase} />

      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table} stickyHeader aria-label={translate('institutions.pageTitle')} size="small">
          <InstitutionsTableHeader
            orderDirection={orderDirection}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody sx={styles.tableBody}>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>

      {data && (
        <Pagination
          count={data.totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={paginationOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
