import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';

import { EventLogParams, EventLogsPayload, EventLogsResponse } from './eventLogs.types';

export const getEventLogs = async (payload: EventLogsPayload): Promise<EventLogsResponse> => {
  const { filters, itemsPerPage = 30, pageNumber = 0, orderBy, orderDirection, searchPhrase } = payload;

  const params: EventLogParams = {
    items_per_page: itemsPerPage,
    page_number: pageNumber + 1,
    order_by: orderBy,
    order_direction: orderDirection,
    search_phrase: searchPhrase,
    context: filters?.context,
    event_timestamp_from: filters?.eventTimestampFrom,
    event_timestamp_to: filters?.eventTimestampTo,
    event_type: filters?.eventType?.length ? filters?.eventType.join(',') : undefined,
    event_source: filters?.eventSource,
    process_id: filters?.processId,
  };

  const response = await umsApi.get(endpoints.eventLogs, {
    params,
  });

  return response.data;
};
