export enum FormFields {
  lastName = 'lastName',
  firstName = 'firstName',
  login = 'login',
  bindingKey = 'bindingKey',
  bindingKeyKey = 'bindingKey.key',
  bindingKeyValue = 'bindingKey.value',
  email = 'email',
  status = 'status',
  roles = 'roles',
}
