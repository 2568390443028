import { Redirect, Route, Switch } from 'react-router-dom';

import { permissions } from 'utils/permissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppMessages } from 'i18n/messages';
import { SsoContainer } from 'app/sso/SsoContainer';
import { LoginContainer } from 'app/login/LoginContainer';
import { LogoutContainer } from 'app/logout/LogoutContainer';
import { PasswordRemindContainer } from 'app/password/passwordRemind/PasswordRemindContainer';
import { PasswordNew } from 'app/password/passwordNew/PasswordNew';
import { PasswordChange } from 'app/password/passwordChange/PasswordChange';
import { Users } from 'app/users/Users';
import { UsersAdd } from 'app/users/usersAdd/UsersAdd';
import { UsersDelete } from 'app/users/usersDelete/UsersDelete';
import { UsersEdit } from 'app/users/usersEdit/UsersEdit';
import { UsersImport } from 'app/users/usersImport/UsersImport';
import { Roles } from 'app/roles/Roles';
import { Institutions } from 'app/institutions/Institutions';
import { EventLogs } from 'app/eventLogs/EventLogs';
import { Profile } from 'app/profile/Profile';
import { EmailConfirm } from 'app/email/confirm/EmailConfirm';
import { LoginLogs } from 'app/loginLogs/LoginLogs';
import { NotFound } from 'app/notFound/NotFound';

import { AppRoute } from './AppRoute.enum';
import { Routes } from './AppRoutes.types';
import { AuthorizedRoute } from './authorizedRoute/AuthorizedRoute';
import { PreventAuthorizedRoute } from './preventAuthorizedRoute/PreventAuthorizedRoute';

const Breadcrumb = ({ text }: { text: keyof typeof AppMessages }) => {
  const translate = useTranslator();

  return <>{translate(text)}</>;
};

export const routes: Routes = [
  {
    path: AppRoute.sso,
    component: SsoContainer,
    breadcrumb: null,
    preventAuthorized: true,
  },
  {
    path: AppRoute.login,
    component: LoginContainer,
    breadcrumb: null,
  },
  {
    path: AppRoute.logout,
    component: LogoutContainer,
    breadcrumb: null,
  },
  {
    path: AppRoute.passwordRemind,
    component: PasswordRemindContainer,
    breadcrumb: null,
    preventAuthorized: true,
  },
  {
    path: AppRoute.passwordNew,
    component: PasswordNew,
    breadcrumb: null,
  },
  {
    path: AppRoute.passwordChange,
    component: PasswordChange,
    breadcrumb: null,
    isProtected: true,
    allowedPermissions: permissions.passwordChange,
  },
  {
    path: AppRoute.emailConfirm,
    component: EmailConfirm,
    breadcrumb: null,
  },
  {
    path: AppRoute.users,
    exact: true,
    component: Users,
    breadcrumb: () => <Breadcrumb text="users.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.usersListView,
  },
  {
    path: AppRoute.usersAdd,
    component: UsersAdd,
    breadcrumb: () => <Breadcrumb text="usersAdd.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.usersManage,
  },
  {
    path: AppRoute.usersDelete,
    component: UsersDelete,
    breadcrumb: () => <Breadcrumb text="usersImport.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.usersManage,
  },
  {
    path: AppRoute.usersEdit,
    component: UsersEdit,
    breadcrumb: () => <Breadcrumb text="usersEdit.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.usersManage,
  },
  {
    path: AppRoute.usersImport,
    component: UsersImport,
    breadcrumb: () => <Breadcrumb text="usersImport.pageTitle" />,
    matchOptions: {
      exact: true,
    },
    isProtected: true,
    allowedPermissions: permissions.usersImport,
  },
  {
    path: AppRoute.roles,
    component: Roles,
    breadcrumb: () => <Breadcrumb text="roles.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.rolesListView,
  },
  {
    path: AppRoute.institutions,
    component: Institutions,
    breadcrumb: () => <Breadcrumb text="institutions.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.institutionsListView,
  },
  {
    path: AppRoute.eventLogs,
    component: EventLogs,
    breadcrumb: () => <Breadcrumb text="eventLogs.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.eventLogsView,
  },
  {
    path: AppRoute.loginLogs,
    component: LoginLogs,
    breadcrumb: () => <Breadcrumb text="loginLogs.pageTitle" />,
    isProtected: true,
    allowedPermissions: permissions.loginLogsView,
  },
  {
    path: AppRoute.profile,
    component: Profile,
    breadcrumb: () => <Breadcrumb text="profile.pageTitle" />,
    isProtected: true,
  },
  {
    path: AppRoute.notFound,
    component: NotFound,
    breadcrumb: null,
  },
];

const getRouterComponent = (isProtected?: boolean, preventAuthorized?: boolean) => {
  if (isProtected) {
    return AuthorizedRoute;
  }

  if (preventAuthorized) {
    return PreventAuthorizedRoute;
  }

  return Route;
};

export const AppRoutes = () => {
  return (
    <Switch>
      {routes.map(({ component, exact, path, isProtected, allowedPermissions, preventAuthorized }, i) => {
        const RouteComponent = getRouterComponent(isProtected, preventAuthorized);

        return (
          <RouteComponent
            exact={exact}
            path={path}
            component={component}
            key={i}
            allowedPermissions={allowedPermissions}
            preventAuthorized={preventAuthorized}
          />
        );
      })}

      <Redirect exact from={AppRoute.home} to={AppRoute.login} />

      <Route component={NotFound} />
    </Switch>
  );
};
