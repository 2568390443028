import { Styles } from '@typings/common';

export const searchForm: Styles = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    order: 2,
    width: '100%',
  },
});

export const searchInput: Styles = {
  width: { xs: '100%', sm: '24rem' },
  mr: 2,
};

export const searchIcon: Styles = {
  ml: 'auto',
};
