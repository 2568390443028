import { umsApi } from 'api/axios/axiosInstance';
import { endpoints } from 'api/endpoints/endpoints';

import {
  InstitutionShortNamesParams,
  InstitutionsParams,
  InstitutionsPayload,
  InstitutionsResponse,
  InstitutionsShortNamesPayload,
  InstitutionsShortNamesResponse,
} from './institutionsActions.types';

export const getInstitutions = async (payload: InstitutionsPayload): Promise<InstitutionsResponse> => {
  const { itemsPerPage = 30, pageNumber = 0, orderBy, orderDirection, searchPhrase } = payload;

  const params: InstitutionsParams = {
    items_per_page: itemsPerPage,
    page_number: pageNumber + 1,
    order_by: orderBy,
    order_direction: orderDirection,
    search_phrase: searchPhrase,
  };

  const response = await umsApi.get(endpoints.institutions.fullNames, {
    params,
  });

  return response.data;
};

export const getInstitutionsShortNames = async (
  payload: InstitutionsShortNamesPayload,
): Promise<InstitutionsShortNamesResponse> => {
  const { itemsPerPage = 30, pageNumber = 0, searchPhrase } = payload;

  const params: InstitutionShortNamesParams = {
    items_per_page: itemsPerPage,
    page_number: pageNumber + 1,
    search_phrase: searchPhrase,
  };

  const response = await umsApi.get(endpoints.institutions.shortNames, {
    params,
  });

  return response.data;
};
