import { format } from 'date-fns';
import { AxiosRequestConfig } from 'axios';

import { endpoints } from 'api/endpoints/endpoints';
import { toUsersEndpointFilterSearchParams } from 'utils/function/toUsersEndpointFilterSearchParams/toUsersEndpointFilterSearchParams';
import { umsApi } from 'api/axios/axiosInstance';

import {
  CheckExportStatusPayload,
  CheckExportStatusResponse,
  DownloadExportFilePayload,
  StartUsersExportPayload,
  StartExportResponse,
} from './exportActions.types';

export const startUsersExport = async (payload: StartUsersExportPayload): Promise<StartExportResponse> => {
  const { params, userIds } = payload;

  const data = {
    userIds,
  };

  const config: AxiosRequestConfig = {
    params: toUsersEndpointFilterSearchParams(params),
  };

  const response = await umsApi.post(endpoints.exports.start, data, config);

  return response.data;
};

export const checkExportStatus = async (payload: CheckExportStatusPayload): Promise<CheckExportStatusResponse> => {
  const { id } = payload;

  const response = await umsApi.get(endpoints.exports.check(id));

  return response.data;
};

export const downloadExportFile = async (payload: DownloadExportFilePayload): Promise<void> => {
  const { id } = payload;

  const response = await umsApi.get(endpoints.exports.download(id), {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });

  const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `startup_passwords_${format(new Date(), 'dd_MM_yyyy_HH_mm_ss')}.pdf`);
  document.body.appendChild(link);
  link.click();
};
