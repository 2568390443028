import { Styles } from '@typings/common';

export const stepper: Styles = {
  my: 3,
  background: 'inherit',

  '.MuiStep-root:first-child': {
    pl: 0,
  },
};

export const step: Styles = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  py: 3,
  px: 2,

  [theme.breakpoints.down('md')]: {
    py: 2,
    px: 0,
    backgroundColor: 'inherit',
    boxShadow: 'none',
  },
});

export const textField: Styles = {
  mb: 2,
  width: '40rem',
  maxWidth: '100%',

  '&:last-of-type': {
    mb: 0,
  },
};

export const readOnly: Styles = {
  transition: 'none',

  '& *': {
    cursor: 'auto',
  },

  '& .MuiFormLabel-root': {
    color: 'text.secondary',
  },

  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid action.disabled`,
  },

  '& .MuiSelect-select': {
    backgroundColor: 'initial',
  },

  '& .MuiSelect-icon': {
    display: 'none',
  },
};

export const textFieldWrapper: Styles = {
  mb: 2,
  maxWidth: '100%',
  display: { md: 'flex' },
};

export const textFieldSnippet: Styles = {
  display: 'block',
  mt: 1.25,
  p: 1,
  color: 'text.hint',
};

export const buttonBox: Styles = {
  display: 'flex',
  flexDirection: { xs: 'row-reverse', lg: 'row' },
  justifyContent: 'flex-end',
  mt: 2,
  gap: 1,
};

export const rolesHeader: Styles = {
  display: { xs: 'none', md: 'flex' },
  width: '100%',
  justifyContent: 'space-between',
};

export const rolesTitle: Styles = {
  flex: 1,
  wordBreak: 'break-word',
};

export const addRole: Styles = {
  ml: 1,
  alignSelf: 'flex-start',
};

export const userName: Styles = {
  color: 'primary.main',
};

export const userRoles: Styles = {
  width: '100%',
  p: 0,
  mt: 2,
  mb: 0,
  listStyleType: 'none',
};

export const userRole: Styles = {
  display: 'flex',
  flexDirection: { md: 'row' },
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: { xs: 'flex-end', md: 'flex-start' },
  mb: 1,
};

export const institution: Styles = {
  width: '100%',
  flex: { md: 1 },
  mr: { md: 2 },
};

export const role: Styles = {
  width: '100%',
  flex: { md: 1 },
  mb: { xs: 2, md: 0 },
  mr: { md: 2 },
};

export const addRoleMobile: Styles = {
  display: { md: 'none' },
};

export const modificationDateWrapper: Styles = {
  width: { md: '8rem' },
  mt: { xs: 1, md: 0 },
  mr: 'auto',
};

export const modificationDateContainer: Styles = {
  display: { md: 'flex' },
  flexDirection: { md: 'column' },
  alignItems: { md: 'center' },
};

export const modificationDateTitle: Styles = {
  mr: 1,
  fontSize: '0.75rem',
};

export const deleteUsersRole: Styles = {
  mt: { xs: 1, md: 0 },
};

export const summaryTableContainer: Styles = {
  mb: 4,
};

export const summaryTableContainerDesktop: Styles = {
  display: { xs: 'none', md: 'block' },
};

export const summaryTableContainerMobile: Styles = {
  display: { md: 'none' },

  '& .MuiTableCell-body:first-child': {
    fontWeight: 700,
  },
};

export const summaryTable: Styles = {
  tableLayout: 'fixed',
};

export const statusBlocked: Styles = {
  color: 'error.main',
  fontWeight: 700,
};

export const rolesBadge: Styles = {
  ml: 2,

  '& .MuiBadge-badge': {
    backgroundColor: 'text.hint',
  },
};

export const serverErrorsList: Styles = {
  mt: 2,
};
