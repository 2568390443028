import { formatUsersFilterValues } from 'utils/function/formatUsersFilterValues/formatUsersFilterValues';
import { startUsersExport } from 'api/actions/exports/exportsActions';
import { removeEmptyFields } from 'utils/function/removeEmptyFields/removeEmptyFields';
import { useStartExport } from 'hooks/export/useStartExport/useStartExport';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { useUsersMethods } from 'hooks/users/useUsersMethods/useUsersMethods';
import { useUsersState } from 'hooks/users/useUsersState/useUsersState';
import { BulkActionComponentProps } from '../UsersBulkActions.types';
import { ConfirmDialog } from 'ui/confirmDialog/ConfirmDialog';
import { StartUsersExportPayload } from 'api/actions/exports/exportActions.types';

export const Export = ({ onCloseBulkAction }: BulkActionComponentProps) => {
  const translate = useTranslator();

  const { selectedRows, numOfSelectedRows, areSelectedAllOnAllPages, filters, searchPhrase } = useUsersState();
  const { handleResetSelectedRows, handleToggleAreSelectedAllOnAllPages } = useUsersMethods();

  const mutationStartUsersExport = useStartExport<StartUsersExportPayload>(startUsersExport, 'usersExport');

  const onStartExport = () => {
    const params = removeEmptyFields({
      ...formatUsersFilterValues(filters),
      searchPhrase,
      allUsers: true,
    });

    mutationStartUsersExport.mutate({
      params: areSelectedAllOnAllPages ? params : undefined,
      userIds: !areSelectedAllOnAllPages ? selectedRows : undefined,
    });

    onCloseBulkAction();
    handleResetSelectedRows();
    handleToggleAreSelectedAllOnAllPages(false);
  };

  return (
    <ConfirmDialog
      id="confirmPrintStartupPasswords"
      title={translate('users.bulkActionsPrintStartupPasswordsConfirmDialogTitle')}
      description={translate('users.bulkActionsPrintStartupPasswordsConfirmDialogDescription', {
        numOfUsers: areSelectedAllOnAllPages
          ? translate('users.buttonBulkActionsAllUsers').toLocaleLowerCase()
          : numOfSelectedRows,
      })}
      show
      onCancel={onCloseBulkAction}
      onConfirm={onStartExport}
      onConfirmButtonText={translate('users.bulkActionsPrintStartupPasswordsConfirmDialogSubmit')}
      isProcessing={mutationStartUsersExport.isLoading}
    />
  );
};
