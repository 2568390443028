import { useQuery } from 'react-query';

import { Queries } from 'api/queries/queries.enum';
import { LoginLogsPayload } from 'api/actions/loginLogs/loginLogs.types';
import { getLoginLogs } from 'api/actions/loginLogs/loginLogs';

export const useGetLoginLogs = (params: LoginLogsPayload) => {
  const queryGetLoginLogs = useQuery([Queries.getLoginLogs, params], () => getLoginLogs(params), {
    keepPreviousData: true,
  });

  return queryGetLoginLogs;
};
