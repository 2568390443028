export const executeOneTime = {
  cacheTime: 0,
  retry: false,
  retryOnMount: false,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 1000 * 60 * 60 * 24,
};
