import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { checkImportStatus } from 'api/actions/imports/importsActions';
import { CheckImportStatusResponse, ImportActionPayload } from 'api/actions/imports/impportActions.types';
import { ImportStatus } from 'api/actions/imports/importsActions.enum';

const retryTimeout = 1000;

export const useCheckImportStatus = () => {
  const [isValidated, setIsValidated] = useState(false);

  const mutationCheckImportStatus = useMutation<CheckImportStatusResponse, AxiosError, ImportActionPayload>(
    checkImportStatus,
    {
      onMutate: (variables) => {
        return variables;
      },
      onSuccess: ({ status }, variables) => {
        if (status === ImportStatus.validated) {
          setIsValidated(true);
        } else if (status === ImportStatus.toValid || status === ImportStatus.inProgress) {
          setTimeout(() => {
            return mutationCheckImportStatus.mutate({ id: variables.id, url: variables.url });
          }, retryTimeout);
        }
      },
    },
  );

  return { mutationCheckImportStatus, isValidated };
};
