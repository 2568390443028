import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { permissions } from 'utils/permissions';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { AuthorizedSection } from 'hoc/authorizedSection/AuthorizedSection';
import { UsersContextController } from 'context/users/usersContextController/UsersContextController';
import { UsersTable } from 'app/users/usersTable/UsersTable';

import { MenuOption } from './Users.types';
import { LastAddedUser } from './lastAddedUser/LastAddedUser';
import * as styles from './Users.styles';

export const Users = () => {
  const translate = useTranslator();

  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorMenuEl);

  const menuOptions: MenuOption[] = [
    {
      route: AppRoute.usersImport,
      title: translate('usersImport.pageTitle'),
    },
  ];

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };

  return (
    <UsersContextController>
      <Box sx={styles.container}>
        <Typography component="h2" variant="h5">
          {translate('users.pageTitle')}
        </Typography>

        <AuthorizedSection allowedPermissions={permissions.usersManage}>
          <Box alignItems="flex-end">
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              sx={styles.addButton}
              to={AppRoute.usersAdd}
            >
              {translate('global.buttonAdd')}
            </Button>

            <AuthorizedSection allowedPermissions={permissions.usersImport}>
              <Tooltip title={translate('global.more')} arrow>
                <IconButton
                  id="more-menu-button"
                  aria-label={translate('global.more')}
                  aria-controls={isMenuOpen ? 'more-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={isMenuOpen ? 'true' : undefined}
                  onClick={handleOpenMenu}
                  size="small"
                  sx={styles.moreButton}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>

              <Menu
                id="more-menu"
                anchorEl={anchorMenuEl}
                keepMounted
                open={isMenuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'more-menu-button',
                }}
              >
                {menuOptions.map(({ route, title }) => (
                  <MenuItem key={title} component={RouterLink} to={route} onClick={handleCloseMenu}>
                    {title}
                  </MenuItem>
                ))}
              </Menu>
            </AuthorizedSection>
          </Box>
        </AuthorizedSection>
      </Box>

      <LastAddedUser />

      <UsersTable />
    </UsersContextController>
  );
};
