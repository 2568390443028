import { useQuery } from 'react-query';

import { getEventLogs } from 'api/actions/eventLogs/eventLogs';
import { EventLogsPayload } from 'api/actions/eventLogs/eventLogs.types';
import { Queries } from 'api/queries/queries.enum';

export const useGetEventLogs = (params: EventLogsPayload) => {
  const queryGetEventLogs = useQuery([Queries.getInstitutions, params], () => getEventLogs(params), {
    keepPreviousData: true,
  });

  return queryGetEventLogs;
};
