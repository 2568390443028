import parseHtml from 'html-react-parser';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useTranslator } from 'hooks/useTranslator/useTranslator';

import { ConfirmDialogProps } from './ConfirmDialog.types';

export const ConfirmDialog = ({
  children,
  description,
  id,
  isProcessing,
  onCancel,
  onCancelButtonText,
  onConfirm,
  onConfirmButtonText,
  show,
  title,
}: ConfirmDialogProps) => {
  const translate = useTranslator();

  return (
    <Dialog open={show} aria-labelledby={`${id}-title`} aria-describedby={`${id}-description`}>
      <DialogTitle id={`${id}-title`}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${id}-description`}>{parseHtml(description)}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          {onCancelButtonText || translate('global.confirmDialogAbort')}
        </Button>
        <Button onClick={onConfirm} disabled={isProcessing} variant="contained" color="primary" autoFocus>
          {onConfirmButtonText || translate('global.confirmDialogApprove')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
