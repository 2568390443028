import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useConfirmFormAbort } from 'hooks/useConfirmFormAbort/useConfirmFormAbort';
import { AppRoute } from 'routing/AppRoute.enum';

export const useCancelForm = (onCancelCallback?: () => void) => {
  const [isFormCancelled, setIsFormCancelled] = useState(false);

  const history = useHistory();

  const confirmFormAbort = useConfirmFormAbort();

  const cancelForm = () => {
    confirmFormAbort.disable();
    setIsFormCancelled(true);
  };

  const defaultOnCancelCallback = () => {
    history.push(AppRoute.users);
  };

  useEffect(() => {
    if (!isFormCancelled) {
      return;
    }

    if (onCancelCallback) {
      return onCancelCallback();
    }

    return defaultOnCancelCallback();
  }, [isFormCancelled]);

  return { cancelForm };
};
