import { useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

import { setUnauthorized } from 'context/auth/authActionCreators/authActionCreators';
import { useAuthDispatch } from 'hooks/auth/useAuthDispatch/useAuthDispatch';
import { useDefaultRoute } from 'hooks/useDefaultRoute/useDefaultRoute';
import { useGetLoggedInRealms } from 'hooks/auth/useGetLoggedInRealms/useGetLoggedInRealms';
import { useLogout } from 'hooks/auth/useLogout/useLogout';
import { useTranslator } from 'hooks/useTranslator/useTranslator';
import { AppRoute } from 'routing/AppRoute.enum';
import { Loader } from 'ui/loader/Loader';

import { SsoLogout } from './ssoLogout/SsoLogout';

export const LogoutContainer = () => {
  const translate = useTranslator();

  const dispatch = useAuthDispatch();

  const queryClient = useQueryClient();

  const defaultRoute = useDefaultRoute();

  const queryGetLoggedInRealms = useGetLoggedInRealms();
  const loggedInRealms = queryGetLoggedInRealms.data?.loggedInRealms;
  const isLoggedInRealms = loggedInRealms?.length;

  const mutationLogout = useLogout();

  const onLogout = useCallback(
    (wreply?: string | null) => {
      mutationLogout.mutate(undefined, {
        onSuccess: () => {
          dispatch(setUnauthorized());

          queryClient.clear();

          toast.dismiss();
          toast.success(translate('logout.success'));

          if (wreply) {
            window.location.replace(wreply);
          }
        },
        onError: () => {
          toast.error(translate('global.errorMessage'));
        },
      });
    },
    [dispatch, mutationLogout.mutate],
  );

  useEffect(() => {
    if (queryGetLoggedInRealms.isFetched && !isLoggedInRealms) {
      onLogout();
    }
  }, [dispatch, queryGetLoggedInRealms.isFetched, isLoggedInRealms]);

  if (mutationLogout.isError) {
    return <Redirect to={defaultRoute} />;
  }

  if (mutationLogout.isSuccess) {
    return <Redirect to={AppRoute.login} />;
  }

  if (loggedInRealms?.length) {
    return <SsoLogout loggedInRealms={loggedInRealms} onLogout={onLogout} />;
  }

  return <Loader data-testid="loader" />;
};
